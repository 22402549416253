import { Grid } from "@mui/material";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_ADMIN } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import TenantTableWithToolBar from "../../components/other/TenantTableWithToolBar";
import useCheckPermission from "../../hooks/usePermission";
import { EDIT_TENANTS_PERMISSION } from "../../helpers/constants";
import { useAuth } from "../../context/auth";

const TENANT_FIELDS_TO_SAVE = [
  "tenant_full_name",
  "tenant_short_name",
  "tenant_legal",
  "tenant_nip",
  "tenant_regon",
  "tenant_krs",
  "tenant_iban",
  "address_data",
  "phone",
  "mail",
  "tenant_priority",
  "locations",
];

export default function AdminTenantsPage(props) {
  const { pageName } = props;

  const { user } = useAuth();
  
  const [hasPermissionToEditTeams] = useCheckPermission(EDIT_TENANTS_PERMISSION)

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_ADMIN}
    >
      <DefaultPageWrapper titleKey={"admin_tenants"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
          marginTop={1}
        >
          <Grid item xs={12}>
            <TenantTableWithToolBar
              pageName={pageName}
              fieldsToUpdate={TENANT_FIELDS_TO_SAVE}
              fullDataToUpdateInTenantDialog
              showFullAddressInDialog
              showAddTenantButton
              showLocalizationInDialog
              hasPermissionToEditTeams = {hasPermissionToEditTeams || user.is_admin}
            />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
