import { AUTOCOMPLETE_FIELD_TYPE, DATETIME_TO_DATE_RANGE_FIELD_TYPE, IS_EDITED, SELECT_FIELD_TYPE } from "../../../helpers/constants";
import i18n from "../../../i18n";
import { IconButton } from "@mui/material";
import Delete from "@mui/icons-material/Delete";


export const NOTIFICATION_TABLE_NAME = "Notification";
export const NOTIFICATION_TABLE_FIELDS_CONFIG = [
    {
      name: "postrec_department",
      getValueCallback: (rowData) => rowData?.postrec_department,
      label: i18n.t("table.correspondence.recipient_department"),
      filterType: AUTOCOMPLETE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        options: filteringData.postrec_department,
        isObjectOption: true,
        optionLabelKey: "full_name",
        multiple: true,
      }),
      allowChangeVisibility: false,
      
    },
    {
      name: "recipient_person",
      getValueCallback: (rowData) => rowData?.postrec_person,
      label: i18n.t("table.correspondence.recipient_person"),
      filterType: AUTOCOMPLETE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        options: filteringData.postrec_person,
        isObjectOption: true,
        optionLabelKey: "full_name",
        multiple: true,
      }),
      allowChangeVisibility: false,
    },
    {
      name: "postrec_person_type",
      getValueCallback: (rowData) => rowData?.postrec_person_type,
      label: i18n.t("table.correspondence.recipient_type"),
      editable: true,
      dataType: AUTOCOMPLETE_FIELD_TYPE,
      getFilterFieldConfigCallback: (filteringData) => ({
        options: filteringData.postrec_person_type,
        isObjectOption: true,
        optionLabelKey: "postrec_person_type",
        addNewValue: false,
      }),
    },
    {
      name: "delete_action",
      getValueCallback: (rowData, onClickDelete) => (
        <IconButton onClick={() => onClickDelete(rowData)}>
          <Delete />
        </IconButton>
      ),
      label: i18n.t("table.post_notification_table.delete"),
      filterType: null,
      getFilterFieldConfigCallback: (filteringData) => undefined,
    },
    
]

export const NOTIFICATION_OUT_TABLE_FIELDS_CONFIG = [
  {
    name: "postrec_department",
    getValueCallback: (rowData) => rowData?.postrec_department,
    label:i18n.t("table.correspondence.sender_department"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.postrec_department,
      isObjectOption: true,
      optionLabelKey: "full_name",
      multiple: true,
    }),
    allowChangeVisibility: false,
    
  },
  {
    name: "recipient_person",
    getValueCallback: (rowData) => rowData?.postrec_person,
    label: i18n.t("table.correspondence.sender_person"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.postrec_person,
      isObjectOption: true,
      optionLabelKey: "full_name",
      multiple: true,
    }),
    allowChangeVisibility: false,
  },
  {
    name: "postrec_person_type",
    getValueCallback: (rowData) => rowData?.postrec_person_type,
    label: i18n.t("table.correspondence.sender_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.postrec_person_type,
      isObjectOption: true,
      optionLabelKey: "full_name",
      multiple: true,
    }),
    allowChangeVisibility: false,
  },
  {
    name: "delete_action",
    getValueCallback: (rowData, onClickDelete) => (
      <IconButton onClick={() => onClickDelete(rowData)}>
        <Delete />
      </IconButton>
    ),
    label: i18n.t("table.post_notification_table.delete"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  
]