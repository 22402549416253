import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import ItemRecord from "../../other/ItemRecord/ItemRecord";
import AutocompleteField from "../../field/AutocompleteField";
import { isEmptyValue } from "../../../helpers/methods";
import FloatField from "../../base/FloatField/FloatField";
import PropTypes from "prop-types"
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import AddButton from "@mui/icons-material/Add";
import useDialog from "../../../hooks/useDialog";
import PurchaseFvDialog from "../../dialog/PurchaseFvDialog/PurchaseFvDialog";
import useCheckPermission from "../../../hooks/usePermission";
import { CREATE_INVOCIE_PERMISSION, CREATE_PURCHASE_PERMISSION, MATERIAL_MAIN_BRANCH_NAME } from "../../../helpers/constants";
import { formatRackShellData } from "../../../page/StockWarehousePage/PageTablesConfig";
import DateField from "../../field/DateField/DateField";
import { useMemo } from "react";

const TransactionInForm = (props
) => {
  const { t } = useTranslation();

  const [hasPermissionToManagePurchases] = useCheckPermission(CREATE_PURCHASE_PERMISSION)
  const [hasCreateInvoicePermission] = useCheckPermission(CREATE_INVOCIE_PERMISSION)

  const [
    openInvoiceDialog,
    handleOpenInvoiceDialog,
    handleCloseInvoiceDialog,
  ] = useDialog();

  const getObjectedLocationList = (list) => {
    let translatedList = []
    if (list) {
      for (let permission of list) {
        translatedList.push({ 'id': permission.id, 'warehouse_location': formatRackShellData(permission) })
      }
    }
    return translatedList
  }
  
  const reservationMaxDate = useMemo(
    () =>new Date(),
    []
  );


  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={6}>
          <AutocompleteField
            name={`warehouse`}
            label={t(`form.transaction_form.warehouse`)}
            value={props.formValue[`warehouse`]}
            options={props.transactionSelectData?.value?.warehouse}
            isObjectOption={true}
            optionLabelKey={"warehouse_name"}
            addNewValue={false}
            onChange={props.onChangeAutocomplete}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteField
            name={`warehouse_location`}
            label={t(`form.transaction_form.warehouse_location`)}
            value={props.formValue[`warehouse_location`]}
            options={getObjectedLocationList(props.warehouseLocations?.value)}
            isObjectOption={true}
            optionLabelKey={"warehouse_location"}
            addNewValue={false}
            onChange={props.onChangeAutocomplete}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={props.readOnly && !hasPermissionToManagePurchases ? 4 : 3.5}>
          <AutocompleteField
            name={`transaction_po`}
            label={t(`form.transaction_form.po`)}
            value={props.formValue[`transaction_po`]}
            options={props.transactionSelectData?.value?.po}
            isObjectOption={true}
            optionLabelKey={"po_nr"}
            addNewValue={false}
            onChange={props.onChangeAutocomplete}
            disabled={props.readOnly}
          />
        </Grid>
        {!props.readOnly && hasPermissionToManagePurchases &&
          <Grid item xs={0.5} style={{ paddingLeft: "0" }}>
            <IconButtonWithTooltip
              style={{ border: "3px solid var(--secondary)" }}
              title={t(`form.transaction_form.add_po`)}
              onClick={props.onCreateNewPo}
            >
              <AddButton
                fontSize="medium"
                sx={{
                  color: "var(--secondary)",
                }}
              />
            </IconButtonWithTooltip>
          </Grid>
        }
        <Grid item xs={props.readOnly && !hasCreateInvoicePermission ? 4 : 3.5}>
          <AutocompleteField
            name={"transaction_fv"}
            label={t(`form.transaction_form.invoice`)}
            value={props.formValue[`transaction_fv`]}
            options={props.transactionSelectData?.value?.invoice}
            isObjectOption={true}
            optionLabelKey={"fv_nr"}
            addNewValue={false}
            onChange={props.onChangeAutocomplete}
            disabled={props.readOnly}
          />
        </Grid>
        {!props.readOnly && hasCreateInvoicePermission &&
          <Grid item xs={0.5} style={{ paddingLeft: "0" }}>
            <IconButtonWithTooltip
              style={{ border: "3px solid var(--secondary)" }}
              title={t(`form.transaction_form.add_fv`)}
              onClick={handleOpenInvoiceDialog}
            >
              <AddButton
                fontSize="medium"
                sx={{
                  color: "var(--secondary)",
                }}
              />
            </IconButtonWithTooltip>
          </Grid>
        }
        {isEmptyValue(props.formValue[`transaction_po`]) || props.poPartList?.value?.length === 0 ?
          <Grid item xs={12}>
            <ItemRecord
              itemLevels={props.items}
              onChangeItem={props.onChangeLevelItem}
              onChangeAutocompleteFieldWithObjectOptions={props.onChangeAutocomplete}
              partValue={props.formValue['part']}
              onCleanPart={props.onCleanPart}
              showMaterialLabel={true}
              required={false}
              onClickSearchItem={props.onClickSearchItem}
              defaultFirstLevelItemName={MATERIAL_MAIN_BRANCH_NAME}
            />
          </Grid> :
          <Grid item xs={3}>
            <AutocompleteField
              name={"part"}
              label={t("form.transaction_form.part")}
              value={props.formValue[`part`]}
              options={props.poPartList?.value}
              isObjectOption={true}
              optionLabelKey={"part_name"}
              onChange={props.onChangeAutocomplete}
              addNewValue={false}
              multiple={false}
              required={true}
            />
          </Grid>
        }
        <Grid item xs={2}>
          <FloatField
            name="transaction_amount"
            readOnly={props.readOnly}
            value={props.formValue[`transaction_amount`]}
            label={t("form.transaction_form.transaction_amount")}
            onChange={props.onChange}
            required
          />
        </Grid>
        <Grid item xs={1}>
          <TextFieldFormControl
            disabled={true}
            value={props.formValue.part_unit ? t(`other.units.${props.formValue.part_unit}`) : undefined}
            label={t("form.transaction_form.part_unit")}
          />
        </Grid>

        <Grid item xs={2}>
          <FloatField
            name="transaction_price"
            readOnly={props.readOnly}
            value={props.formValue[`transaction_price`]}
            label={t("form.transaction_form.transaction_price")}
            onChange={props.onChange}
            required
            suffix={"zł"}
          />
        </Grid>

        <Grid item xs={2}>
          <FloatField
            name="transaction_value"
            disabled={true}
            value={props.formValue[`transaction_value`]}
            label={t("form.transaction_form.transaction_value")}
            onChange={props.onChange}
            suffix={"zł"}
          />
        </Grid>
      <Grid item xs={3}>
        <TextFieldFormControl
          name="transaction_part_production_number"
          value={props.formValue[`transaction_part_production_number`]}
          label={t("form.transaction_form.production_number")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={2}>
        <DateField
          name={"transaction_part_production_date"}
          value={props.formValue["transaction_part_production_date"]}
          onChange={props.onChangeDate}
          label={t("form.transaction_form.production_date")}
          maxDate={reservationMaxDate}
          showClearIcon = {false}
        />
      </Grid>
      </Grid>

        {openInvoiceDialog &&
          <PurchaseFvDialog
            open={openInvoiceDialog}
            onClose={handleCloseInvoiceDialog}
            onRefetchData={props.transactionSelectData.refetch}
          />
        }
      </>
      );
};

      TransactionInForm.propTypes = {
        formValue: PropTypes.object,
      onChange: PropTypes.func,
      onChangeAutocomplete: PropTypes.func,
      readOnly: PropTypes.bool,
      transactionSelectData: PropTypes.array,
      poPartList: PropTypes.array,
      onCreateNewPo: PropTypes.func
};

      TransactionInForm.defaultProps = {
        readOnly: false
};

      export default TransactionInForm;
