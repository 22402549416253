import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import TableService from "../../services/tableService";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import AgreementsToolBar from "../../components/bar/AgreementsToolBar";
import FilterDialog from "../../components/base/FilterDialog";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import {
  GUARANTEE_AGREEMENT_PAGE_TABLE_FIELDS_CONFIG,
  GUARANTEE_AGREEMENT_TABLE_NAME,
} from "./PageTablesConfig";
import UniversalFilterForm from "../../components/form/UniversalFilterForm";
import AgreementTable from "../../components/table/AgreementTable";
import { exportGuaranteeAgreementsToFileUrl } from "../../helpers/apiUrls";
import { NAVIGATION_DRAWER_TYPE_LEGAL } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import SelectAgreementDialog from "../../components/dialog/SelectAgreementDialog";
import useGuaranteeService from "../../services/guaranteeService";
import AgreementGuaranteeDetailsDialog from "../../components/dialog/AgreementGuaranteeDetailsDialog/AgreementGuaranteeDetailsDialog";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import useUserService from "../../services/userService";
import usePermissionService from "../../services/permissionService";
import { EDIT_AGREEMENTS_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";

const TABLE_CONFIGS = [
  {
    name: GUARANTEE_AGREEMENT_TABLE_NAME,
    config: GUARANTEE_AGREEMENT_PAGE_TABLE_FIELDS_CONFIG,
  },
];

export default function AgreementsGuaranteePage(props) {
  const { pageName } = props;

  const [hasEditPermission] = useCheckPermission(EDIT_AGREEMENTS_PERMISSION)

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [
    agreementsGuaranteeTableConfigLocal,
    setAgreementsGuaranteeTableConfigLocal,
  ] = useState(GUARANTEE_AGREEMENT_PAGE_TABLE_FIELDS_CONFIG);

  const [agreementGuaranteeDataLocal, setAgreementGuaranteeDataLocal] =
    useState([]);
  const [countAgreementGuaranteeRecord, setCountAgreementGuaranteeRecord] =
    useState();
  const [selectedAgreement, setSelectedAgreement] = useState();
  const [selectedAgreementGuaranteeId, setSelectedAgreemenGuaranteeId] =
    useState();
  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const onCleanFlitersInFilterDialog = () => {
    setAgreementGuaranteeDataLocal(undefined);
    setCountAgreementGuaranteeRecord(undefined);
  };

  const {
    checkPermissionToEdit
  } = usePermissionService();



  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const [
    openSelectAgreementDialog,
    onOpenSelectAgreementDialog,
    onCloseSelectAgreementDialog,
  ] = useDialog();

  const [
    openGuaranteeAgreementDialog,
    onOpenGuaranteeAgreementDialog,
    onCloseGuaranteeAgreementDialog,
  ] = useDialog();

  const {
    page: agreementGuaranteeDataPage,
    pageSize: agreementGuaranteeDataPageSize,
    handleChangePageWithSearchParams: handleChangeAgreementGuaranteeDataPage,
    handleChangePageSizeWithSearchParams:
      handleChangeAgreementGuaranteeDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const { getGuaranteeAgreementFilteringData, getGuaranteeAgreementData } =
    useGuaranteeService();

  const agreementsGuaranteeData = useAsync(
    () => getGuaranteeAgreementData(searchParams),
    [searchParams]
  );

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const agreementGuaranteeFilteringData = useAsync(
    getGuaranteeAgreementFilteringData
  );
  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  useEffect(() => {
    if (agreementsGuaranteeData.loading) {
      return;
    }
    setAgreementGuaranteeDataLocal(agreementsGuaranteeData.value.results);
    setCountAgreementGuaranteeRecord(agreementsGuaranteeData.value.count);
  }, [agreementsGuaranteeData.loading]);

 

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      setHiddenColumnsForTables(undefined);
      setColumnsOrdersForTables(undefined);
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [GUARANTEE_AGREEMENT_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [GUARANTEE_AGREEMENT_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const handleOpenAgreementGuaranteeDetails = useCallback(
    (e, agreementGuaranteeId) => {
      e.stopPropagation();
      setSelectedAgreemenGuaranteeId(agreementGuaranteeId);
      onOpenGuaranteeAgreementDialog();
    },
    [selectedAgreementGuaranteeId]
  );

  useEffect(() => {
    if (
      hasEditPermission &&
      agreementsGuaranteeTableConfigLocal.find(
        (config) => config.name === "edit"
      ) === undefined
    ) {
      const tempConfig = [...GUARANTEE_AGREEMENT_PAGE_TABLE_FIELDS_CONFIG];
      tempConfig.push({
        name: "edit",
        getValueCallback: (rowData, onClickEdit) => {
          return (
            <ModeEditOutlineOutlined
              onClick={(e) => onClickEdit(e, rowData.id)}
            />
          );
        },
        label: t("table.agreements_table.edit"),
        filterType: null,
        getFilterFieldConfigCallback: (filteringData) => undefined,
      });
      setAgreementsGuaranteeTableConfigLocal(tempConfig);
    }
  }, [hasEditPermission]);

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const handleClickAgreementRow = (agreementGuaranteeId) => {
    if (
      !agreementGuaranteeId ||
      agreementGuaranteeId === selectedAgreementGuaranteeId
    ) {
      setSelectedAgreemenGuaranteeId(undefined);
    } else {
      setSelectedAgreemenGuaranteeId(agreementGuaranteeId);
    }
  };

  const onClickAddNewGuarantee = useCallback(() => {
    setSelectedAgreemenGuaranteeId(undefined);
    setSelectedAgreement(undefined);
    onOpenSelectAgreementDialog();
  }, [selectedAgreement]);

  const onChooseAgreement = useCallback(
    (agreement) => {
      setSelectedAgreement(agreement);
      onOpenGuaranteeAgreementDialog();
      onCloseSelectAgreementDialog();
    },
    [selectedAgreement]
  );

  const isAgreementGuaranteeTableLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    agreementGuaranteeFilteringData.loading ||
    agreementsGuaranteeData.loading ||
    agreementGuaranteeDataLocal === undefined ||
    countAgreementGuaranteeRecord === undefined;

  const agreementExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      GUARANTEE_AGREEMENT_PAGE_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[GUARANTEE_AGREEMENT_TABLE_NAME],
      columnsOrdersForTables?.[GUARANTEE_AGREEMENT_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[GUARANTEE_AGREEMENT_TABLE_NAME],
    columnsOrdersForTables?.[GUARANTEE_AGREEMENT_TABLE_NAME],
    GUARANTEE_AGREEMENT_PAGE_TABLE_FIELDS_CONFIG,
  ]);

  return (
    <NavigationDrawer
      drawerType={NAVIGATION_DRAWER_TYPE_LEGAL}
      pageName={pageName}
    >
      <DefaultPageWrapper titleKey={"agreement_guarantees"}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <AgreementsToolBar
              style={{ marginBlock: "5px" }}
              params={searchParams}
              onClickSearch={onOpenFilterDialog}
              onClickCreateGuarantee={onClickAddNewGuarantee}
              onClickMyFilters={onOpenUserFiltersDialog}
              onClickAdjustTable={onOpenTableColumnVisibilityDialog}
              hasPermission={hasEditPermission}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isAgreementGuaranteeTableLoading}>
              <AgreementTable
                data={agreementGuaranteeDataLocal}
                onClickRow={handleClickAgreementRow}
                countRecords={countAgreementGuaranteeRecord}
                page={agreementGuaranteeDataPage}
                onPageChange={handleChangeAgreementGuaranteeDataPage}
                rowsPerPage={agreementGuaranteeDataPageSize}
                onRowsPerPageChange={handleChangeAgreementGuaranteeDataPageSize}
                resetPageNumber={resetPageNumber}
                filteringData={
                  agreementGuaranteeFilteringData
                    ? agreementGuaranteeFilteringData
                    : []
                }
                hiddenColumns={
                  hiddenColumnsForTables
                    ? hiddenColumnsForTables[GUARANTEE_AGREEMENT_TABLE_NAME]
                    : []
                }
                columnsOrders={
                  columnsOrdersForTables
                    ? columnsOrdersForTables[GUARANTEE_AGREEMENT_TABLE_NAME]
                    : undefined
                }
                selectedAgreementId={selectedAgreementGuaranteeId}
                style={{ maxHeight: "80vh" }}
                tableConfig={agreementsGuaranteeTableConfigLocal}
                showCleanFilterIcon={true}
                showContextMenu={false}
                showExportToFileButton={true}
                exportToFileUrl={exportGuaranteeAgreementsToFileUrl}
                exportToFileSearchParams={searchParams}
                exportToFileHeaders={agreementExportHeaders}
                exportToFileFileName={`${t(
                  "page.agreement_guarantee_page.agreement_guarantee"
                ).replace(" ", "_")}.xlsx`}
                showDetailsIcon={false}
                onClickEdit={handleOpenAgreementGuaranteeDetails}
                detailsTitle={
                  "page.agreement_guarantee_page.open_dialog_with_details"
                }
              />
            </LoaderWrapper>
          </Grid>
        </Grid>
        {openTableColumnVisibilityDialog && (
          <TableColumnVisibilityDialog
            open={openTableColumnVisibilityDialog}
            onClose={onCloseTableColumnVisibilityDialog}
            onSubmit={handleUpdateUserPreferences}
            tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
              TABLE_CONFIGS,
              columnsOrdersForTables
            )}
            tablesHiddenColumns={hiddenColumnsForTables}
            isLoading={userPreferencesForPage.loading}
          />
        )}
        {openFilterDialog && (
          <FilterDialog
            open={openFilterDialog}
            onCleanFilters={onCleanFlitersInFilterDialog}
            onClose={onCloseFilterDialog}
            resetPageNumber={resetPageNumber}
            filterForm={
              <UniversalFilterForm
                filteringData={agreementGuaranteeFilteringData}
                filtersConfig={GUARANTEE_AGREEMENT_PAGE_TABLE_FIELDS_CONFIG}
                includeOpenCloseFilter={false}
              />
            }
          />
        )}
        {openUserFiltersDialog && (
          <UserFilterDialog
            open={openUserFiltersDialog}
            onClose={onCloseUserFiltersDialog}
            pageName={pageName}
            filterForm={
              <UniversalFilterForm
                filteringData={agreementGuaranteeFilteringData}
                filtersConfig={GUARANTEE_AGREEMENT_PAGE_TABLE_FIELDS_CONFIG}
                includeOpenCloseFilter={false}
              />
            }
          />
        )}
        {openGuaranteeAgreementDialog && (
          <AgreementGuaranteeDetailsDialog
            open={openGuaranteeAgreementDialog}
            onClose={onCloseGuaranteeAgreementDialog}
            filteringData={agreementGuaranteeFilteringData}
            agreementId={selectedAgreement}
            guaranteeId={selectedAgreementGuaranteeId}
            onRefetchData={agreementsGuaranteeData.refetch}
          />
        )}
        {openSelectAgreementDialog && (
          <SelectAgreementDialog
            open={openSelectAgreementDialog}
            onClose={onCloseSelectAgreementDialog}
            onSubmit={onChooseAgreement}
          />
        )}
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
