import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import AutocompleteField from "../AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import DateField from "../DateField/DateField";
import SelectField from "../SelectField/SelectField";
import TextFieldFormControl from "../TextFieldFormControl";
import TrueFalseSelectField from "../TrueFalseSelectField/trueFalseSelectField";
import DateTimeField from "../DateTimeField/DateTimeField";
import TimeDurationSelectField from "../TimeDurationSelectField/TimeDurationSelectField";

import DateService from "../../../services/dateService";

import {
  getFieldValueFromSearchParams,
  getObjectFromSearchParams,
  getFilterSearchParamsKey,
} from "../../../helpers/methods";

import {
  TEXT_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  SELECT_FIELD_TYPE,
  DATE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  DATE_TIME_FIELD_TYPE,
  DATE_TIME_RANGE_FIELD_TYPE,
  TIME_FIELD_TYPE,
  TIME_RANGE_FIELD_TYPE,
  DATETIME_TO_DATE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_START_FIELD_SUBTYPE,
  DATETIME_TO_DATE_RANGE_END_FIELD_SUBTYPE,
} from "../../../helpers/constants";

import useDebounce from "../../../hooks/useDebouce";

const FilterFieldTable = React.memo(
  ({
    type,
    subtype,
    name,
    fieldConfig,
    label,
    resetPageNumber,
    filterPrefix,
  }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [formValue, setFormValue] = useState(() =>
      getFieldValueFromSearchParams(searchParams, name)
    );

    const onChange = (e) => {
      const { value } = e.target;
      setFormValue(value);
    };

    const onChangeDate = (e) => {
      const { value } = e.target;
      setFormValue(DateService.convertDateToFormatYYYYMMDD(value));
    };

    const onChangeDatetimeToDate = (e) => {
      const { value } = e.target;
      const formatedValue =
        subtype === DATETIME_TO_DATE_RANGE_START_FIELD_SUBTYPE
          ? DateService.getISOStringZeroHourOfDate(value)
          : subtype === DATETIME_TO_DATE_RANGE_END_FIELD_SUBTYPE
          ? DateService.getISOStringleEndHourOfDate(value)
          : DateService.convertDateToFormatYYYYMMDD(value);

      setFormValue(formatedValue);
    };

    const onChangeDateTime = (e) => {
      const { value } = e.target;
      setFormValue(DateService.convertDateToFormatYYYYMMDD_hhmmss(value));
    };

    useEffect(() => {
      setFormValue(getFieldValueFromSearchParams(searchParams, name));
    }, [searchParams, name]);

    const onChangeAutocompleteFieldWithObjectOptions = (
      e,
      value_object,
      value_key,
      state_value_name
    ) => {
      const newFormValue = Array.isArray(value_object)
        ? value_object.map((option) =>
            typeof option === "object" ? option[value_key] : option
          )
        : value_object[value_key];

      setFormValue(newFormValue);
    };

    const onSearch = () => {
      if (formValue !== undefined)
        setSearchParams((prev) => {
          let searchObject = {
            ...getObjectFromSearchParams(prev),
            [name]: formValue,
          };

          const filterKey = getFilterSearchParamsKey(filterPrefix);
          const pageKey = `${filterKey}page`;
          if (prev.get(pageKey)) {
            searchObject = { ...searchObject, [pageKey]: 1 };
          }

          if (resetPageNumber) resetPageNumber();
          return searchObject;
        });
    };

    const getDebounceDeps = () => {
      return Array.isArray(formValue) ? formValue.length : formValue;
    };

    useDebounce(onSearch, 1000, [getDebounceDeps()]);

    let FilterField = null;
    let onFilterChange = onChange;
    let formattedFieldConfig = { ...fieldConfig };
    const formattedFieldConfigForDateFields = {
      ...formattedFieldConfig,
      style: { ...formattedFieldConfig.style, minWidth: "160px" },
    };
    switch (type) {
      case NUMERIC_FIELD_TYPE:
        FilterField = FloatField;
        break;
      case NUMERIC_RANGE_FIELD_TYPE:
        FilterField = FloatField;
        break;
      case TEXT_FIELD_TYPE:
        FilterField = TextFieldFormControl;
        break;
      case AUTOCOMPLETE_FIELD_TYPE:
        FilterField = AutocompleteField;
        if (formattedFieldConfig?.isObjectOption) {
          onFilterChange = onChangeAutocompleteFieldWithObjectOptions;
        }
        break;
      case SELECT_FIELD_TYPE:
        FilterField = SelectField;
        break;
      case DATE_FIELD_TYPE:
      case DATE_RANGE_FIELD_TYPE:
        FilterField = DateField;
        onFilterChange = onChangeDate;
        formattedFieldConfig = formattedFieldConfigForDateFields;
        break;
      case DATETIME_TO_DATE_FIELD_TYPE:
      case DATETIME_TO_DATE_RANGE_FIELD_TYPE:
        FilterField = DateField;
        onFilterChange = onChangeDatetimeToDate;
        formattedFieldConfig = formattedFieldConfigForDateFields;
        break;
      case BOOLEAN_FIELD_TYPE:
        FilterField = TrueFalseSelectField;
        break;
      case DATE_TIME_FIELD_TYPE:
      case DATE_TIME_RANGE_FIELD_TYPE:
        FilterField = DateTimeField;
        onFilterChange = onChangeDateTime;
        formattedFieldConfig = formattedFieldConfigForDateFields;
        break;
      case TIME_FIELD_TYPE:
      case TIME_RANGE_FIELD_TYPE:
        FilterField = TimeDurationSelectField;
        break;
      default:
        return formValue;
    }

    return (
      <FilterField
        name={name}
        label={label}
        value={formValue}
        onChange={onFilterChange}
        variant={"standard"}
        {...formattedFieldConfig}
      />
    );
  }
);

FilterFieldTable.propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  fieldConfig: PropTypes.object,
  resetPageNumber: PropTypes.func,
};

export default FilterFieldTable;
