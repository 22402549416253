import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { checkIfIsUndefinedOrNullOrEmpty } from "../../../helpers/methods";
import AddButton from "@mui/icons-material/Add";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import { Chip } from "@mui/material";
import { Popper } from "@mui/material";

const filter = createFilterOptions();

const MenuOptionPopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: "fit-content", flexWrap: "wrap" }}
      placement="bottom-start"
    />
  );
};

export default function AutocompleteField(props) {
  const axiosPrivate = useAxiosPrivate();

  const { t } = useTranslation();

  const getValueObjectFromValue = (value, searchOptions) => {
    const searchOption = searchOptions.find(
      (x) => x[props.optionValueKey] == value
    );
    if (searchOption !== undefined) {
      return {
        [props.optionValueKey]: searchOption[props.optionValueKey],
        [props.optionLabelKey]: searchOption[props.optionLabelKey],
      };
    }
  };

  const getValue = (searchOptions) => {
    if (props.value === "" || props.value == null) {
      return null;
    }

    if (searchOptions === undefined) {
      searchOptions = options;
    }

    if (props.isObjectOption && props.multiple) {
      let selectedOptions = props.value;
      if (!Array.isArray(selectedOptions)) {
        selectedOptions = [selectedOptions];
      }
      return selectedOptions.map((optionValue) =>
        getValueObjectFromValue(optionValue, searchOptions)
      );
    }

    if (typeof props.value !== "object" && props.value !== null) {
      return getValueObjectFromValue(props.value, searchOptions);
    }

    return props.value;
  };

  const [options, setOptions] = useState(props.options);
  const [timer, setTimer] = useState(null);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.options_url !== undefined) {
      getOptionsFromUrl(props.options_url);
    }
  }, []);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    // if (options.length > 0) {
    setValue(getValue());
    // }
  }, [props.value]);

  useEffect(() => {
    if (options.length > 0 && value === null && props.value !== null) {
      setValue(getValue());
    }
  }, [options]);

  const getOptionsFromUrl = (options_url) => {
    axiosPrivate()
      .get(options_url)
      .then((result) => {
        setOptions(result.data);
        setLoading(false);
      })
      .catch(() => {});
  };

  const changeTextInputValue = (e) => {
    if (props.getOptionsAfterChangeInputValue) {
      setLoading(true);
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        getOptionsFromUrl(`${props.options_url}?search=${e.target.value}`);
      }, 600);
      setTimer(newTimer);
    }
  };

  const onBlurTextInput = () => {
    if (props.getOptionsAfterChangeInputValue) {
      getOptionsFromUrl(props.options_url);
    }
  };

  const isOptionEqualToValue = (option, value) => {
    if (
      props.addNewValue &&
      value !== undefined &&
      value[optionValueKey] === undefined
    ) {
      return true;
    }
    return (
      value !== undefined && option[optionValueKey] === value[optionValueKey]
    );
  };

  const {
    onChange,
    optionLabelKey,
    label,
    noOptionsText,
    optionValueKey,
    size,
    disabled,
    required,
    multiple,
  } = props;

  const onChangeIsNotObjectOption = (e, value) => {
    var target = { name: props.name };
    var isNew = false;
    if (!multiple && typeof value === "object" && value !== null) {
      target["value"] = value.inputValue;
      isNew = true;
    } else {
      target["value"] = value;
    }
    onChange({ target: target }, isNew);
  };

  if (props.isObjectOption) {
    return (
      <div style={{ display: props.showExternalButton ? "flex" : "" }}>
        <Autocomplete
          PopperComponent={MenuOptionPopper}
          disabled={disabled || props.readOnly}
          selectOnFocus
          multiple={multiple}
          clearOnBlur
          handleHomeEndKeys
          disableClearable={props.disableClearable}
          getOptionDisabled={
            props.getOptionDisabled ? props.getOptionDisabled : undefined
          }
          size={size}
          id={props.name}
          readOnly={props.readOnly}
          value={
            props.multiple && checkIfIsUndefinedOrNullOrEmpty(value)
              ? []
              : value
          }
          onChange={(e, value) => {
            if (
              multiple &&
              (value.length === 0) & (props.fixedChosenOptions.length > 0)
            ) {
              value = props.fixedChosenOptions;
            }
            onChange(
              e,
              value !== null ? value : { optionValueKey: value },
              optionValueKey,
              props.name
            );
          }}
          options={options}
          getOptionLabel={(option) => {
            return option[optionLabelKey] != undefined
              ? option[optionLabelKey]
              : "";
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              label={label.charAt(0).toUpperCase() + label.slice(1)}
              variant={props.variant}
              fullWidth
              onChange={changeTextInputValue}
              onBlur={onBlurTextInput}
              style={props.style}
              error={props.error}
              helperText={props.helperText}
            />
          )}
          noOptionsText={noOptionsText || t("no_options")}
          isOptionEqualToValue={(option, value) =>
            isOptionEqualToValue(option, value)
          }
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (props.allwaysShowOptions) {
              for (const option of props.allwaysShowOptions) {
                if (
                  filtered.find(
                    (x) =>
                      x[props.optionValueKey] === option[props.optionValueKey]
                  ) === undefined
                ) {
                  filtered.push(option);
                }
              }
            }

            const { inputValue } = params;
            if (props.addNewValue) {
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option[optionLabelKey]
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  [optionLabelKey]: `Dodaj "${inputValue}"`,
                });
              }
            }
            return filtered;
          }}
          loading={loading}
          limitTags={props.limitTags}
          onClose={props.onClose}
          variant={props.variant}
          filterSelectedOptions={props.filterSelectedOptions}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option? option[optionLabelKey] : null}
                {...getTagProps({ index })}
                size="small"
                disabled={
                  multiple &&
                  option ? props.fixedChosenOptions.includes(option[optionValueKey]) : true
                }
              />
            ))
          }
        />
        {props.showExternalButton ? (
          <IconButtonWithTooltip
            style={{ border: "3px solid var(--secondary)" }}
            title={t("add")}
            onClick={props.onClickAddItemToAutocompletedField}
          >
            <AddButton
              fontSize="small"
              sx={{
                color: "var(--secondary)",
                fontSize: "small",
              }}
            />
          </IconButtonWithTooltip>
        ) : null}
      </div>
    );
  } else {
    return (
      <Autocomplete
        disabled={disabled || props.readOnly}
        selectOnFocus
        multiple={multiple}
        clearOnBlur
        PopperComponent={MenuOptionPopper}
        disableClearable={props.disableClearable}
        handleHomeEndKeys
        size={size}
        name={props.name}
        id={props.name}
        value={
          props.multiple
            ? checkIfIsUndefinedOrNullOrEmpty(props.value)
              ? []
              : Array.isArray(props.value)
              ? props.value
              : [props.value]
            : props.value
        }
        onChange={(e, value) => onChangeIsNotObjectOption(e, value, props.name)}
        options={options}
        readOnly={props.readOnly}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            label={label.charAt(0).toUpperCase() + label.slice(1)}
            variant={props.variant}
            fullWidth
            style={props.style}
            error={props.error}
            helperText={props.helperText}
          />
        )}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string" || option === null) {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option;
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {typeof option === "string" ? option : option.newValue}
          </li>
        )}
        noOptionsText={noOptionsText || t("no_options")}
        // filterOptions={(options, params) => {
        //   const filtered = filter(options, params);

        //   const { inputValue } = params;
        //   if (props.addNewValue) {
        //     // Suggest the creation of a new value
        //     const isExisting = options.some(
        //       (option) => inputValue === option[optionLabelKey]
        //     );
        //     if (inputValue !== "" && !isExisting) {
        //       filtered.push({
        //         inputValue,
        //         newValue: `${t("add")} "${inputValue}"`,
        //       });
        //     }
        //   }
        //   return filtered;
        // }}
        loading={loading}
        limitTags={props.limitTags}
        onClose={props.onClose}
      />
    );
  }
}

AutocompleteField.propTypes = {
  options: PropTypes.array,
  optionLabelKey: PropTypes.string,
  optionValueKey: PropTypes.string,
  onChange: PropTypes.func,
  options_url: PropTypes.string,
  label: PropTypes.string,
  noOptionsText: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  valueObject: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  required: PropTypes.bool,
  addNewValue: PropTypes.bool,
  getOptionsAfterChangeInputValue: PropTypes.bool,
  isObjectOption: PropTypes.bool,
  style: PropTypes.object,
  disableClearable: PropTypes.bool,
  multiple: PropTypes.bool,
  limitTags: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  variant: PropTypes.string,
  allwaysShowOptions: PropTypes.array,
  showExternalButton: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  getOptionDisabled: PropTypes.func,
  filterSelectedOptions: PropTypes.bool,
  fixedChosenOptions: PropTypes.array,
};

AutocompleteField.defaultProps = {
  options: [],
  optionLabelKey: "name",
  optionValueKey: "id",
  options_url: undefined,
  label: "",
  disabled: false,
  value: null,
  required: false,
  addNewValue: true,
  getOptionsAfterChangeInputValue: false,
  isObjectOption: true,
  size: "small",
  style: {},
  disableClearable: false,
  multiple: false,
  onClose: () => {},
  variant: "outlined",
  showExternalButton: false,
  error: false,
  fixedChosenOptions: [],
};
