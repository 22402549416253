import React, { useCallback, useEffect } from "react";
import LocationLevel from "./LocationLevel/LocationLevel";
import { Grid, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  isEmptyValue,
  removeElementsInArrayByIndex,
} from "../../../helpers/methods";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import useLocationService from "../../../services/locationService";
import MiniButton from "../../base/MiniButton/MiniButton";

const LocationRecord = React.memo((props) => {
  const { t } = useTranslation();

  const { getEmptyLocationLevel, getLevelFieldId } = useLocationService();

  const onChangeLocation = useCallback(
    (locationLevels) => {
      props.onChangeLocation(locationLevels, props.index);
    },
    [props.index, props.onChangeLocation]
  );

  const onChangeLocationLevel = (value_key, level) => {
    var locationLevels = [...props.locationLevels];
    const indexLevel = level - 1;
    if (locationLevels.length > level) {
      locationLevels = removeElementsInArrayByIndex(locationLevels, indexLevel);
    }
    locationLevels[indexLevel].id = value_key["id"];
    locationLevels[indexLevel].object_name = value_key["object_name"];

    if (level < props.maxAllowedLevelNumber) {
      locationLevels.push(getEmptyLocationLevel(value_key["id"], level + 1));
    }
    onChangeLocation(locationLevels);
  };

  const onClearLocationLevel = (e, level) => {
    var locationLevels = [...props.locationLevels];
    const indexLevel = level - 1;

    locationLevels[indexLevel].id = undefined;
    locationLevels.splice(indexLevel + 1);
    onChangeLocation(locationLevels);
  };

  useEffect(() => {
    let locationLevels = props.locationLevels;
    var i = locationLevels.length;
    while (i--) {
      let locationObject = locationLevels[i];
      if (
        locationObject.parent === null &&
        locationObject.id === undefined &&
        locationObject.level > 1
      ) {
        locationLevels.splice(i, 1);
      }
    }
    props.onChangeLocation(locationLevels, props.index);
  }, [props.locationLevels]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
            {props.showTitile && (
              <Typography
                variant="subtitle2"
                className="capitalize-first-letter"
              >
                {t("other.location_record.location")}
                {props.showLocationNumber ? ` ${props.index + 1}` : ""}
              </Typography>
            )}
            {!props.readOnly && props.showButtonDeleteLocation && (
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ padding: 0, paddingLeft: 0.5, color: "var(--alert)" }}
                onClick={() => {
                  props.onDeleteLocation(props.index);
                }}
              >
                <RemoveCircleIcon fontSize="inherit" />
              </IconButton>
            )}
          </Grid>
          <Grid item sm={6} xs={6} textAlign={"end"}>
            {!props.readOnly && props.showButtonAddLocation && (
              <MiniButton
                variant="contained"
                size="small"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={props.onAddLocation}
              >
                {t("other.location_record.add_location")}
              </MiniButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          columns={{ sm: 15, xs: 12 }}
          spacing={1}
          direction={props.displayInRows ? "column" : "row"}
        >
          {props.locationLevels.map((locationLevel, _index) => (
            <LocationLevel
              readOnly={props.readOnly}
              index={props.index}
              level={_index + 1}
              value={locationLevel.id}
              locationParent={locationLevel.parent}
              onChange={onChangeLocationLevel}
              onClear={onClearLocationLevel}
              createTicketLocation={props.createTicketLocation}
              maxAllowedLevelNumber={props.maxAllowedLevelNumber}
              required={props.showAllObjectsAndLevels ? _index < 2 : true}
              errorField={props.unfillRequiredFields.includes(
                getLevelFieldId(props.index, _index)
              )}
              ticketOm={props.ticketOm}
              helperTextField={
                props.unfillRequiredFields.includes(
                  getLevelFieldId(props.index, _index)
                ) && t("field_required")
              }
              showEmptyLevel={props.showEmptyLevel}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
});

LocationRecord.propTypes = {
  index: PropTypes.number,
  locationLevels: PropTypes.array,
  onChangeLocationLevel: PropTypes.func,
  showLocationNumber: PropTypes.bool,
  onDeleteLocation: PropTypes.func,
  showButtonDeleteLocation: PropTypes.bool,
  showButtonAddLocation: PropTypes.bool,
  onAddLocation: PropTypes.func,
  unfillRequiredFields: PropTypes.array,
  readOnly: PropTypes.bool,
  maxAllowedLevelNumber: PropTypes.number,
  showAllObjectsAndLevels: PropTypes.bool,
  showEmptyLevel: PropTypes.bool,
};

LocationRecord.defaultProps = {
  locationLevels: [],
  showLocationNumber: false,
  showButtonDeleteLocation: false,
  unfillRequiredFields: [],
  readOnly: false,
  maxAllowedLevelNumber: 5,
  showAllObjectsAndLevels: false,
  showTitile: true,
  displayInRows: false,
  showEmptyLevel: false,
};

export default LocationRecord;
