import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import FloatField from "../../base/FloatField/FloatField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";
import SelectField from "../../field/SelectField";

const OwnerDetailsForm = (props) => {
  const { t } = useTranslation();


  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={6}>
        <TextFieldFormControl
          name="owner_full_name"
          readOnly={props.readOnly}
          required
          value={props.ownerData?.owner_full_name}
          label={t("dialog.owner_details.owner_full_name")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormControl
          name="owner_short_name"
          readOnly={props.readOnly}
          value={props.ownerData?.owner_short_name}
          label={t("dialog.owner_details.owner_short_name")}
          onChange={props.onChange}
          
        />
      </Grid>
      <Grid item xs={6}>
        <SelectField
          label={t("dialog.owner_details.owner_kind")}
          name="owner_kind"
          value={props.ownerData?.owner_kind}
          options={props.ownerKinds}
          isObjectOption={false}
          onChange={props.onChange}
          readOnly={props.readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormControl
          name="owner_legal"
          readOnly={props.readOnly}
          value={props.ownerData?.owner_legal}
          label={t("dialog.owner_details.owner_legal")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormControl
          name="owner_nip"
          readOnly={props.readOnly}
          value={props.ownerData?.owner_nip}
          label={t("dialog.owner_details.owner_nip")}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormControl
          name="owner_krs"
          readOnly={props.readOnly}
          value={props.ownerData?.owner_krs}
          label={t("dialog.owner_details.krs")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormControl
          name="owner_regon"
          readOnly={props.readOnly}
          value={props.ownerData?.owner_regon}
          label={t("dialog.owner_details.owner_regon")}
          onChange={props.onChange}
        />
      </Grid>

      <Grid item xs={6}>
        <TrueFalseSelectField
          name="owner_active"
          readOnly={props.readOnly}
          valuesAsBool
          value={props.ownerData?.owner_active}
          label={t("dialog.owner_details.owner_active")}
          onChange={props.onChange}
          addEmptyOptions={false}
        />
      </Grid>

      <Grid item xs={6}>
        {props.ownerData?.owner_active === false &&
          <SelectField
            name="owner_deactivated_reason"
            value={props.ownerData?.owner_deactivated_reason}
            options={props.deactivationReasons}
            isObjectOption={false}
            required
            readOnly={props.readOnly}
            label={t("dialog.owner_details.owner_deactivated_reason")}
            onChange={props.onChange}
          />
        }
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="owner_note"
          multiline
          rows = {4}
          readOnly={props.readOnly}
          value={props.ownerData?.owner_note}
          label={t("dialog.owner_details.note")}
          onChange={props.onChange}
        />
      </Grid>
    </Grid>
  );
};

OwnerDetailsForm.propTypes = {
  ownerData: PropTypes.object,
  readOnly: PropTypes.bool,
  deactivationReasons: PropTypes.array,
  onEditAddress: PropTypes.func,
  onAddAddress: PropTypes.func,
  onRemoveAddress: PropTypes.func,
  onEditPhone: PropTypes.func,
  onAddPhone: PropTypes.func,
  onRemovePhone: PropTypes.func,
  onEditMail: PropTypes.func,
  onAddMail: PropTypes.func,
  onRemoveMail: PropTypes.func,
};

OwnerDetailsForm.defaultProps = {
  deactivationReasons: [],
  readOnly: true,
};

export default OwnerDetailsForm;
