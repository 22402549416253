import i18n from "../../../i18n";
import PropTypes from "prop-types";
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import PreviewIcon from '@mui/icons-material/Preview';
import MiniDrawer from "../MiniDrawer";
import hasUserPermission, {
  IS_K,
  IS_KT,
  IS_RR,
  IS_ADMIN,
  IS_TB,
  IS_WAREHOUSE,
} from "../../../helpers/userPermissions";
import { useAuth } from "../../../context/auth";
import { CircularProgress } from "@mui/material";
import ReceiptIcon from '@mui/icons-material/Receipt';

export const FUNCTION_DRAWER_TYPE_ALL = "all";
export const FUNCTION_DRAWER_TYPE_OFFERS = "offers";
export const FUNCTION_DRAWER_TYPE_PURCHASES = "purchases";

function FunctionsDrawer(props) {
  const { user } = useAuth();

  const topDrawerActionIcons = [
    {
      label: i18n.t("drawer.function_drawer.update_offer"),
      icon: <UpdateIcon />,
      permissionList: [IS_ADMIN, IS_K],
      disabled: !props.isUpdateToNewOfferButtonActive,
      callback : props.onCreateCopyOffer,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
    {
      label: i18n.t("drawer.function_drawer.offer_new_variant"),
      icon: <AddIcon />,
      permissionList: [IS_ADMIN, IS_K],
      disabled : props.dialogMode === 'newOffer',
      callback : props.onCreateNewVersion,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
    {
      label: i18n.t("drawer.function_drawer.inner_offer"),
      icon: <DriveFileMoveIcon />,
      permissionList: [IS_ADMIN, IS_K],
      disabled: !props.isAllowedToCreateInnerOffer,
      callback : props.onCreateInnerOffer,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
    {
      label: i18n.t("drawer.function_drawer.cancel_offer"),
      icon: <DoDisturbIcon  />,
      permissionList: [IS_ADMIN, IS_K],
      disabled: !props.isAllowedToCancel || props.dialogMode === 'newOffer' && props.isAllowedToCreateInnerOffer,
      callback : props.onCancelOffer,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
  ];
  
  const middleDrawerActionIcons = [
    {
      label: i18n.t("drawer.function_drawer.send_to_join_offer"),
      icon: <LinkIcon />,
      permissionList: [IS_ADMIN, IS_K],
      disabled: props.readOnly || props.dialogMode === 'newOffer' || props.disableSaveButton,
      callback : props.onSendToJoinOffer,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
    {
      label: i18n.t("drawer.function_drawer.send_to_k"),
      icon: <CheckIcon />,
      permissionList: [IS_ADMIN, IS_KT, IS_TB],
      callback : props.onSendAfterJoin,
      disabled: props.dialogMode === 'joinOffer' ? props.disableSaveButton : props.readOnly || props.dialogMode === 'newOffer' || props.disableSaveButton,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
    {
      label: i18n.t("drawer.function_drawer.show_offer_preview"),
      icon: <PreviewIcon />,
      permissionList: [IS_ADMIN, IS_RR, IS_K],
      callback : props.onOfferPdfViewer,
      disabled: props.disablePreviewOffer,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
    {
      label: i18n.t("drawer.function_drawer.accept_and_send_to_k"),
      icon: <CheckIcon />,
      permissionList: [IS_ADMIN, IS_RR],
      callback : props.onAcceptRR,
      disabled: props.dialogMode === 'acceptRR' ? props.disableSaveButton : props.readOnly || props.dialogMode === 'newOffer' || props.disableSaveButton,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
    {
      label: i18n.t("drawer.function_drawer.subcontractors_offers"),
      icon: <ShareIcon />,
      permissionList: [IS_ADMIN, IS_K],
      disabled: true,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
    {
      label:  i18n.t("drawer.function_drawer.invoices"),
      icon:  <ReceiptIcon/>,
      permissionList: [IS_ADMIN, IS_WAREHOUSE],
      callback :  props.showInvoives,
      drawerType: FUNCTION_DRAWER_TYPE_PURCHASES
    },
  ];
  
  const bottomDrawerActionIcons = [
    {
      label: i18n.t("drawer.function_drawer.request_change_data"),
      icon: <RestorePageIcon />,
      permissionList: [IS_ADMIN, IS_K],
      disabled: !props.isAllowedToRequestChangeData,
      callback : props.onRequestChangeData,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
    {
      label: i18n.t("drawer.function_drawer.generate_offer"),
      icon: <CheckIcon />,
      permissionList: [IS_ADMIN, IS_K],
      disabled: props.readOnly || props.dialogMode === 'newOffer' || props.disableSaveButton,
      callback: props.onGenerateOffer,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },
    {
      label:  props.dialogMode === 'newOffer' ? i18n.t("drawer.function_drawer.create") :  i18n.t("drawer.function_drawer.save"),
      icon:  props.isSavingData ?<CircularProgress /> : <SaveIcon />,
      permissionList: [IS_ADMIN, IS_K, IS_KT, IS_TB, IS_RR],
      disabled:  props.disableSaveButton,
      callback : props.onSaveOfferChanges,
      drawerType: FUNCTION_DRAWER_TYPE_OFFERS 
    },

    {
      label: props.poId === undefined ? i18n.t("drawer.function_drawer.create") :  i18n.t("drawer.function_drawer.save"),
      icon:  props.isSavingData ?<CircularProgress /> : <SaveIcon />,
      permissionList: [IS_ADMIN, IS_WAREHOUSE],
      disabled : props.readOnly,
      callback :  props.onSaveChanges,
      drawerType: FUNCTION_DRAWER_TYPE_PURCHASES
    },
    
    {
      label: i18n.t("drawer.function_drawer.send_order") ,
      icon:  <SendIcon />,
      permissionList: [IS_ADMIN, IS_WAREHOUSE],
      disabled : props.readOnly || props.poId === undefined || props.isSendButtonDisabled,
      callback :  props.onSendPurchase,
      drawerType: FUNCTION_DRAWER_TYPE_PURCHASES
    },
    {
      label: i18n.t("drawer.function_drawer.close"),
      icon: <CloseIcon />,
      permissionList: [],
      callback :  props.onClose,
      drawerType: FUNCTION_DRAWER_TYPE_ALL
    },
  ];

  const getDrawerActionIconsContent = (drawerActionIcons) => {
    return drawerActionIcons
      .filter((drawerActionIcon) =>
        hasUserPermission(drawerActionIcon.permissionList, user) &&
        (drawerActionIcon.drawerType === FUNCTION_DRAWER_TYPE_ALL ||
        drawerActionIcon.drawerType === props.drawerType)
      )
      .map((drawerActionIcon) => {
        const { pageName, link, permissionList, ...rest } = drawerActionIcon;
        return {
          ...rest,
        };
      });
  };

  const topDrawerIcons = getDrawerActionIconsContent(topDrawerActionIcons);
  const middleDrawerIcons = getDrawerActionIconsContent(middleDrawerActionIcons);
  const bottomDrawerIcons = getDrawerActionIconsContent(bottomDrawerActionIcons);

  return (
    <MiniDrawer
      canHideDrawer = {false}
      initialOpen = {true}
      showLangugeOptions = {false}
      topDrawerActionIcons={topDrawerIcons}
      showTopDrawerActionIcons={topDrawerIcons.length > 0}
      middleDrawerActionIcons={middleDrawerIcons}
      showMiddleDrawerActionIcons={middleDrawerIcons.length > 0}
      bottomDrawerActionIcons={bottomDrawerIcons}
      showBottomDrawerActionIcons={bottomDrawerIcons.length > 0}
    >
      {props.children}
    </MiniDrawer>
  );
}

export default FunctionsDrawer;

FunctionsDrawer.propTypes = {
  readOnly : PropTypes.bool,
  onClose : PropTypes.func,
  onSaveOfferChanges : PropTypes.func,
  onCancelOffer : PropTypes.func,
  onSendToJoinOffer : PropTypes.func,
  onSendAfterJoin : PropTypes.func,
  dialogMode: PropTypes.oneOf(["newOffer", "existOffer", "joinOffer"]),
  onGenerateOffer : PropTypes.func,
  onDecrementPayers : PropTypes.func,
  onCreateNewVersion : PropTypes.func,
  isUpdateToNewOfferButtonActive : PropTypes.bool,
  onCreateCopyOffer : PropTypes.func,
  drawerType : PropTypes.string,
  hasPermissionToEdit : PropTypes.bool,
  isAllowedToRequestChangeData : PropTypes.bool,
  onRequestChangeData : PropTypes.func
};

FunctionsDrawer.defaultProps = {
  readOnly : false,
  hasPermissionToEdit : false,
  dialogMode: "newOffer",
  isUpdateToNewOfferButtonActive : false,
  drawerType : FUNCTION_DRAWER_TYPE_OFFERS,
  isAllowedToRequestChangeData : false
};
