import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


const ValidationForm = (props) => {

  const { t } = useTranslation();

  return (
    <>
      {!props.isRequiredeFieldsValid &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.not_all_required_fields_are_completed")}
        </Typography>}
      {!props.sumSettlementRatesIsCorrectly &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.sum_of_settlement_rate_is_not_equal_one")}
        </Typography>}
      {props.offerCostIsEqualZero &&
        <Typography
          variant="subtitle1"
          color={"error"}
          style={{
            textTransform: "uppercase",
          }}>
          {t("form.validation_form.sum_of_offer_must_be_more_than_zero")}
        </Typography>}
    </>
  );
};

ValidationForm.propTypes = {
  isRequiredeFieldsValid: PropTypes.bool,
  sumSettlementRatesIsCorrectly: PropTypes.bool,
  offerCostIsEqualZero : PropTypes.bool
};

ValidationForm.defaultProps = {
  isRequiredeFieldsValid: true,
  sumSettlementRatesIsCorrectly: true,
  offerCostIsEqualZero : false
};

export default ValidationForm;
