import React, { useCallback,  } from "react";
import PropTypes from "prop-types";
import { Grid, IconButton,  Tooltip } from "@mui/material";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import EnclosureDetailsDialog from "../../dialog/EnclosureDetailsDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import useDialog from "../../../hooks/useDialog";
import ConfirmationDialog from "../../dialog/ConfirmationDialog/confirmationDialog";
import DocsDetailsDialog from "../../dialog/DocsDetailsDialog";
import AddPartCertificationDialog from "../../dialog/AddPartCertificationDialog";

function FileTableCell(props) {
  const { t } = useTranslation();

  const isShowPreview = props.file.content_type === 'application/pdf'
  const canBeRemoved = !props.readOnly
  const miniatureUrl = props.file.miniature_download_link

  const getFileName = () => {
    switch (props.fileType) {
      case "docs":
        return props.file.docs_name
      case "certs":
        return props.file.cert_name
      default:
        return props.file.enclosure_name
    }
  }
  const fileName = getFileName()

  const [
    openNotesDialog,
    onOpenNotesDialog,
    onCloseNotesDialog,
  ] = useDialog();

  const [
    openConfimartionDialog,
    onOpenConfimartionDialog,
    onCloseConfimartionDialog,
  ] = useDialog();

 

  const downloadFile = useCallback((e) => {
    e.stopPropagation();

    props.onDownload(
      props.file.id,
      fileName,
      props.index
    );
  }, [props.file, props.fileType, props.index]);

  const previewFile = useCallback((e) => {
    e.stopPropagation();
    props.onPreview(props.file.id, props.index);
  }, [props.file, props.fileType, props.index]);

  const updateFile= useCallback((enclosure) => {
    props.onUpdateFile(enclosure, props.index);
    onCloseNotesDialog();
  }, [props.index]);

  const deleteFile = useCallback((e) => {
    e.stopPropagation();
    props.onDeleteFile(props.file.id, props.index);
    onCloseConfimartionDialog();
  }, [props.file, props.index]);



  return (
    <Grid
    container
    direction="row"
    justifyContent="space-around"
    alignItems="center"
    columnSpacing={1}
>

          <Grid item xs={12} sm={1}>
            <Tooltip title={t("other.enclosure_data_row.details")}>
              <IconButton color="primary" onClick={onOpenNotesDialog} sx={{ padding: "5px" }}>
                <InfoIcon fontSize="medium" />
              </IconButton >
            </Tooltip>
          </Grid>


          <Grid item xs={12} sm={1}>
            <Tooltip title={t("other.enclosure_data_row.download")}>
              <IconButton color="primary" onClick={downloadFile} sx={{ padding: "5px" }}>
                <DownloadIcon fontSize="medium" />
              </IconButton >
            </Tooltip>
          </Grid>

        <Grid item xs={12} sm={1}>
          {isShowPreview && (
            <Tooltip title={t("other.enclosure_data_row.preview")}>
              <IconButton
                color="primary"
                onClick={previewFile}
                sx={{ padding: "5px" }}
              >
                <PreviewOutlinedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}
          {miniatureUrl && 
            <Tooltip title={t("other.enclosure_data_row.preview")}>
              <img
                id="pictureId"
                src={miniatureUrl}
                width="70%"
                height="70%"
                alt={""}
                onClick={previewFile}
              ></img>
            </Tooltip>
         }
        </Grid>
        <Grid item xs={12} sm={1}>
          {canBeRemoved && <Tooltip title={t("other.enclosure_data_row.delete")}>
            <IconButton color="primary" onClick={onOpenConfimartionDialog} sx={{ padding: "5px" }}>
             <DeleteIcon fontSize="medium" /> 
            </IconButton >
          </Tooltip>
          }
        </Grid>

  
      {openNotesDialog && props.fileType === "enclosure" && (
        <EnclosureDetailsDialog
          showSubcontractorSelect={props.showSubcontractorSelect}
          showEnclosureType={props.showEnclosureType}
          open={openNotesDialog}
          onClose={onCloseNotesDialog}
          enclosure={props.file}
          editable={!props.readOnly}
          onSubmit={updateFile}
        />
      )}
      {openNotesDialog && props.fileType === "docs" && (
        <DocsDetailsDialog
          open={openNotesDialog}
          onClose={onCloseNotesDialog}
          docsId={props.file.id}
          editable={!props.readOnly}
          readOnly={props.readOnly}
          onSubmit={updateFile}
        />
      )}
      {openNotesDialog && props.fileType === "certs" && (
        <AddPartCertificationDialog
          open={openNotesDialog}
          onClose={onCloseNotesDialog}
          certificate = {props.file}
          editable={!props.readOnly}
          readOnly={props.readOnly}
          onSubmit={updateFile}
          mode={"view"}
        />
      )}
      {openConfimartionDialog && (
        <ConfirmationDialog
          open={openConfimartionDialog}
          onYes={deleteFile}
          onNo={onCloseConfimartionDialog}
          title={t(`other.enclosure_data_row.remove_${props.fileType}`)}
          content={t(`other.enclosure_data_row.are_you_sure_to_remove_${props.fileType}`)}
          maxWidth={"xs"}
          titleSx={{ color: "var(--alert)" }}
          contentSx={{ fontFamily: "Roboto", textAlign: "center" }}
          btnYesColor={"error"}
          btnNoColor={"secondary"}
          showDialogContentDividers={false}
        />
      )

      }
    </Grid>
  );
}

FileTableCell.propTypes = {
  file: PropTypes.object,
  fileType: PropTypes.oneOf(["docs", "certs", "enclosure"]),
  readOnly: PropTypes.bool,
  onDownload : PropTypes.func,
  onPreview : PropTypes.func,
  onUpdateFile : PropTypes.func,
  onDeleteFile : PropTypes.func,
}

FileTableCell.defaultProps = {
  readOnly: true,
  fileType: 'docs'
}

export default FileTableCell;  