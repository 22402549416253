import { AUTOCOMPLETE_FIELD_TYPE, DATE_FIELD_TYPE, NUMERIC_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../../helpers/constants";
import i18n from "../../../i18n";
import DateService from "../../../services/dateService";
import UploadIcon from '@mui/icons-material/Upload';
import FileTableCell from "../../other/FileTableCell/FileTableCell";


export const MATERIALS_SHOPPING_TABLE_NAME = "xxx";

export const MATERIALS_SHOPPING_TABLE_FIELDS_CONFIG = [
  {
    name: "po_number",
    getValueCallback: (rowData) => rowData.po_nr,
    label: i18n.t("table.materials_shopping_table.po_number"),
    editable: false,
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "po_payer",
    getValueCallback: (rowData) => rowData.po_payer,
    label: i18n.t("table.materials_shopping_table.po_payer"),
    editable: true,
    required: true,
    dataType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.po_payer,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
    }),
  },
  {
    name: "po_issuer",
    getValueCallback: (rowData) => rowData.po_issuer,
    label: i18n.t("table.materials_shopping_table.po_issuer"),
    editable: true,
    required: true,
    dataType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.po_issuer,
      isObjectOption: true,
      optionLabelKey: "subcontractor_short_name",
      addNewValue: false,
    }),
  },
  {
    name: "po_person",
    getValueCallback: (rowData) => rowData.po_person,
    label: i18n.t("table.materials_shopping_table.po_person"),
    editable: true,
    required: false,
    dataType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.po_person,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      showExternalButton: false,
    }),
  },
  {
    name: "po_mail",
    getValueCallback: (rowData) => rowData.po_mail,
    label: i18n.t("table.materials_shopping_table.po_mail"),
    editable: true,
    required: true,
    dataType: TEXT_FIELD_TYPE,

  },
  {
    name: "Created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.created
      ),
    label: i18n.t("table.materials_shopping_table.created"),
    editable: false,
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "po_date_send",
    getValueCallback: (rowData) => rowData.po_date_send,
    label: i18n.t("table.materials_shopping_table.date_send"),
    editable: false,
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "po_value",
    getValueCallback: (rowData) => rowData.po_value,
    label: i18n.t("table.materials_shopping_table.value"),
    editable: false,
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "po_date_forecast",
    getValueCallback: (rowData) => rowData.po_date_forecast,
    label: i18n.t("table.materials_shopping_table.date_forecast"),
    editable: true,
    dataType: DATE_FIELD_TYPE,
  },
  {
    name: "po_doc",
    getValueCallback: (rowData,
      index,
      onClickAddDoc,  
      onUpdateDoc, 
      onPreviewDoc, 
      onDownloadDoc,
      onDeleteDoc,
      allowEdit) => {
   
      return rowData.po_doc
        ?      <FileTableCell
        fileType={'docs'} 
        file={rowData.po_doc}
        index = {index} 
        onUpdateFile={onUpdateDoc}
        onPreview={onPreviewDoc}
        onDownload = {onDownloadDoc}
        onDeleteFile = {(docsId, index) =>onDeleteDoc(rowData.id, index)}
        readOnly={!allowEdit}/>
        : (
          allowEdit?
        <UploadIcon  onClick={(e) => onClickAddDoc(e, rowData.id)} />: "")
        ;
    },
    label: i18n.t("table.materials_shopping_table.url_origin"),
    filterType: null,
    editable: true,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
export const MATERIALS_SHOPPING_LINES_TABLE_FIELDS_CONFIG = [
  {
    name: "poli_nr",
    getValueCallback: (rowData) => rowData.poli_nr,
    label: i18n.t("table.materials_shopping_table.poli_number"),
    editable: false,
    dataType: TEXT_FIELD_TYPE,
  },
  {
    name: "part_name",
    getValueCallback: (rowData) => rowData.part_name,
    label: i18n.t("table.materials_shopping_table.poli_part"),
    editable: false,
    dataType: TEXT_FIELD_TYPE,
  },

  {
    name: "poli_amount",
    getValueCallback: (rowData) => rowData.poli_amount,
    label: i18n.t("table.materials_shopping_table.poli_amount"),
    editable: true,
    dataType: NUMERIC_FIELD_TYPE,
    
  },
  {
    name: "poli_unit",
    getValueCallback: (rowData) => i18n.t(`other.units.${rowData.poli_unit}`),
    editable: false,
    label: i18n.t("table.stock_warehouse_table.part_unit"),
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "poli_price_plan",
    getValueCallback: (rowData) => rowData.poli_price_plan,
    label: i18n.t("table.materials_shopping_table.poli_price_plan"),
    editable: false,
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "poli_price_value",
    getValueCallback: (rowData) => rowData.poli_price_value,
    label: i18n.t("table.materials_shopping_table.value"),
    editable: false,
    dataType: NUMERIC_FIELD_TYPE,
  },
  {
    name: "poli_note",
    getValueCallback: (rowData) => rowData.poli_note,
    label: i18n.t("table.materials_shopping_table.poli_note"),
    editable: true,
    dataType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "Created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.created),
    label: i18n.t("table.materials_shopping_table.created"),
    editable: false,
    filterType: DATE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "poli_status",
    getValueCallback: (rowData) => rowData.poli_status,
    label: i18n.t("table.materials_shopping_table.poli_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.poli_status,
      isObjectOption: true,
      optionLabelKey: "poli_status",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  } 
];
