import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useForm } from "../../../../../hooks/useForm";
import useDialog from "../../../../../hooks/useDialog";
import { useTranslation } from "react-i18next";
import OwnerForm from "../../UniversalDetailsDrawerForms/OwnerForm";
import LoaderWrapper from "../../../../wrapper/LoaderWrapper";
import SelectDeactivateReasonDialog from "../../../../dialog/SelectDeactivateReasonDialog/SelectDeactivateReasonDialog";
import AddressDialog from "../../../../dialog/AddressDialog/AddressDialog";
import PhoneDialog from "../../../../dialog/PhoneDialog/PhoneDialog";
import MailDialog from "../../../../dialog/MailDialog";
import BoardMemberDialog from "../../../../dialog/BoardMemberDialog/BoardMemberDialog";
import useOwnerFormsService from "../../../../../hooks/useOwnerFormsService";
import BoardFormBox from "../../../../box/BoardFormBox";
import AddressFormBox from "../../../../box/AddressFormBox";
import PhoneFormBox from "../../../../box/PhoneFormBox/PhoneFormBox";
import MailFormBox from "../../../../box/MailFormBox/MailFormBox";
import OwnerHistoryLogDialog from "../../../../dialog/OwnerHistoryLogDialog/OwnerHistoryLogDialog";

const OwnerDrawerContent = (props) => {

  const { t } = useTranslation();

  const {
    formValue,
    setFormValue,
    onChangeWithCallback,
  } = useForm(props.ownerData);

  const [isLoading, setIsLoading] = useState(false);

  const [
    openLogDialog,
    onOpenLogDialog,
    handleCloseLogDialog,
  ] = useDialog();


  const [
    openDeactivateReasonsDialog,
    onOpenDeactivateReasonsDialog,
    onCloseDeactivateReasonsDialog,
  ] = useDialog();

  const {
    openAddressDialog,
    onOpenAddressDialog,
    onEditAddress,
    onRemoveAddress,
    onCloseAddressDialog,

    openBoardDialog,
    onOpenBoardDialog,
    onEditBoard,
    onRemoveBoard,
    onCloseBoardDialog,

    openMailDialog,
    onOpenMailDialog,
    onEditMail,
    onRemoveMail,
    onCloseMailDialog,

    openPhoneDialog,
    onOpenPhoneDialog,
    onEditPhone,
    onRemovePhone,
    onClosePhoneDialog,

    selectedAddressId,
    selectedBoardId,
    selectedMailId,
    selectedPhoneId } = useOwnerFormsService(props.onRefetchData)



  const handleChangeFieldValue = (name, value) => {
    setIsLoading(true);
    const oldValue = formValue[name];
    props
      .onUpdateDataPromise({ [name]: value })
      .then(() => {
      })
      .catch((error) => {
        setFormValue((prev) => ({ ...prev, [name]: oldValue }));
      })
      .finally(() => {
        setIsLoading(false);
        onCloseDeactivateReasonsDialog()
      });
  };

  const onChangeOwnerActivate = (name, value) => {
    if (value) {
      handleChangeFieldValue(name, value)
    } else {
      onOpenDeactivateReasonsDialog()
    }
  }

  const onSubmitDeactivateReason = useCallback((reason) => {
    props
      .onUpdateDataPromise({
        "owner_deactivated_reason": reason,
        "owner_active": false
      })
  }, [])

  if (isLoading) {
    return <LoaderWrapper isLoading={isLoading} />
  }


  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingX={1}
      rowGap={1}
      marginTop={1}
      marginBottom={3}
    >
      <Grid item xs={12}>
        <OwnerForm
          ownerData={formValue}
          onChangeTextField={onChangeWithCallback(handleChangeFieldValue)}
          onChangeOwnerActivate={onChangeWithCallback(onChangeOwnerActivate)}
          onChangeSelectField={onChangeWithCallback(handleChangeFieldValue)}
          deactivateReasonsList={props.filteringData?.value?.deactivation_reasons}
          ownerKinds={props.filteringData?.value?.owner_kinds}
          readOnly= {!formValue?.owner_active || props.readOnly}
        />
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <BoardFormBox
          boards={formValue?.boards}
          onEditBoard={onEditBoard}
          onAddBoard={onOpenBoardDialog}
          onRemoveBoard={onRemoveBoard}
          readOnly= {!formValue?.owner_active || props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <AddressFormBox
          addresses={formValue?.addresses}
          onEditAddress={onEditAddress}
          onAddAddress={onOpenAddressDialog}
          onRemoveAddress={onRemoveAddress}
          readOnly= {!formValue?.owner_active || props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneFormBox
          phones={formValue?.phones}
          onEditPhone={onEditPhone}
          onAddPhone={onOpenPhoneDialog}
          onRemovePhone={onRemovePhone}
          readOnly= {!formValue?.owner_active || props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <MailFormBox
          mails={formValue?.mails}
          onEditMail={onEditMail}
          onAddMail={onOpenMailDialog}
          onRemoveMail={onRemoveMail}
          readOnly= {!formValue?.owner_active || props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onOpenLogDialog}
        >
          {t(`dialog.owner_details.show_changes_history_table`)}
        </Button>
      </Grid>
      {onOpenDeactivateReasonsDialog &&
        <SelectDeactivateReasonDialog
          open={openDeactivateReasonsDialog}
          onClose={onCloseDeactivateReasonsDialog}
          deactivateReasonsList={props.filteringData?.value?.deactivation_reasons}
          onSubmit={onSubmitDeactivateReason}
        />
      }
      {openAddressDialog &&
        <AddressDialog
          addressId={selectedAddressId.current}
          open={openAddressDialog}
          ownerId={props.ownerId}
          onRefetch={props.onRefetchData}
          onClose={onCloseAddressDialog}
        />
      }
      {openPhoneDialog &&
        <PhoneDialog
          phoneId={selectedPhoneId.current}
          open={openPhoneDialog}
          ownerId={props.ownerId}
          onRefetch={props.onRefetchData}
          onClose={onClosePhoneDialog}
        />
      }
      {openMailDialog &&
        <MailDialog
          mailId={selectedMailId.current}
          open={openMailDialog}
          ownerId={props.ownerId}
          onRefetch={props.onRefetchData}
          onClose={onCloseMailDialog}
        />
      }
      {openBoardDialog &&
        <BoardMemberDialog
          boardId={selectedBoardId.current}
          open={openBoardDialog}
          ownerId={props.ownerId}
          onRefetch={props.onRefetchData}
          onClose={onCloseBoardDialog}
        />
      }
      {openLogDialog &&
        <OwnerHistoryLogDialog
          ownerId={props.ownerId}
          open={openLogDialog}
          onClose={handleCloseLogDialog}
        />
      }
    </Grid>
  );
};

OwnerDrawerContent.propTypes = {
  ownerData: PropTypes.object,
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  onRefetchTableData: PropTypes.func,
};

OwnerDrawerContent.defaultProps = {
  isLoading: false,
  readOnly: true,
};

export default OwnerDrawerContent;
