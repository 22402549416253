import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";

import { NAVIGATION_DRAWER_TYPE_RR } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import RRAdminTabs from "../../components/tabs/RRAdminTabs/RRAdminTabs";

export default function RRAdminPage(props) {
  const { pageName } = props;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_RR}
    >
      <DefaultPageWrapper titleKey={"rr_admin"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <RRAdminTabs pageName={pageName} />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
