import { AUTOCOMPLETE_FIELD_TYPE, BUFFER_EDIT_PERMISSION, CREATE_INVOCIE_PERMISSION, CREATE_PURCHASE_PERMISSION, EDIT_AGREEMENTS_PERMISSION, EDIT_CORRESPONDENCE_PERMISSION, EDIT_OWNERS_PERMISSION, EDIT_SUBCONTRACTORS_PERMISSION, EDIT_TENANTS_PERMISSION, MANAGE_ITEMS_TREE_PERMISSION, MANAGE_ORDERS_PERMISSION, MANAGE_PARTS_PERMISSION, MANAGE_PLANNER_PERMISSION, MANAGE_SERVICES_PERMISSION, MANAGE_TASKS_PERMISSION, MANAGE_TEAMS_PERMISSION, MANAGE_TICKETS_PERMISSION} from "../../helpers/constants";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import i18n from "../../i18n";
import permissionTableService from "../../services/permissionTableService"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const USER_PERMISSION_TABLE_CONFIG = [
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user_fullname,
    label: i18n.t("table.admin_perm_table.user"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },

  {
    name: "tickets_management",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, MANAGE_TICKETS_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.tickets_management"),
    filterType: undefined
  },
  {
    name: "orders_management",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, MANAGE_ORDERS_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.orders_management"),
    filterType: undefined
  },
  {
    name: "tasks_management",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, MANAGE_TASKS_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.tasks_management"),
    filterType: undefined
  },
  {
    name: "planner_management",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, MANAGE_PLANNER_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.planner_management"),
    filterType: undefined
  },
  {
    name: "teams_management",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, MANAGE_TEAMS_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.teams_management"),
    filterType: undefined
  },
  {
    name: "owners_edit",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, EDIT_OWNERS_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.owners_edit"),
    filterType: undefined
  },
  {
    name: "edit_tenants",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, EDIT_TENANTS_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.edit_tenants"),
    filterType: undefined
  },
  {
    name: "edit_subcontractors",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, EDIT_SUBCONTRACTORS_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.edit_subcontractors"),
    filterType: undefined
  },
  {
    name: "agreements_edit",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, EDIT_AGREEMENTS_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.agreements_edit"),
    filterType: undefined
  },
  {
    name: "edit_correspondence",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, EDIT_CORRESPONDENCE_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.edit_correspondence"),
    filterType: undefined
  },
  {
    name: "manage_services",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions,MANAGE_SERVICES_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.manage_services"),
    filterType: undefined
  },
  {
    name: "part_manage",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, MANAGE_PARTS_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.other_warehouse_perm_table.part_manage"),
    filterType: undefined
  },
  {
    name: "manage_items_tree",
    getValueCallback: (rowData) => permissionTableService.checkPermission(rowData.permissions, MANAGE_ITEMS_TREE_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.admin_perm_table.manage_items_tree"),
    filterType: undefined
  },
  {
    name: "create_purchase",
    getValueCallback: (rowData)=> permissionTableService.checkPermission(rowData.permissions, CREATE_PURCHASE_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.other_warehouse_perm_table.create_purchase"),
    filterType: undefined
  },
  {
    name: "create_invoice",
    getValueCallback: (rowData)=> permissionTableService.checkPermission(rowData.permissions, CREATE_INVOCIE_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.other_warehouse_perm_table.create_invoice"),
    filterType: undefined
  },
  {
    name: "buffer_edit",
    getValueCallback: (rowData)=> permissionTableService.checkPermission(rowData.permissions, BUFFER_EDIT_PERMISSION),
    valueAsCheckbox : true,
    label: i18n.t("table.other_warehouse_perm_table.buffer_edit"),
    filterType: undefined
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickDialog) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickDialog(e, rowData.id, "edit")}
        />
      );
    },
    label: i18n.t("table.admin_perm_table.edit"),
    filterType: null
  },
  {
    name: "copy",
    getValueCallback: (rowData, onClickDialog, ) => {
      return (
        <ContentCopyIcon
          onClick={(e) => onClickDialog(e, rowData.id, "copy")}
        />
      );
    },
    label: i18n.t("table.admin_perm_table.copy_permissions"),
    filterType: null
  }
];
