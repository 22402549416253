import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";
import InfoIcon from "@mui/icons-material/Info";
import FileTableCell from "../../components/other/FileTableCell/FileTableCell";

export const PO_TABLE_NAME = "po_table";
export const PO_LINES_TABLE_NAME = "po_lines_table";

export const PO_TABLE_FIELDS_CONFIG = [
  {
    name: "preview",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        <InfoIcon  onClick={(e) => onClickEdit(e, rowData.id)} />
      )
    },
    label: i18n.t("table.materials_shopping_table.preview"),
    filterType: null,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "po_nr",
    getValueCallback: (rowData) => rowData.po_nr,
    label: i18n.t("table.materials_shopping_table.po_number"),
    filterType: TEXT_FIELD_TYPE,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "po_payer",
    getValueCallback: (rowData) => rowData.po_payer,
    label: i18n.t("table.materials_shopping_table.po_payer"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.po_payer,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true
    }),
  },
  {
    name: "po_creator",
    getValueCallback: (rowData) => rowData.po_creator,
    label: i18n.t("table.materials_shopping_table.po_creator"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.creator,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
    }),
  },
  {
    name: "po_issuer",
    getValueCallback: (rowData) => rowData.po_issuer,
    label: i18n.t("table.materials_shopping_table.po_issuer"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.po_issuer,
      isObjectOption: true,
      optionLabelKey: "subcontractor_short_name",
      addNewValue: false,
      multiple: true
    }),
  },
  {
    name: "po_person",
    getValueCallback: (rowData) => rowData.po_person,
    label: i18n.t("table.materials_shopping_table.po_person"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.po_person,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      showExternalButton: false,
    }),
  },
  {
    name: "po_mail",
    getValueCallback: (rowData) => rowData.po_mail,
    label: i18n.t("table.materials_shopping_table.po_mail"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "po_value",
    getValueCallback: (rowData) => rowData.po_value,
    label: i18n.t("table.materials_shopping_table.value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.created
      ),
    label: i18n.t("table.materials_shopping_table.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "po_date_send",
    getValueCallback: (rowData) => rowData.po_date_send,
    label: i18n.t("table.materials_shopping_table.date_send"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "po_date_forecast",
    getValueCallback: (rowData) => rowData.po_date_forecast,
    label: i18n.t("table.materials_shopping_table.date_forecast"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "invoices",
    getValueCallback: (rowData, onClickEdit, onClickShowInvoices) => {
      return (
        <InfoIcon onClick={(e) => onClickShowInvoices(e, rowData.id)} />
      );
    },
    label: i18n.t("table.materials_shopping_table.invoices"),
    filterType: null,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "po_doc",
    getValueCallback: (rowData, onClickEdit, onClickShowInvoices, onPreviewDoc, onDownloadDoc) => {
      const content = (
        <FileTableCell 
        fileType={'docs'} 
        file={rowData.po_doc} 
        onPreview={onPreviewDoc}
        onDownload = {onDownloadDoc}
        readOnly={true}/>
      );
      return rowData.po_doc
        ? content
        : "";
    },
    label: i18n.t("table.materials_shopping_table.url_origin"),
    filterType: null,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];

export const PO_LINES_TABLE_FIELDS_CONFIG = [
  {
    name: "part",
    getValueCallback: (rowData) => rowData.part_name,
    label: i18n.t("table.materials_shopping_table.poli_part"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    },
  {
    name: "poli_amount",
    getValueCallback: (rowData) => rowData.poli_amount,
    label: i18n.t("table.materials_shopping_table.poli_amount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "poli_unit",
    getValueCallback: (rowData) => rowData.poli_unit,
    label: i18n.t("table.stock_warehouse_table.part_unit"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "poli_price_plan",
    getValueCallback: (rowData) => rowData.poli_price_plan,
    label: i18n.t("table.materials_shopping_table.poli_price_plan"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "poli_price_real",
    getValueCallback: (rowData) => rowData.poli_price_real,
    label: i18n.t("table.materials_shopping_table.poli_price_real"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "poli_price_value",
    getValueCallback: (rowData) => rowData.poli_price_value,
    label: i18n.t("table.materials_shopping_table.value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "poli_note",
    getValueCallback: (rowData) => rowData.poli_note,
    label: i18n.t("table.materials_shopping_table.poli_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.created
      ),
    label: i18n.t("table.materials_shopping_table.transaction_date"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "creator",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.materials_shopping_table.operator"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.creator,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
    }),
  },
  {
    name: "poli_status",
    getValueCallback: (rowData) => rowData.poli_status,
    label: i18n.t("table.materials_shopping_table.poli_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.poli_status,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },

  {
    name: "poli_date_delivery",
    getValueCallback: (rowData) => rowData.poli_date_delivery,
    label: i18n.t("table.materials_shopping_table.date_delivery"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];
