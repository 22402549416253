import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { makeRequestWithLanguage } from "../helpers/makeRequestWithLanguage";

import {
  ticketDataUrl,
  ticketDetailsDataUrl,
  ticketShortDetailsDataUrl,
  ticketFilteringDataUrl,
  ticketFilteringMyTicketsDataUrl,
  ticketAddFavorityUrl,
  ticketDeleteFavorityUrl,
  stdTicketShortDataUrl,
  ticketCategoryDataUrl,
  ticketCategoryAdminDataUrl,
  ticketCategoryFilteringDataUrl,
  ticketCategoryDetailsDataUrl,
  ticketCategoryShortDataUrl,
  createTicketUrl,
  ticketAddFlagsUrl,
  ticketDeleteFlagsUrl,
  ticketFilteringEnclosuresDataUrl,
  ticketWithEnclosureDataUrl,
  ticketPrioritySelectListUrl,
  ticketMassActionUrl,
  ticketTicketUrl,
  ticketSelectListUrl,
  ticketTicketTicketKindSelectListUrl,
  ticketTicketMergeUrl,
  ticketOfferDataUrl,
  ticketFilteringOffersDataUrl,
  myTicketDataUrl,
  ticketOfferInitDataUrl,
  ticketFilteringDataForDetails,
  ticketAreaSelectListUrl,
  ticketDataForCopyTicketUrl,
  ticketDataForClaimTicketUrl,
  myTicketOfferDataUrl,
  myTicketFilteringOffersDataUrl,
  ticketSlaDataUrl,
  ticketSlaShortDataUrl,
  ticketSlaFilteringDataUrl,
  ticketSlaDataWithDelayTimeDistributionUrl,
  ticketSlaDataWithClaimDistributionUrl,
  stdTicketAdminDataUrl,
  stdTicketAdminDetailsDataUrl,
  stdTicketAdminFilteringDataUrl,
  stdTicketAutomatAdminDataUrl,
  stdTicketAutomatAdminDetailsDataUrl,
  stdTicketAutomatAdminFilteringDataUrl,
  ticketsForOverridingDataUrl,
  overridingDataUrl,
  overridingDetailsDataUrl,
  overridingFilteringDataUrl,
  stdTicketConfigForHandOver,
  ticketChartFilteringDataUrl,
  ticketFeedbackDistributionDataUrl,
  ticketProtocolGenerateUrl,
} from "../helpers/apiUrls";
import { prepareUrlWithQueryParams, getErrorMsg } from "../helpers/methods";
import useFileService from "./fileService";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../context/snackbarAlert";

export default function useTicketService() {
  const axiosPrivate = useAxiosPrivate();
  const { uploadAttachments } = useFileService();
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const getTicketData = (searchParams, isDialogWithParamsOpen = true) => {
    if (isDialogWithParamsOpen) {
      return makeRequestWithLanguage(
        axiosPrivate,
        prepareUrlWithQueryParams(ticketDataUrl, searchParams)
      );
    }
    return Promise.resolve([]);
  };

  const getTicketWithExistEnclosuresData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketWithEnclosureDataUrl, searchParams)
    );
  };

  const getTicketDetailsData = (ticketId) => {
    return makeRequest(axiosPrivate, ticketDetailsDataUrl(ticketId));
  };

  const getTicketShortDetailsData = (ticketId) => {
    return makeRequest(axiosPrivate, ticketShortDetailsDataUrl(ticketId));
  };

  const getTicketOfferInitData = (ticketId) => {
    return makeRequest(axiosPrivate, ticketOfferInitDataUrl(ticketId));
  };

  const getTicketOfferData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketOfferDataUrl, searchParams)
    );
  };

  const getMyTicketOfferData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(myTicketOfferDataUrl, searchParams)
    );
  };
  const updateTicketData = (ticketId, dataToSend) => {
    return makeRequest(axiosPrivate, ticketDetailsDataUrl(ticketId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getTicketFilteringData = () => {
    return makeRequestWithLanguage(axiosPrivate, ticketFilteringDataUrl);
  };

  const getTicketFilteringMyTicketsData = () => {
    return makeRequestWithLanguage(
      axiosPrivate,
      ticketFilteringMyTicketsDataUrl
    );
  };

  const getTicketEnclosuresFilteringData = () => {
    return makeRequestWithLanguage(
      axiosPrivate,
      ticketFilteringEnclosuresDataUrl
    );
  };

  const getTicketOffersFilteringData = () => {
    return makeRequestWithLanguage(axiosPrivate, ticketFilteringOffersDataUrl);
  };

  const getMyTicketOffersFilteringData = () => {
    return makeRequestWithLanguage(
      axiosPrivate,
      myTicketFilteringOffersDataUrl
    );
  };

  const addTicketsToFavority = (ticket_ids) => {
    return makeRequest(axiosPrivate, ticketAddFavorityUrl, {
      method: "POST",
      data: ticket_ids,
    });
  };

  const deleteTicketsFromFavority = (ticket_ids) => {
    return makeRequest(axiosPrivate, ticketDeleteFavorityUrl, {
      method: "POST",
      data: ticket_ids,
    });
  };

  const getTicketCategoryShortData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketCategoryShortDataUrl, searchParams)
    );
  };

  const getTicketCategoryAdminData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketCategoryAdminDataUrl, searchParams)
    );
  };

  const getTicketCategoryFilteringData = () => {
    return makeRequestWithLanguage(
      axiosPrivate,
      ticketCategoryFilteringDataUrl
    );
  };

  const getTicketCategoryDetailsData = (ticketCategoryId) => {
    return makeRequest(
      axiosPrivate,
      ticketCategoryDetailsDataUrl(ticketCategoryId)
    );
  };

  const addTicketCategory = (dataToSend) => {
    return makeRequest(axiosPrivate, ticketCategoryDataUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateTicketCategory = (dataToSend, ticketCategoryId) => {
    return makeRequest(
      axiosPrivate,
      ticketCategoryDetailsDataUrl(ticketCategoryId),
      {
        method: "PATCH",
        data: dataToSend,
      }
    );
  };

  const getStdTicketShortData = () => {
    return makeRequestWithLanguage(axiosPrivate, stdTicketShortDataUrl);
  };

  const createTicket = async (dataToSend) => {
    const enclosures = dataToSend.enclosures;
    delete dataToSend.enclosures;

    let results = await uploadAttachments(enclosures, {});
    let uploadedData = results["uploadedData"];
    let showWaring = results["showWaring"];

    dataToSend.enclosures = uploadedData;
    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, createTicketUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          if (showWaring) {
            snackbarAlert.openWarningSnackbarAlert(
              t("snackbar_alert.occurred_warning_during_upload_file")
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addFlagsToTickets = (tickets, flags) => {
    return makeRequest(axiosPrivate, ticketAddFlagsUrl, {
      method: "POST",
      data: { tickets, flags },
    });
  };

  const deleteFlagsfromTickets = (tickets, flags) => {
    return makeRequest(axiosPrivate, ticketDeleteFlagsUrl, {
      method: "POST",
      data: { tickets, flags },
    });
  };

  const getTicketPrioritySelectList = () => {
    return makeRequest(axiosPrivate, ticketPrioritySelectListUrl);
  };

  const updateMassTicketData = (dataToSend) => {
    return makeRequest(axiosPrivate, ticketMassActionUrl, {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getRelatedTicketForTicket = (ticketId) => {
    return makeRequest(axiosPrivate, `${ticketTicketUrl}?ticket=${ticketId}`);
  };

  const getTicketSelectListForWithoutSelectedTicket = (ticketId) => {
    return getTicketSelectList({ excluded_ticket: ticketId });
  };

  const getTicketSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketSelectListUrl, searchParams)
    );
  };

  const getNotClosedTicketSelectList = () => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketSelectListUrl, { is_open: true })
    );
  };

  const getTicketTicketKindSelectList = () => {
    return makeRequest(axiosPrivate, ticketTicketTicketKindSelectListUrl);
  };

  const updateRelatedTicketForTicket = (ticketId, dataToSend) => {
    return makeRequest(axiosPrivate, `${ticketTicketUrl}${ticketId}/`, {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const mergeTickets = (dataToSend) => {
    return makeRequest(axiosPrivate, ticketTicketMergeUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const getMyTicketData = (searchParams, isDialogWithParamsOpen = true) => {
    if (isDialogWithParamsOpen) {
      return makeRequestWithLanguage(
        axiosPrivate,
        prepareUrlWithQueryParams(myTicketDataUrl, searchParams)
      );
    }
    return Promise.resolve([]);
  };

  const getTicketDetailsFilteringData = () => {
    return makeRequestWithLanguage(axiosPrivate, ticketFilteringDataForDetails);
  };

  const getTicketAreaSelectList = () => {
    return makeRequest(axiosPrivate, ticketAreaSelectListUrl);
  };

  const getTicketDataForCopyTicket = (ticketId, searchParams) => {
    if (!ticketId) return Promise.resolve(null);
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        ticketDataForCopyTicketUrl(ticketId),
        searchParams
      )
    );
  };

  const getTicketDataForClaimTicket = (ticketId) => {
    if (!ticketId) return Promise.resolve(null);
    return makeRequestWithLanguage(
      axiosPrivate,
      ticketDataForClaimTicketUrl(ticketId)
    );
  };

  const getTicketWithSlaData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketSlaDataUrl, searchParams)
    );
  };

  const getTicketWithSlaShortData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketSlaShortDataUrl, searchParams)
    );
  };

  const getTicketSlaFilteringData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketSlaFilteringDataUrl, searchParams)
    );
  };

  const getTicketsWithSlaDelayTimeDistribution = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(
        ticketSlaDataWithDelayTimeDistributionUrl,
        searchParams
      )
    );
  };

  const getTicketsWithSlaClaimDistribution = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(
        ticketSlaDataWithClaimDistributionUrl,
        searchParams
      )
    );
  };

  const getStdTicketAdminData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(stdTicketAdminDataUrl, searchParams)
    );
  };

  const getStdTicketAdminDetailsData = (stdTicketId, searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(
        stdTicketAdminDetailsDataUrl(stdTicketId),
        searchParams
      )
    );
  };

  const createStdTicket = (dataToSend) => {
    return makeRequest(axiosPrivate, stdTicketAdminDataUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateStdTicket = (dataToSend, stdTicketId) => {
    return makeRequest(
      axiosPrivate,
      stdTicketAdminDetailsDataUrl(stdTicketId),
      {
        method: "PATCH",
        data: dataToSend,
      }
    );
  };

  const getStdTicketAdminFilteringData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(stdTicketAdminFilteringDataUrl, searchParams)
    );
  };

  const getStdTicketAutomatAdminData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(stdTicketAutomatAdminDataUrl, searchParams)
    );
  };

  const getStdTicketAutomatAdminDetailsData = (
    ticketCategoryId,
    searchParams
  ) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(
        stdTicketAutomatAdminDetailsDataUrl(ticketCategoryId),
        searchParams
      )
    );
  };

  const updateStdTicketAutomat = (dataToSend, ticketCategoryId) => {
    return makeRequest(
      axiosPrivate,
      stdTicketAutomatAdminDetailsDataUrl(ticketCategoryId),
      {
        method: "PATCH",
        data: dataToSend,
      }
    );
  };

  const getStdTicketAutomatAdminFilteringData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(
        stdTicketAutomatAdminFilteringDataUrl,
        searchParams
      )
    );
  };

  const getOverridingData = (searchParams, isDialogWithParamsOpen = true) => {
    if (isDialogWithParamsOpen) {
      return makeRequest(
        axiosPrivate,
        prepareUrlWithQueryParams(overridingDataUrl, searchParams)
      );
    }
    return Promise.resolve([]);
  };

  const getOverridingDetailsData = (stdTicketId, searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        overridingDetailsDataUrl(stdTicketId),
        searchParams
      )
    );
  };

  const createOrUpdateOverridingWithEnclosuers = async (
    dataToSend,
    url,
    method
  ) => {
    const preparedDataToSend = { ...dataToSend };
    const tickets = preparedDataToSend.tickets;

    const preparedTickets = [];
    let isUploadFileFailed = false;
    for (const ticket of tickets) {
      const preparedTicket = { ...ticket };
      const enclosures = preparedTicket.enclosures;
      delete preparedTicket.enclosures;

      const results = await uploadAttachments(enclosures, {});
      const uploadedData = results["uploadedData"];
      const showWaring = results["showWaring"];
      if (showWaring) isUploadFileFailed = true;

      preparedTicket.enclosures = uploadedData;

      preparedTickets.push(preparedTicket);
    }

    preparedDataToSend.tickets = preparedTickets;

    return new Promise(async (resolve, reject) => {
      await makeRequestWithLanguage(axiosPrivate, url, {
        method: method,
        data: preparedDataToSend,
      })
        .then((result) => {
          resolve(result);
          if (isUploadFileFailed) {
            snackbarAlert.openWarningSnackbarAlert(
              t("snackbar_alert.occurred_warning_during_upload_file")
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const createOverriding = async (dataToSend) => {
    return createOrUpdateOverridingWithEnclosuers(
      dataToSend,
      overridingDataUrl,
      "POST"
    );
  };

  const updateOverriding = async (dataToSend, overridingId) => {
    return createOrUpdateOverridingWithEnclosuers(
      dataToSend,
      overridingDetailsDataUrl(overridingId),
      "PATCH"
    );
  };

  const getOverridingFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(overridingFilteringDataUrl, searchParams)
    );
  };

  const getTicketsForOverriding = (overridingId) => {
    return makeRequest(axiosPrivate, ticketsForOverridingDataUrl(overridingId));
  };

  const getStdTicketsConfigForHandOver = () => {
    return makeRequestWithLanguage(axiosPrivate, stdTicketConfigForHandOver);
  };

  const getTicketChartFilteringData = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketChartFilteringDataUrl, searchParams)
    );
  };

  const getTicketsFeedbackDistribution = (searchParams) => {
    return makeRequestWithLanguage(
      axiosPrivate,
      prepareUrlWithQueryParams(ticketFeedbackDistributionDataUrl, searchParams)
    );
  };

  const getTicketProtocol = (ticketId) => {
    const axios = axiosPrivate.get(ticketProtocolGenerateUrl(ticketId), {
      responseType: "blob",
    });

    return axios
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        return url;
      })
      .catch((error) => {
        error.response.data
          .text()
          .then((responseTxt) => {
            snackbarAlert.openErrorSnackbarAlert(
              getErrorMsg(
                JSON.parse(responseTxt),
                t("snackbar_alert.occurred_error_during_download_file")
              )
            );
          })
          .catch(() => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_download_file")
            );
          });
      });
  };

  return {
    getTicketData,
    getTicketWithExistEnclosuresData,
    updateTicketData,
    getTicketDetailsData,
    getTicketShortDetailsData,
    getTicketOfferInitData,
    getTicketFilteringData,
    getTicketEnclosuresFilteringData,
    getTicketOffersFilteringData,
    getMyTicketOffersFilteringData,
    getTicketOfferData,
    addTicketsToFavority,
    deleteTicketsFromFavority,
    getTicketCategoryShortData,
    getTicketCategoryDetailsData,
    getTicketCategoryAdminData,
    getTicketCategoryFilteringData,
    updateTicketCategory,
    addTicketCategory,
    getStdTicketShortData,
    createTicket,
    addFlagsToTickets,
    deleteFlagsfromTickets,
    getTicketPrioritySelectList,
    updateMassTicketData,
    getRelatedTicketForTicket,
    getTicketSelectList,
    getTicketTicketKindSelectList,
    updateRelatedTicketForTicket,
    getTicketSelectListForWithoutSelectedTicket,
    mergeTickets,
    getMyTicketData,
    getNotClosedTicketSelectList,
    getTicketDetailsFilteringData,
    getTicketAreaSelectList,
    getTicketDataForCopyTicket,
    getMyTicketOfferData,
    getTicketDataForClaimTicket,
    getTicketFilteringMyTicketsData,
    getTicketWithSlaData,
    getTicketWithSlaShortData,
    getTicketSlaFilteringData,
    getTicketsWithSlaDelayTimeDistribution,
    getTicketsWithSlaClaimDistribution,
    getStdTicketAdminData,
    getStdTicketAdminDetailsData,
    createStdTicket,
    updateStdTicket,
    getStdTicketAdminFilteringData,
    getStdTicketAutomatAdminData,
    getStdTicketAutomatAdminDetailsData,
    updateStdTicketAutomat,
    getStdTicketAutomatAdminFilteringData,
    getTicketsForOverriding,
    getOverridingData,
    getOverridingDetailsData,
    getOverridingFilteringData,
    createOverriding,
    updateOverriding,
    getStdTicketsConfigForHandOver,
    getTicketChartFilteringData,
    getTicketsFeedbackDistribution,
    getTicketProtocol,
  };
}
