import PropTypes from "prop-types";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import TuneIcon from "@mui/icons-material/Tune";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import { overflowButtonStyle } from "../../../helpers/styles";
import SearchButton from "../../button/SearchButton/SearchButton";
import { useSearchParams } from "react-router-dom";
import { isSearchParamsForFilteringEmpty } from "../../../helpers/methods";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export default function AgreementsToolBar(props) {
  const { t } = useTranslation();


  const [searchParams, setSearchParams] = useSearchParams();

  const isFilterSet =
    props.isFilterSet !== undefined
      ? props.isFilterSet
      : !isSearchParamsForFilteringEmpty(searchParams);

  return (
    <Grid
      container
      alignItems="center"
    >
      <Grid item xs={12} md={11}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          columnSpacing={1}
          rowSpacing={2}
          style={props.style}
        >
          {props.hasPermission &&
          <>
          {props.onClickCreateAgreement &&
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={
                  props.selectedAgreementId ?
                  <ModeEditOutlineOutlined  sx={centerVericalAlignIconStyle} />
                  :
                  <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
                }
                onClick={props.onClickCreateAgreement}
                sx={overflowButtonStyle}
              >
               { props.selectedAgreementId ?
                t("bar.agreement_tool_bar.edit_agreement")
                :
                t("bar.agreement_tool_bar.add_agreement")
               }
              </Button>
            </Grid>
          }
            <Grid item xs={12} md={2}>
            {props.onClickCreateGuarantee &&
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={
                  <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
                }
                onClick={props.onClickCreateGuarantee}
                sx={overflowButtonStyle}
              >
                {t("bar.agreement_tool_bar.add_guarantee")}
              </Button>
          }
            </Grid>
            <Grid item xs={12} md={2}>
            {props.onClickCreateDocument &&

              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={
                  <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
                }
                onClick={props.onClickCreateDocument}
                sx={overflowButtonStyle}
              >
                {t("bar.agreement_tool_bar.add_document")}
              </Button>

}
            </Grid>
          </>
}

<Grid item xs={12} md={2}/>

         
          {props.onClickAdjustTable &&
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={<TuneIcon sx={centerVericalAlignIconStyle} />}
                onClick={props.onClickAdjustTable}
                sx={overflowButtonStyle}
              >
                {t("bar.agreement_tool_bar.adjust_table")}
              </Button>
            </Grid>
          }
          {props.onClickMyFilters &&
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={<FilterAltIcon sx={centerVericalAlignIconStyle} />}
                onClick={props.onClickMyFilters}
                sx={overflowButtonStyle}
                color={isFilterSet ? "success" : "primary"}
              >
                {t("bar.tool_bar.my_filters")}
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid item xs={12} md={1}>
        <SearchButton onClickSearch={props.onClickSearch} />
      </Grid>
    </Grid>
  );
}

AgreementsToolBar.propTypes = {
  style: PropTypes.object,
  onClickAdjustTable: PropTypes.func,
  onClickCreateAgreement: PropTypes.func,
  onClickCreateGuarantee: PropTypes.func,
  onClickCreateDocument: PropTypes.func
};

AgreementsToolBar.defaultProps = {

};
