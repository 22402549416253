import { useState, useEffect, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import TableService from "../../services/tableService";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import OfferTable from "../../components/table/OfferTable/OfferTable";
import useOfferService from "../../services/offerService";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import TuneIcon from "@mui/icons-material/Tune";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import AcceptOfferButton from "../../components/button/AcceptOfferButton";
import useTicketService from "../../services/ticketService";
import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import TicketTable from "../../components/table/TicketTable";
import { NAVIGATION_DRAWER_TYPE_TICKETS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import {
  MY_OFFER_TABLE_FIELDS_CONFIG,
  MY_OFFER_TABLE_NAME,
  MY_TICKET_OFFER_TABLE_FIELDS_CONFIG,
  MY_TICKET_OFFER_TABLE_NAME,
  SETTLEMENT_OM_TABLE_FIELDS_CONFIG,
} from "./MyOffersTablesConfig";
import OfferDocumentDialog from "../../components/dialog/OfferDocumentDialog";
import useEnclosuresData from "../../hooks/useEnclosuresData";
import OfferRejectDialog from "../../components/dialog/OfferRejectDialog";
import useSettlementData from "../../hooks/useSettlementData";
import OfferSettlementDialog from "../../components/dialog/OfferSettlementDialog/OfferSettlementDialog";
import useSettlementTableService from "../../services/settlementTableService";
import {
  OFFER_ACCEPED,
  OFFER_REJECTED,
  OFFER_SEND_TO_RR,
} from "../../helpers/constants";
import { overflowButtonStyle } from "../../helpers/styles";
import useAcceptRejectOffers from "../../hooks/useAcceptRejectOffers";
import useDialogWithId from "../../hooks/useDialogWithId";
import OfferHistoryLogDialog from "../../components/dialog/OfferHistoryLogDialog/OfferHistoryLogDialog";

const TABLE_CONFIGS = [
  {
    name: MY_TICKET_OFFER_TABLE_NAME,
    config: MY_TICKET_OFFER_TABLE_FIELDS_CONFIG,
  },
  { name: MY_OFFER_TABLE_NAME, config: MY_OFFER_TABLE_FIELDS_CONFIG },
];

const OFFER_TABLE_FILTER_PREFIX = "offertable";

const TICKET_TABLE_FILTER_PREFIX = "tickettable";

export default function MyOffersAcceptPage(props) {
  const { pageName } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [ticketDataLocaly, setTicketDataLocaly] = useState();
  const [countTicketRecords, setCountTicketRecords] = useState();

  const [offerTableTitle, setOfferTableTitle] = useState(undefined);
  const [offersDataLocaly, setOffersDataLocaly] = useState([]);
  const [countOfferRecords, setCountOfferRecords] = useState();

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  const [chosenOfferDetails, setChosenOfferDetails] = useState(undefined);

  const [selectedView, setSelectedView] = useState("ticket-offers");

  const [ticketTableSearchParams, setTicketTableSearchParams] = useState({});
  const [offerTableSearchParams, setOfferTableSearchParams] = useState({});

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isOfferCR, setIsOfferCR] = useState(true);
  const [totalItemsCost, setTotalItemsCost] = useState([]);

  const [selectedOfferId, setSelectedOfferId] = useState();

  const openedTicketIdRef = useRef();

  const { setNewItemSearchParamsIfAreChanged } =
    useFilterSearchParams(searchParams);

  const [searchParamsFromPage, setSearchParamsFromPage] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(searchParams.entries());
  });

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      TICKET_TABLE_FILTER_PREFIX,
      ticketTableSearchParams,
      setTicketTableSearchParams
    );
    setNewItemSearchParamsIfAreChanged(
      OFFER_TABLE_FILTER_PREFIX,
      offerTableSearchParams,
      setOfferTableSearchParams
    );
  }, [searchParams]);

  const { enclosures, onPreviewEnclosure, onDownloadEnclosure } =
    useEnclosuresData(selectedOfferId, undefined, "offer");

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  
  const [
    openLogDialog,
    historyLogOfferId,
    onOpenLogDialog,
    handleCloseLogDialog,
    exportStatus
  ] = useDialogWithId();

  const [
    openDocumentOfferDialog,
    onOpenDocumentOfferDialog,
    handleCloseDocumentOfferDialog,
  ] = useDialog();

  const {
    page: ticketDataPage,
    pageSize: ticketDataPageSize,
    handleChangePageWithSearchParams: handleChangeTicketDataPage,
    handleChangePageSizeWithSearchParams: handleChangeTicketDataPageSize,
    resetTicketPageNumber,
  } = usePaginationWithSearchParams(TICKET_TABLE_FILTER_PREFIX);

  const {
    page: offerDataPage,
    pageSize: offerDataPageSize,
    handleChangePageWithSearchParams: handleChangeOfferDataPage,
    handleChangePageSizeWithSearchParams: handleChangeOfferDataPageSize,
    resetOfferPageNumber,
  } = usePaginationWithSearchParams(
    selectedView === "ticket-offers" ? OFFER_TABLE_FILTER_PREFIX : undefined
  );

  useEffect(() => {
    if (openedTicketIdRef.current) {
      offersByTicketFn
        .execute(openedTicketIdRef.current, offerTableSearchParams)
        .then((data) => {
          setOffersDataLocaly(data.results);
          setCountOfferRecords(data.count);
        });
    }
  }, [offerTableSearchParams]);

  const {
    getMyOffersList,
    getMyOffersFilteringData,
    getMyOfferDetailsData,
    getMyOfferListByTicket,
    updateMyOfferData,
  } = useOfferService();

  const updateOfferDataFn = useAsyncFn(updateMyOfferData);

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const { getMyTicketOfferData, getMyTicketOffersFilteringData } =
    useTicketService();

  const offersData = useAsync(
    () => getOffersDataBySelectedView(),
    [searchParams, selectedView]
  );

  const ticketData = useAsync(
    () => getTicketDataBySelectedView(),
    [ticketTableSearchParams, selectedView]
  );

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const getOffersDataBySelectedView = () => {
    switch (selectedView) {
      case "all-offers":
        return getMyOffersList(searchParams);

      case "ticket-offers":
        return Promise.resolve([]);
    }
  };

  const getTicketDataBySelectedView = () => {
    switch (selectedView) {
      case "all-offers":
        return Promise.resolve([]);
      case "ticket-offers":
        return getMyTicketOfferData(ticketTableSearchParams);
    }
  };

  const ticketFilteringData = useAsync(getMyTicketOffersFilteringData);

  const offersByTicketFn = useAsyncFn(getMyOfferListByTicket);

  const offerFilteringData = useAsync(getMyOffersFilteringData);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const setTotalDataCostDict = (offer) => {
    let totalCostData = {};
    totalCostData.items_quotation_net = offer.offer_quatation_net;
    totalCostData.items_quotation_vat = offer.offer_quatation_vat;
    totalCostData.items_quotation_gross = offer.offer_quatation_gross;
    return [totalCostData];
  };

  const offersDetailsFn = useAsyncFn(getMyOfferDetailsData);

  const onCloseDocumentDialog = useCallback(() => {
    setSelectedOfferId(undefined);
    handleCloseDocumentOfferDialog();
    refetchDataOnClose();
  }, []);

  const {
    onSendMessageToOffer,
    handleSendAcceptOffer,
    handleSendRejectOffer,
    openSettlementOfferDialog,
    onOpenSettlementOfferDialog,
    openRejectOfferDialog,
    onOpenRejectOfferDialog,
    handleCloseRejectOfferDialog,
    handleCloseSettlementOfferDialog,
    existsChanges,
    offerSettlementData,
    onChangeSettlementTableData,
    offerDetailsLoading,
  } = useAcceptRejectOffers(
    selectedOfferId,
    totalItemsCost,
    offersData.refetch,
    onCloseDocumentDialog,
    isOfferCR
  );

  useEffect(() => {
    if (ticketData.loading) {
      return;
    }
    setTicketDataLocaly(ticketData.value.results);
    setCountTicketRecords(ticketData.value.count);
  }, [ticketData.loading]);

  useEffect(() => {
    if (offersData.loading) {
      return;
    }
    setOffersDataLocaly(offersData.value.results);
    setCountOfferRecords(offersData.value.count);
  }, [offersData.loading]);

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [MY_TICKET_OFFER_TABLE_NAME, MY_OFFER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [MY_TICKET_OFFER_TABLE_NAME, MY_OFFER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const handleChangeTicketRowsPerPage = (e) => {
    handleChangeTicketDataPageSize(e, parseInt(e.target.value, 10));
  };

  const handleChangeOfferRowsPerPage = (e) => {
    handleChangeOfferDataPageSize(e, parseInt(e.target.value, 10));
  };

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        onCloseTableColumnVisibilityDialog();
        if (selectedView === "ticket-offers") {
          ticketData.refetch();
          setTicketDataLocaly(undefined);
        } else {
          setOffersDataLocaly(undefined);
          offersData.refetch();
        }
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const handleClickTicketRow = (ticketId) => {
    let selectedTicket = ticketDataLocaly.find(
      (ticket) => ticket.id === ticketId
    );

    if (!ticketId || ticketId === openedTicketIdRef.current) {
      openedTicketIdRef.current = null;
      setOfferTableTitle(undefined);
      setOffersDataLocaly([]);
      setCountOfferRecords(0);
    } else {
      setSelectedOfferId(undefined);
      openedTicketIdRef.current = ticketId;
      setOfferTableTitle(
        `${t("page.offers_page.offers_for_ticket_nr")} ${
          selectedTicket.ticket_nr
        }`
      );
      offersByTicketFn
        .execute(ticketId, offerTableSearchParams)
        .then((data) => {
          setOffersDataLocaly(data.results);
          setCountOfferRecords(data.count);
        });
    }
  };

  const setDefaultView = () => {
    setSelectedOfferId(undefined);
  };

  const handleClickOfferRow = (offerId) => {
    if (!offerId || offerId === selectedOfferId) {
      setDefaultView();
    } else {
      setSelectedOfferId(offerId);
      offersDetailsFn.execute(offerId).then((data) => {
        setChosenOfferDetails(data);
        setIsReadOnly(!data.is_allowed_to_accept && !data.is_cr_requested);
        setIsOfferCR(data.is_cr_requested)
        setTotalItemsCost(setTotalDataCostDict(data));
        onOpenDocumentOfferDialog();
      });
    }
  };

  const handleClickVisibilityState = (filterKey) => {
    switch (filterKey) {
      case "all-offers":
        setSelectedView("all-offers");
        setSelectedOfferId(undefined);
        setOfferTableTitle(undefined);
        break;

      case "ticket-offers":
        setSelectedOfferId(undefined);
        openedTicketIdRef.current = null;
        setSelectedView("ticket-offers");
        break;
    }
  };

  const filterChoiceButtonOptions = [
    {
      label: t("button.ticket_offer_button.tickets_with_offers"),
      key: "ticket-offers",
      callback: () => handleClickVisibilityState("ticket-offers"),
    },
    {
      label: t("button.ticket_offer_button.all_offers"),
      key: "all-offers",
      callback: () => handleClickVisibilityState("all-offers"),
    },
  ];

  const refetchDataOnClose = () => {
    userPreferencesForPage.refetch();
    onCloseTableColumnVisibilityDialog();
 
      if (openedTicketIdRef.current) {
        offersByTicketFn
          .execute(openedTicketIdRef.current, offerTableSearchParams)
          .then((data) => {
            setOffersDataLocaly(data.results);
            setCountOfferRecords(data.count);
            ticketData.refetch();
            ticketFilteringData.refetch();
            offersByTicketFn
            .execute(openedTicketIdRef.current, offerTableSearchParams)
            .then((data) => {
              setOffersDataLocaly(data.results);
              setCountOfferRecords(data.count);
            });
          });
      } else {
        offersData.refetch();
      }
    
  };

  const isTicketTableLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    ticketFilteringData.loading ||
    ticketDataLocaly === undefined ||
    countTicketRecords === undefined;

  const isOfferTableLoading =
    offersData.loading ||
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    offerFilteringData.loading ||
    offersDataLocaly === undefined ||
    countOfferRecords === undefined;

  const getOfferTable = (prefix = undefined, style = {}) => {
    return (
      <OfferTable
        data={offersDataLocaly ? offersDataLocaly : []}
        showCheckbox={false}
        onClickRow={handleClickOfferRow}
        countRecords={countOfferRecords}
        page={offerDataPage}
        onPageChange={handleChangeOfferDataPage}
        rowsPerPage={offerDataPageSize}
        onRowsPerPageChange={handleChangeOfferRowsPerPage}
        resetPageNumber={resetOfferPageNumber}
        filteringData={offerFilteringData}
        hiddenColumns={
          hiddenColumnsForTables
            ? hiddenColumnsForTables[MY_OFFER_TABLE_NAME]
            : []
        }
        columnsOrders={
          columnsOrdersForTables
            ? columnsOrdersForTables[MY_OFFER_TABLE_NAME]
            : undefined
        }
        selectedOfferId={selectedOfferId}
        showTitle={offerTableTitle ? true : false}
        title={offerTableTitle}
        style={style}
        tableConfig={MY_OFFER_TABLE_FIELDS_CONFIG}
        filterPrefix={prefix}
        showContextMenu={false}
      />
    );
  };

  const getTabbleConentBySelectedView = () => {
    switch (selectedView) {
      case "all-offers":
        return (
          <LoaderWrapper showLoader={isOfferTableLoading}>
            {getOfferTable(undefined, { maxHeight: "80vh" })}

            <Typography
              textAlign="center"
              variant="overline"
              display="block"
              gutterBottom
              style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
            >
              {t("page.offers_om_page.choose_offer_to_show_details")}
            </Typography>
          </LoaderWrapper>
        );

      case "ticket-offers":
        return (
          <LoaderWrapper showLoader={isTicketTableLoading}>
            <TicketTable
              data={ticketDataLocaly}
              onClickRow={handleClickTicketRow}
              countRecords={countTicketRecords}
              page={ticketDataPage}
              onPageChange={handleChangeTicketDataPage}
              rowsPerPage={ticketDataPageSize}
              onRowsPerPageChange={handleChangeTicketRowsPerPage}
              resetPageNumber={resetTicketPageNumber}
              filteringData={ticketFilteringData}
              hiddenColumns={
                hiddenColumnsForTables
                  ? hiddenColumnsForTables[MY_TICKET_OFFER_TABLE_NAME]
                  : []
              }
              columnsOrders={
                columnsOrdersForTables
                  ? columnsOrdersForTables[MY_TICKET_OFFER_TABLE_NAME]
                  : undefined
              }
              selectedTicketId={openedTicketIdRef.current}
              style={{ maxHeight: "50vh" }}
              tableConfig={MY_TICKET_OFFER_TABLE_FIELDS_CONFIG}
              filterPrefix={TICKET_TABLE_FILTER_PREFIX}
              showCleanFilterIcon={true}
              showContextMenu={false}
            />
            {openedTicketIdRef.current ? (
              <LoaderWrapper showLoader={isOfferTableLoading}>
                {getOfferTable(OFFER_TABLE_FILTER_PREFIX, {
                  maxHeight: "50vh",
                })}
              </LoaderWrapper>
            ) : (
              <Typography
                textAlign="center"
                variant="overline"
                display="block"
                gutterBottom
                style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
              >
                {t("page.offers_om_page.choose_ticket_to_show_offers")}
              </Typography>
            )}
          </LoaderWrapper>
        );
    }
  };

  return (
    <NavigationDrawer
      drawerType={NAVIGATION_DRAWER_TYPE_TICKETS}
      pageName={pageName}
    >
      <DefaultPageWrapper titleKey={"my_offers"}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={2} style={{ marginBlock: "5px" }}>
            <Button
              fullWidth
              variant="contained"
              size="small"
              startIcon={<TuneIcon sx={centerVericalAlignIconStyle} />}
              onClick={onOpenTableColumnVisibilityDialog}
              sx={overflowButtonStyle}
            >
              {t("bar.offer_tool_bar.adjust_table")}
            </Button>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={2} style={{ marginBlock: "5px" }}>
            <AcceptOfferButton
              options={filterChoiceButtonOptions}
              selectedView={selectedView}
            />
          </Grid>
          <Grid item xs={12}>
            {getTabbleConentBySelectedView()}
          </Grid>
        </Grid>

        {openTableColumnVisibilityDialog && (
          <TableColumnVisibilityDialog
            open={openTableColumnVisibilityDialog}
            onClose={onCloseTableColumnVisibilityDialog}
            onSubmit={handleUpdateUserPreferences}
            tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
              selectedView === "ticket-offers"
                ? TABLE_CONFIGS
                : [
                    {
                      name: MY_OFFER_TABLE_NAME,
                      config: MY_OFFER_TABLE_FIELDS_CONFIG,
                    },
                  ],
              columnsOrdersForTables
            )}
            tablesHiddenColumns={hiddenColumnsForTables}
            isLoading={userPreferencesForPage.loading}
          />
        )}

        {openDocumentOfferDialog && (
          <OfferDocumentDialog
            open={openDocumentOfferDialog}
            onClose={onCloseDocumentDialog}
            validatedOffer={chosenOfferDetails}
            readOnly={isReadOnly}
            isOfferCR = {isOfferCR}
            enclosures={enclosures}
            isMyOfferPage={true}
            onPreviewEnclosure={onPreviewEnclosure}
            onDownloadEnclosure={onDownloadEnclosure}
            onOpenHistoryLogDialog = {onOpenLogDialog}
            onRejectOfferByOm={isOfferCR ? () =>handleSendRejectOffer({}): onOpenRejectOfferDialog}
            onAcceptOfferByOm={onOpenSettlementOfferDialog}
            loading={updateOfferDataFn.loading || offerDetailsLoading}
          />
        )}
        {openRejectOfferDialog && (
          <OfferRejectDialog
            open={openRejectOfferDialog}
            onClose={handleCloseRejectOfferDialog}
            onSubmit={handleSendRejectOffer}
            loading={offerDetailsLoading}
            isOfferCR = {isOfferCR}
          />
        )}

        {openSettlementOfferDialog && (
          <OfferSettlementDialog
            open={openSettlementOfferDialog}
            onClose={handleCloseSettlementOfferDialog}
            data={offerSettlementData}
            readOnly={isReadOnly}
            showAddButton={false}
            showContextMenu={false}
            onDataChange={onChangeSettlementTableData}
            isTableValid={true}
            isSumValid={true}
            tableConfig={SETTLEMENT_OM_TABLE_FIELDS_CONFIG}
            showConfirmationForm={!isReadOnly}
            onConfirmAcceptOfferByOm={handleSendAcceptOffer}
            onSendMessage={onSendMessageToOffer}
            loading={offerDetailsLoading}
            isOfferCR = {isOfferCR}
          />
        )}
      {openLogDialog &&
        <OfferHistoryLogDialog 
          offerId={historyLogOfferId}
          open={openLogDialog}
          onClose={handleCloseLogDialog}
        />
      }
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
