import { Grid, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import BaseTable from "../../base/BaseTable/BaseTable";
import { AGREEMENT_TABLE_FIELDS_CONFIG } from "../../../page/AgreementsPage/PageTablesConfig";
import TableService from "../../../services/tableService";

export default function RelatedAgreementsBox({ relatedAgreements }) {
    const { t } = useTranslation();

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={6} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        {t(`other.related_agreements_box.related_agreements`)}
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={6} />

                <Grid item xs={12}>
                    <BaseTable
                        headers={TableService.getHeaderLables(
                            AGREEMENT_TABLE_FIELDS_CONFIG 
                        )}
                        rows={TableService.getPreparedDataForBaseTable(
                            AGREEMENT_TABLE_FIELDS_CONFIG,
                            relatedAgreements
                        )}
                    />
                </Grid>
            </Grid>
        </BaseBox>
    );
}

RelatedAgreementsBox.propTypes = {
    relatedAgreements: PropTypes.array,
}


RelatedAgreementsBox.defaultProps = {
    relatedAgreements: [],
}
