import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useEmptyArrayMemo } from "../../../hooks/useEmptyArrayMemo";
import TableService from "../../../services/tableService";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import FilterTable from "../FilterTable";


const TransactionTable= (props) => {
  const { emptyArrayMemo } = useEmptyArrayMemo()

  const [transactionLocal, setTransactionLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();


  useEffect(() => {
    if (props.transactionData.loading) {
      return;
    }
    setTransactionLocal(props.transactionData.value.results);
    setCountRecords(props.transactionData.value.count);
  }, [props.transactionData.loading]);

  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        props.tableConfig,
        transactionLocal,
        emptyArrayMemo,
        emptyArrayMemo,
        props.onCancelReservation
      ),
    [transactionLocal, props.tableConfig]
  );

  const isLoading =
    transactionLocal === undefined ||
    countRecords === undefined;


  if (isLoading) return <LoaderWrapper showLoader={true} />;


  return (

        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={props.page}
          onPageChange={props.handleChangePageWithSearchParams}
          rowsPerPage={props.pageSize}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
          resetPageNumber={props.resetPageNumber}
          filteringData={props.transactionFilteringData}
          hiddenColumns={emptyArrayMemo}
          style={{ maxHeight: "vh" }}
          tableConfig={props.tableConfig}
          showContextMenu={false}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            props.tableConfig,
            props.transactionFilteringData,
            emptyArrayMemo
          )}
        />
  );
};

TransactionTable.propTypes = {
  transactionFilteringData: PropTypes.array,
  tableConfig : PropTypes.array,
  transactionData : PropTypes.object,
  onChangeParams : PropTypes.func
};

TransactionTable.defaultProps = {};

export default TransactionTable;
