import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";

const ShortUserForm= ({
  user,
  index,
  onRemoveUser,
  readOnly,
  subcontractorId }
) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={readOnly ? 12 : 10.8}>
        <TextFieldFormControl
          value={user?.full_name}
          label={t("other.user_form_box.user")}
          readOnly = {readOnly}
        />
      </Grid>
      {!readOnly &&
        <Grid item xs={1}>
          <IconButtonWithTooltip
            onClick={(e) => {
              e.stopPropagation();
              onRemoveUser(subcontractorId ? user.usersubcontractor_id: undefined, index)
            }}
            title={t("other.user_form_box.remove_relation")}
          >
            <RemoveCircleIcon color="secondary" />
          </IconButtonWithTooltip>
        </Grid>
      }
    </>
  );
};

ShortUserForm.propTypes = {
  user: PropTypes.object,
  onUserRemove: PropTypes.func,
  readOnly: PropTypes.bool
};

ShortUserForm.defaultProps = {
  readOnly : false
};

export default ShortUserForm
