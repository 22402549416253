import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
  agreementSubkindsListUrl,
  agreementsDataUrl,
  agreementsDetailsUrl,
  agreementsFilteringDataUrl,
  agreementsNextNumberUrl,
  agreementsSelectListUrl,
  exportAgreementsToFileUrl,
  relatedAgreementsUrl,
} from "../helpers/apiUrls";
import { prepareUrlWithQueryParams } from "../helpers/methods";


export default function useAgreementService() {
  const axiosPrivate = useAxiosPrivate();


  const getAgreements = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(agreementsDataUrl, searchParams)
    );
  };

  const getAgreementsFilteringData = () =>{
    return makeRequest(axiosPrivate, agreementsFilteringDataUrl);
  }

  const getAgreementsSelectList = (searchParams = {}) =>{
    return makeRequest(axiosPrivate, 
      prepareUrlWithQueryParams(agreementsSelectListUrl, searchParams))
  }

  const getAgreementSubkindsSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(agreementSubkindsListUrl, searchParams)
    );
  };

  const getAgreementDetails = (agreementId) => {
    if (agreementId) {
      return makeRequest(axiosPrivate, agreementsDetailsUrl(agreementId));
    }
    return Promise.resolve({});
  };

  const getNextAgreementNumber = () =>{
    return makeRequest(axiosPrivate, agreementsNextNumberUrl);
  }
  const updateAgreementDetails = (agreementId, data) => {
    return makeRequest(axiosPrivate, agreementsDetailsUrl(agreementId), {
      method: "PATCH",
      data: data,
    });
  };

  const createAgreement = (data) => {
    return makeRequest(axiosPrivate, agreementsDataUrl, {
      method: "POST",
      data: data,
    });
  };

  const getRelatedAgreements = (agreementId) => {
    if (agreementId) {
      return makeRequest(axiosPrivate, relatedAgreementsUrl(agreementId));
    }
    return Promise.resolve([]);
  };


  return {
    getAgreements,
    getAgreementsFilteringData,
    getAgreementSubkindsSelectList,
    getAgreementDetails,
    getNextAgreementNumber,
    updateAgreementDetails,
    createAgreement,
    getRelatedAgreements,
    getAgreementsSelectList
  };
}
