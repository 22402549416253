import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import InfoIcon from "@mui/icons-material/Info";
import FileTableCell from "../../components/other/FileTableCell/FileTableCell";

export const PO_INVOICE_TABLE_FIELDS_CONFIG = [
  // {
  //   name: "preview",
  //   getValueCallback: (rowData, onClickEdit) => {
  //     return (
  //       <InfoIcon  onClick={(e) => onClickEdit(e, rowData.id)} />
  //     )
  //   },
  //   label: i18n.t("table.po_invoices_table.preview"),
  //   filterType: null,
  //   allowChangeVisibility: false,
  //   getFilterFieldConfigCallback: (filteringData) => undefined,
  // },
  {
    name: "fv_nr",
    getValueCallback: (rowData) => rowData.fv_nr,
    label: i18n.t("table.po_invoices_table.fv_number"),
    filterType: TEXT_FIELD_TYPE,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "fv_date",
    getValueCallback: (rowData) => rowData.fv_date,
    label: i18n.t("table.po_invoices_table.fv_date"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "fv_date_sales",
    getValueCallback: (rowData) => rowData.fv_date_sales,
    label: i18n.t("table.po_invoices_table.fv_date_sales"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "fv_issuer",
    getValueCallback: (rowData) => rowData.fv_issuer,
    label: i18n.t("table.po_invoices_table.fv_issuer"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.fv_issuer,
      isObjectOption: true,
      optionLabelKey: "subcontractor_short_name",
      addNewValue: false,
      multiple: true
    }),
  },
  {
    name: "fv_payer",
    getValueCallback: (rowData) => rowData.fv_payer,
    label: i18n.t("table.po_invoices_table.fv_payer"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.fv_payer,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true
    }),
  },
  {
    name: "fv_net",
    getValueCallback: (rowData) => rowData.fv_net,
    label: i18n.t("table.po_invoices_table.fv_net"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "fv_vat",
    getValueCallback: (rowData) => rowData.fv_vat,
    label: i18n.t("table.po_invoices_table.fv_vat"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "fv_gross",
    getValueCallback: (rowData) => rowData.fv_gross,
    label: i18n.t("table.po_invoices_table.fv_gross"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "fv_desc",
    getValueCallback: (rowData) => rowData.fv_desc,
    label: i18n.t("table.po_invoices_table.fv_desc"),
    filterType: TEXT_FIELD_TYPE,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "fv_blueDew",
    getValueCallback: (rowData) => rowData.fv_blueDew,
    label: i18n.t("table.po_invoices_table.fv_blueDew"),
    filterType: TEXT_FIELD_TYPE,
    allowChangeVisibility: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "po_list",
    getValueCallback: (rowData, onClickEdit,  onClickShowPoList) => {
      return (
        <InfoIcon onClick={(e) => onClickShowPoList(e, rowData.id)} />
      );
    },
    label: i18n.t("table.po_invoices_table.po_list"),
    filterType: null,
  },
  {
    name: "fv_date_payment",
    getValueCallback: (rowData) => rowData.fv_date_payment,
    label: i18n.t("table.po_invoices_table.fv_date_payment"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "fv_doc",
    getValueCallback: (rowData, onClickEdit,  onClickShowPoList, onPreviewDoc, onDownloadDoc) => {
      const content = (
        <FileTableCell
        fileType={'docs'} 
        file={rowData.fv_doc} 
        onPreview={onPreviewDoc}
        onDownload = {onDownloadDoc}
        readOnly={true}/>
      );
      return rowData.fv_doc
        ? content
        : "";
    },
    label: i18n.t("table.po_invoices_table.fv_doc"),
    filterType: null,
    allowChangeVisibility: false,
  },
];
