import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import { useCallback, useState } from "react";
import { INTERNAL_TYPE, MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES, MAX_FILENAME_LENGTH_CHARS } from "../../../helpers/constants";
import useFileService from "../../../services/fileService";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm";
import { isEmptyValue } from "../../../helpers/methods";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DocsDataForm from "../../form/DocsDataForm/DocsDataForm";
import LoadingButton from "@mui/lab/LoadingButton";
import AddFileForm from "../../form/AddFileForm/AddFileForm";

function AddDocsDialog(props) {

    const { t } = useTranslation();

    const snackbarAlert = useSnackbarAlert();

    const {
        formValue,
        setFormValue,
        onChange,
        onChangeAutocompleteFieldWithObjectOptions,
    } = useForm();

    const [selectedFiles, setSelectedFiles] = useState([])

    const { showFileByBlobUrl } = useFileService();


    const setNotAddedNames = (namesArr) => {
        let formattedNames = ''
        for (let name of namesArr) {
            formattedNames += '\n' + name
        }
        return t("snackbar_alert.max_allowed_file_size_or_name_is_exceeded_files_are_not_added") + formattedNames
    }


    const onChooseFile = (e) => {
        let selectedFiles = e.target.files;
        let enclosures = []
        let notAddedEnclosuresNames = []
        if (selectedFiles) {
            for (let file of selectedFiles) {
                let enclosure = { file: file, docs_name: file.name, content_type : file.type }

                if (file.size < MAX_ALLOWED_ENCLOSURE_SIZE_IN_BYTES && file.name.length < MAX_FILENAME_LENGTH_CHARS) {
                    enclosure.blob_url = window.URL.createObjectURL(new Blob([enclosure.file], { type: enclosure.file.type }));
                    enclosures.push(enclosure)
                } else {
                    notAddedEnclosuresNames.push(file.name)
                }
            }
            setSelectedFiles((enclosuresTemp) => {
                if (props.multiple){
                    enclosuresTemp = [...enclosuresTemp, ...enclosures]
                }else{
                    enclosuresTemp = [...enclosures]
                }
                return enclosuresTemp
            })
            if (notAddedEnclosuresNames.length > 0) {
                snackbarAlert.openWarningSnackbarAlert(
                    setNotAddedNames(notAddedEnclosuresNames)
                );
            }
        }
    }
    const onPreviewNewEnclosure = useCallback((enclosuerId, index) => {
        if (enclosuerId) {
            props.onPreview(enclosuerId)
        } else {
            let enclosure = selectedFiles[index]
            showFileByBlobUrl(enclosure.blob_url)
        }
    }, [selectedFiles])


    const onDeleteSelectedEnclosure = (index) => {
        setSelectedFiles((enclosuresTemp) => {
            enclosuresTemp = [...enclosuresTemp]
            enclosuresTemp.splice(index, 1)
            return enclosuresTemp
        })
    }

    const onSubmitButtonAction = useCallback(() => {
        props.onSubmit(selectedFiles, formValue)
        setFormValue({})
        setSelectedFiles([])
    }, [selectedFiles, formValue])

    const validated = selectedFiles.length > 0 && !isEmptyValue(formValue?.docs_area) && !isEmptyValue(formValue?.docs_owner)

    return (
        <>
            <BasicDialog
                open={props.open}
                onClose={() => {
                    props.onClose()
                    setFormValue({})
                }}
                titleAlign="center"
                contentAlign="center"
                title={t("dialog.add_new_docs_dialog.add_docs")}
                maxWidth="sm"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={1}
                >
                    <Grid item xs={12}>
                        <AddFileForm
                            docs={selectedFiles}
                            onChooseFile={onChooseFile}
                            onDeleteEnclosure={onDeleteSelectedEnclosure}
                            onPreview={onPreviewNewEnclosure}
                            fileType={props.fileType}
                            multiple={props.multiple}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DocsDataForm
                            formValue={formValue}
                            onChange={onChange}
                            onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            disabled={!validated}
                            variant="contained"
                            onClick={onSubmitButtonAction}
                            component="label"
                            loading = {props.docsLoading}
                            fullWidth
                            startIcon={<FileUploadIcon />}
                        >
                            {props.submitButtonLabel ? props.submitButtonLabel : t("form.add_docs_form.save_file")}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </BasicDialog>
        </>
    )

}

AddDocsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    defaultEnclosureType: PropTypes.string,
    submitButtonLabel: PropTypes.string,
    showExtendedAddEnclosureDialog: PropTypes.bool,
    onDownload: PropTypes.func,
    onPreview: PropTypes.func,
    docsLoading  : PropTypes.bool,
    multiple : PropTypes.bool
};
AddDocsDialog.defaultProps = {
    open: false,
    defaultEnclosureType: INTERNAL_TYPE,
    showExtendedAddEnclosureDialog: false,
    docsLoading : false
};

export default AddDocsDialog