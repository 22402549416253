import * as React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import BasicPaginationWithRowsPerPage from "../../base/BasicPaginationWithRowsPerPage";
import { StyledTableHead } from "../../base/BaseTable/BaseTable";
import { Typography } from "@mui/material";
import NoRecordsTableFooter from "../../footer/NoRecordsTableFooter";
import EditableTableHeader from "./EditableTableHeader";
import EditableTableDataRow from "./EditableTableDataRow";
import EditableTableSummaryRow from "./EditableTableSummaryRow";
import useOfferTableService from "../../../services/offerTableService";
import { convertBoolenValueToZero } from "../../../helpers/methods";

const EditableTable = React.memo((props) => {


  const {
    getNumberOfNotDeletedRows
} = useOfferTableService();

  const getDataRowsNumber = React.useCallback(()=>{
    if (props.countDeletedRows){
      return getNumberOfNotDeletedRows(props.data)
    }
    return props.data.length
  },[props.data, props.countDeletedRows])

  const getRowDataReadOnly = React.useCallback((editable)=>{
    
    return props.readOnly || !editable
  },[props.readOnly])

 
  return (
    <>
      <TableContainer component={Paper} style={props.style} sx={{ width: "100%" }}>
        {props.showTitle && props.title &&
          <Typography variant="h6" color="primary" >{props.title}</Typography>
        }
        <Table sx={{ width: "100%" }}>
          <StyledTableHead>
            <EditableTableHeader
              showCheckbox={props.showCheckbox}
              checkedAll={props.checkedAll}
              onClickCheckAll={props.onClickCheckAll}
              headersConfig={props.tableConfig}
              countDataRows = {props.countDataRows}
            />
          </StyledTableHead>
          <TableBody>
            {props.data.map((rowData, index) => {
              if (rowData.isDeleted !== true) {

                return (
                  <EditableTableDataRow
                    rowIndex={index}
                    showCheckbox={props.showCheckbox}
                    data={rowData.data}
                    id={rowData.id}
                    selected = {rowData.selected}
                    onClickCheck={props.onClickCheck}
                    onChange={props.onDataChange}
                    onChangeAutocomplete={props.onChangeAutocomplete}
                    readOnly={getRowDataReadOnly(rowData.editable)}
                    showContextMenu={props.showContextMenu}
                    contextMenuActions={props.contextMenuActions}
                    onClickAddItemToAutocompletedField={props.onClickAddItemToAutocompletedField}
                    allowClickCheckbox = {props.allowClickCheckbox}
                  />)
              }
              else {
                return null
              }
            })}
            {props.summaryDataRow && getDataRowsNumber() > 0 &&
              <EditableTableSummaryRow
                showCheckbox={props.showCheckbox}
                readOnly={props.readOnly}
                data={props.summaryDataRow[0].data}
                fieldConfig={props.fieldConfig}
              />}
          </TableBody>

          {getDataRowsNumber() === 0 &&
            <NoRecordsTableFooter
              colspan={convertBoolenValueToZero(props.showCheckbox) +convertBoolenValueToZero(props.countDataRows) + props.tableConfig.length}
            />}

        </Table>
      </TableContainer>

    </>
  );
});
export default EditableTable;

EditableTable.propTypes = {
  showCheckbox: PropTypes.bool,
  showPagination: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onClickCheckAll: PropTypes.func,
  onClickCheck: PropTypes.func,
  onClickRow: PropTypes.func,
  data: PropTypes.array,
  headersConfig: PropTypes.object,
  filteringData: PropTypes.object,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetPageNumber: PropTypes.func,
  CollapsibleElement: PropTypes.element,
  selectedItemId: PropTypes.string,
  style: PropTypes.object,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  filterPrefix: PropTypes.string,
  showCleanFilterIcon: PropTypes.bool,
  onDataChange: PropTypes.func,
  readOnly: PropTypes.bool,
  onChangeAutocomplete: PropTypes.func,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.arrayOf(PropTypes.object),
  onClickAddItemToAutocompletedField: PropTypes.func,
  summaryDataRow: PropTypes.obj,
  countDeletedRows : PropTypes.bool,
  countDataRows : PropTypes.bool,
  allowClickCheckbox : PropTypes.bool
};

EditableTable.defaultProps = {
  showCheckbox: true,
  showTitle: false,
  title: '',
  showCleanFilterIcon: false,
  showPagination: true,
  readOnly: true,
  showContextMenu: false,
  contextMenuActions: [],
  countDeletedRows : false,
  countDataRows : false,
  allowClickCheckbox :false
};
