import { Grid } from "@mui/material";

const PrefixSuffixFieldTableWrapper = (props) => {
  return (
    <Grid display={"flex"} alignItems="center" justifyContent={"center"}>
      {props.prefix}
      {props.children}
      {props.suffix}
    </Grid>
  );
};

PrefixSuffixFieldTableWrapper.propTypes = {};

PrefixSuffixFieldTableWrapper.defaultProps = {};

export default PrefixSuffixFieldTableWrapper;
