import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { pl } from "date-fns/locale";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

export default function DateTimeField(props) {
  const { value, onChange, disabled, name, required } = props;
  return (
    <FormControl fullWidth={props.fullWidth} variant={props.variant}>
      <LocalizationProvider adapterLocale={pl} dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label={props.label.charAt(0).toUpperCase() + props.label.slice(1)}
          ampm={false}
          disabled={disabled}
          minutesStep={15}
          value={value || null}
          readOnly={props.readOnly}
          open={props.open}
          onClose={() =>
            props.onClose &&
            props.onClose({ target: { value: value, name: name } })
          }
          onChange={(newValue) =>
            onChange({ target: { value: newValue, name: name } })
          }
          renderInput={(params) => (
            <div style={{ position: "relative" }}>
              <TextField
                {...params}
                variant={props.variant}
                size="small"
                style={props.style}
                fullWidth
                required={required}
                onKeyDown={(e) => e.preventDefault()}
                onClick={props.onClickTextInput}
                error={props.isInvalid}
                sx={{ ...props.textFieldSx }}
              />
              {value && props.showClearIcon && (
                <IconButton
                  style={{
                    position: "absolute",
                    top: props.variant === "standard" ? ".5rem" : "0rem",
                    margin: "auto",
                    right: "2rem",
                  }}
                  onClick={(e) => {
                    e.target.value = "";
                    e.target.name = name;
                    onChange(e);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </div>
          )}
        />
      </LocalizationProvider>
      {props.helperText.length > 0 ? (
        <FormHelperText
          id="component-helper-text"
          error={props.isInvalid}
          style={props.helperTextStyle}
        >
          {props.helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

DateTimeField.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  textFieldSx: PropTypes.object,
  onClickTextInput: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.string,
  showClearIcon: PropTypes.bool,
  isInvalid: PropTypes.bool,
  helperText: PropTypes.string,
  helperTextStyle: PropTypes.object,
  fullWidth: PropTypes.bool,
};

DateTimeField.defaultProps = {
  readOnly: false,
  open: null,
  onClose: null,
  variant: "outlined",
  label: "",
  showClearIcon: true,
  fullWidth: true,
  isInvalid: false,
  helperText: "",
};
