import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import DateTimeField from "../../../field/DateTimeField";

import FieldWithLabel from "../../../field/FieldWithLabel";
import i18n from "../../../../i18n";

const TaskDateTimeForm = (props) => {
  const [formValueLocaly, setFormValueLocaly] = useState(props.formValue);
  const onChangeFormValueLocaly = (e) => {
    setFormValueLocaly((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.task.task_date_startT_plan")}
          field={
            <DateTimeField
              name="task_date_startT_plan"
              value={formValueLocaly.task_date_startT_plan}
              showClearIcon={false}
              readOnly={props.readOnly}
              onChange={onChangeFormValueLocaly}
              onClose={props.onChange}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.task.task_date_stopT_plan")}
          field={
            <DateTimeField
              name="task_date_stopT_plan"
              value={formValueLocaly.task_date_stopT_plan}
              showClearIcon={false}
              readOnly={props.readOnly}
              onChange={onChangeFormValueLocaly}
              onClose={props.onChange}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.task.task_date_startT_real")}
          field={
            <DateTimeField
              name="task_date_startT_real"
              value={formValueLocaly.task_date_startT_real}
              showClearIcon={false}
              readOnly={props.readOnly}
              onChange={onChangeFormValueLocaly}
              onClose={props.onChange}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.task.task_date_stopT_real")}
          field={
            <DateTimeField
              name="task_date_stopT_real"
              value={formValueLocaly.task_date_stopT_real}
              showClearIcon={false}
              readOnly={props.readOnly}
              onChange={onChangeFormValueLocaly}
              onClose={props.onChange}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

TaskDateTimeForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

TaskDateTimeForm.defaultProps = {
  readOnly: false,
};

export default TaskDateTimeForm;
