import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import useTicketService from "../../../services/ticketService";
import { useAsync } from "../../../hooks/useAsync";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import AutocompleteField from "../../field/AutocompleteField";
import useDocsService from "../../../services/docsService";

export default function DocsDataForm(props) {
  const { t } = useTranslation();

  const { getDocsFilteringData } = useDocsService();
  const docsFilteringData = useAsync(getDocsFilteringData)

  const { getTicketAreaSelectList } = useTicketService();
  const ticketAreaSelectList = useAsync(getTicketAreaSelectList)

  const showLoading = () =>
    ticketAreaSelectList.loading || docsFilteringData.loading

  if (showLoading()) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {props.editMode &&
        <Grid item xs={12}>
          <TextFieldFormControl
            name="docs_name"
            label={t("form.docs_data_form.docs_name")}
            value={props.formValue.docs_name}
            disabled
          />
        </Grid>}
      <Grid item xs={6}>
        <AutocompleteField
          name="docs_area"
          label={t(`form.docs_data_form.area`)}
          value={props.formValue.docs_area}
          options={ticketAreaSelectList.value}
          isObjectOption={true}
          optionLabelKey={"ta_area"}
          addNewValue={false}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          required
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteField
          name="docs_security"
          label={t(`form.docs_data_form.docs_security`)}
          value={props.formValue.docs_security}
          options={docsFilteringData?.value?.security_choices}
          isObjectOption={false}
          addNewValue={false}
          onChange={props.onChange}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteField
          label={t("form.docs_data_form.docs_owner")}
          name="docs_owner"
          value={props.formValue.docs_owner}
          options={docsFilteringData?.value?.docs_owner}
          isObjectOption={true}
          optionLabelKey={"full_name"}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          readOnly={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="docs_note"
          label={t("form.docs_data_form.docs_note")}
          value={props.formValue.docs_note}
          multiline={true}
          rows={4}
          onChange={props.onChange}
          disabled={props.readOnly}
        />
      </Grid>
    </Grid>
  );
}

DocsDataForm.propTypes = {
  onChange: PropTypes.func,
  formValue: PropTypes.object,
  readOnly: PropTypes.bool,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  editMode: PropTypes.bool
};

DocsDataForm.defaultProps = {
  formValue: {},
  readOnly: false,
  editMode: false
};
