import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import useUserService from "../../../services/userService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import AutocompleteField from "../../field/AutocompleteField";
import { useCallback, useEffect } from "react";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { getTranslatedPermissionList } from "../../../helpers/methods";

const PermissionDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const setInitForm = () =>{
    if (props.dialogType === "edit"){
      return { 'user_id': props.userId }
    } else if (props.dialogType === "copy"){
      return {'permissions' : props.permissions}
    }
    return {}
  }
  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm(setInitForm());


  const { getOtherWarehousePermissionList,
    getNonWarehousePermissionList,
    updateUserPermission
  } = useUserService();

  const updateUserWarehousePermissionFn = useAsyncFn(updateUserPermission)

  const usersWarehousePermissions = useAsync(
    () => {
      if (formValue?.user_id) {
        return getPermissionList()
      }
      return Promise.resolve({})
    },
    [formValue?.user_id]
  );

  const getPermissionList = () => {
    if (props.permissionType === "warehouse" && formValue?.user_id && props.dialogType !== "copy") {
      return getOtherWarehousePermissionList({ 'user': formValue?.user_id })
    } else if (props.permissionType === "other" && formValue?.user_id && props.dialogType !== "copy") {
      return getNonWarehousePermissionList({ 'user': formValue?.user_id })
    }
    return Promise.resolve({})
  }

  useEffect(() => {
    if (usersWarehousePermissions.loading) {
      return;
    }
    if (usersWarehousePermissions.value.length > 0) {
      setFormValue(usersWarehousePermissions.value[0]);

    } else  if (props.dialogType !== "copy"){
      setFormValue((prev) => ({ ...prev, 'permissions': [] }))
    }
  }, [usersWarehousePermissions.loading]);

  const onSubmit = useCallback(() => {
    updateUserWarehousePermissionFn
      .execute(formValue, {'permission_type' : props.permissionType})
      .then((res) => {
        props.onRefetchData()
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.permissions_updated")
        );
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_permissions_updating")
        );
      });
  }, [formValue]);

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t("dialog.permission_dialog.permission_management")}
      maxWidth="sm"
      rowGap={2}
      showDialogActions
    >
      <LoaderWrapper showLoader={usersWarehousePermissions.loading}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          rowGap={1}
        >
          <Grid item xs={12}>
            <AutocompleteField
              name={"user_id"}
              value={formValue?.user_id}
              label={t("dialog.permission_dialog.user")}
              options={props.usersList}
              isObjectOption={true}
              optionLabelKey={"full_name"}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
              disabled={props.dialogType === "edit"}
            />
          </Grid>

          <Grid item xs={12}>
            <AutocompleteField
              name={"permissions"}
              value={formValue?.permissions}
              label={t("dialog.permission_dialog.permissions")}
              options={getTranslatedPermissionList(props.permissionKinds)}
              isObjectOption={true}
              optionLabelKey={"name"}
              multiple={true}
              addNewValue={false}
              onChange={onChangeAutocompleteFieldWithObjectOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={updateUserWarehousePermissionFn.loading}
              disabled={formValue.user_id === undefined}
              onClick={onSubmit}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </LoaderWrapper>
    </BasicDialog>
  );
};

PermissionDialog.propTypes = {
  userId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  usersList: PropTypes.array,
  permissionKinds: PropTypes.array,
  permissionType: PropTypes.string,
  dialogType : PropTypes.oneOf([undefined, "edit", "copy"])
};

PermissionDialog.defaultProps = {
  open: false,
  usersList: [],
  permissionKinds: [],
};

export default PermissionDialog;
