import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import PostDetailsForm from "../../UniversalDetailsDrawerForms/PostDetailsForm";
import ActionIconsForm from "../../UniversalDetailsDrawerForms/ActionIconsForm";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../context/auth";
import hasUserPermission, {
  IS_KT,
} from "../../../../../helpers/userPermissions";
import LoaderWrapper from "../../../../wrapper/LoaderWrapper";

const PostDrawerContent = (props) => {

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  if (!props.itemData) {
    return <LoaderWrapper isLoading={true} />
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingX={1}
      rowGap={1}
      marginTop={1}
      marginBottom={3}
    >
      
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <PostDetailsForm
          itemData={props.itemData}
          autocompleteData={props.autocompleteData}
          readOnly={props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
     
     
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ActionIconsForm
          itemId={props.itemData.id}
          itemData={props.itemData}
          isLoading={isLoading}
          enclosuresCount={props.itemData.docs_count}
          availableActionButtons={props.availableActionButtons}
          itemType={props.itemType}
          onRefetchData={props.onRefetchData}
          autocompleteData={props.autocompleteData}
          setOpenDrawer={props.setOpenDrawer}
          backdropColor={props.backdropColor}
        />
      </Grid>
    </Grid>
  );
};

PostDrawerContent.propTypes = {
  itemData: PropTypes.object,
  itemType: PropTypes.oneOf(["task", "order", "post"]),
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  availableActionButtons: PropTypes.array,
  onRefetchTableData: PropTypes.func,
};

PostDrawerContent.defaultProps = {
  itemType: "post",
  isLoading: false,
  readOnly: false,
};

export default PostDrawerContent;
