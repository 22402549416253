import { useState, useEffect, useCallback, useRef, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import {
  TICKET_TABLE_NAME,
  TICKET_TABLE_FIELDS_CONFIG,
} from "./PageTablesConfig";

import TicketTable from "../../components/table/TicketTable";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import UniversalToolBar from "../../components/bar/UniversalToolBar";

import useTicketService from "../../services/ticketService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";

import useDialog from "../../hooks/useDialog";
import FilterDialog from "../../components/base/FilterDialog";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";

import UniversalFilterForm from "../../components/form/UniversalFilterForm";

import TableService from "../../services/tableService";

import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer";

import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";

import useBasicDrawer from "../../hooks/useBasicDrawer";
import CreateTicketDialog from "../../components/dialog/CreateTicketDialog";
import { isSearchParamsEmpty } from "../../helpers/methods";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import useTicketOrderDetailsDrawer from "../../hooks/useTicketOrderDetailsDrawer";
import usePermissionService from "../../services/permissionService";
import { exportMyTicketsToFileUrl } from "../../helpers/apiUrls";

const TABLE_CONFIGS = [
  { name: TICKET_TABLE_NAME, config: TICKET_TABLE_FIELDS_CONFIG },
];

export default function MyTicketsPage(props) {
  const { pageName } = props;

  const { showChangesCircle, showAllObjectsAndLevels } = usePermissionService();

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [ticketDataLocaly, setTicketDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const [isDialogWithParamsClosed, setIsDialogWithParamsClosed] =
    useState(true);

  const {
    page: ticketDataPage,
    pageSize: ticketDataPageSize,
    handleChangePageWithSearchParams: handleChangeTicketDataPage,
    handleChangePageSizeWithSearchParams: handleChangeTicketDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const handleChangeRowsPerPage = (e) => {
    handleChangeTicketDataPageSize(e, parseInt(e.target.value, 10));
  };

  const {
    getMyTicketData,
    updateTicketData,
    getTicketDetailsData,
    getTicketFilteringMyTicketsData,
    addTicketsToFavority,
    deleteTicketsFromFavority,
  } = useTicketService();

  const ticketData = useAsync(
    () => getMyTicketData(searchParams, isDialogWithParamsClosed),
    [searchParams]
  );

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
    getColumnOrdersFormValueFromBackend,
  } = useUserPreferencesService();

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const { handleToSetShowChangesUpdated } = useTicketOrderDetailsDrawer(
    ticketData,
    setTicketDataLocaly
  );

  const fullRefreshTable = () => {
    setTicketDataLocaly(undefined);
    ticketData.refetch();
  };

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }

    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [TICKET_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [TICKET_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        fullRefreshTable();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const ticketFilteringData = useAsync(getTicketFilteringMyTicketsData);

  useEffect(() => {
    if (ticketData.loading) {
      return;
    }
    setTicketDataLocaly(ticketData.value.results);
    setCountRecords(ticketData.value.count);
  }, [ticketData.loading]);

  const handleClickCheck = useCallback((id) => {
    setTicketDataLocaly((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      selectedRow.selected = !selectedRow.selected;
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, []);

  const [checkedAll, setCheckedAll] = useState(false);
  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setTicketDataLocaly((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );

  const getSeletedTicketIds = () =>
    ticketDataLocaly
      .filter((ticket) => ticket.selected)
      .map((ticket) => ticket.id);

  const addTicketsToFavorityFn = useAsyncFn(addTicketsToFavority);
  const handleAddTicketForUserFavority = () => {
    let ticketIds = getSeletedTicketIds();
    if (ticketIds.length === 0) return;

    addTicketsToFavorityFn
      .execute(ticketIds)
      .then((res) => {
        setTicketDataLocaly(undefined);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.ticket_to_favority_added")
        );
        ticketData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_adding_ticket_to_favority")
        );
      });
  };

  const deleteTicketsToFavorityFn = useAsyncFn(deleteTicketsFromFavority);
  const handleDeleteTicketsFromFavority = () => {
    let ticketIds = getSeletedTicketIds();
    if (ticketIds.length === 0) return;
    deleteTicketsToFavorityFn
      .execute(ticketIds)
      .then((res) => {
        setTicketDataLocaly(undefined);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.ticket_from_favority_deleted")
        );
        ticketData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_deleting_ticket_from_favority"
          )
        );
      });
  };

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [
    openCreateTicketDialog,
    onOpenCreateTicketDialog,
    onCloseCreateTicketDialog,
  ] = useDialog();

  const onCleanFlitersInFilterDialog = () => {
    setTicketDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const isLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    ticketFilteringData.loading ||
    ticketDataLocaly === undefined ||
    countRecords === undefined;

  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);
  const openedTicketIdRef = useRef();
  const opendedTicketDetailsDataRef = useRef();
  const getTicketDetailsDataFn = useAsyncFn(getTicketDetailsData);

  const handleOpenDetailsDrawer = useCallback(
    (ticketId) => {
      if (
        openDetailsDrawer &&
        (!ticketId || ticketId === opendedTicketDetailsDataRef.current?.id)
      ) {
        setOpenDetailsDrawer(false);
        openedTicketIdRef.current = null;
        opendedTicketDetailsDataRef.current = null;
      } else {
        setOpenDetailsDrawer(true);
        openedTicketIdRef.current = ticketId;
        executeGetTicketDetailsDataFn(ticketId);
      }
    },
    [openDetailsDrawer, openedTicketIdRef.current]
  );

  const executeGetTicketDetailsDataFn = (ticketId) => {
    getTicketDetailsDataFn
      .execute(ticketId)
      .then((data) => (opendedTicketDetailsDataRef.current = data));
  };

  const updateTicketDataFn = useAsyncFn(updateTicketData);

  const onUpdateTicketDataPromise = (ticketId, dataToSend) => {
    return updateTicketDataFn
      .execute(ticketId, dataToSend)
      .then((data) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.ticket_data_updated")
        );
        ticketData.refetch();
        return Promise.resolve(data);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_updating_ticket_data")
        );
        return Promise.reject(error);
      });
  };
  const onCloseCreateTicketDialogLocal = (refreshList = false) => {
    if (refreshList) {
      if (isSearchParamsEmpty(searchParams)) {
        ticketData.refetch();
      } else {
        setSearchParams({});
      }
      ticketFilteringData.refetch();
    }
    onCloseCreateTicketDialog();
  };

  const rowClickDetails = (ticketId) => {
    handleOpenDetailsDrawer(ticketId);
    handleToSetShowChangesUpdated(ticketId);
  };

  const massActionButtonOptions = [
    {
      label: t("page.ticket_page.add_ticket_to_favority"),
      callback: handleAddTicketForUserFavority,
    },
    {
      label: t("page.ticket_page.delete_ticket_from_favority"),
      callback: handleDeleteTicketsFromFavority,
    },
  ];

  const ticketExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      TICKET_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[TICKET_TABLE_NAME],
      columnsOrdersForTables?.[TICKET_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[TICKET_TABLE_NAME],
    columnsOrdersForTables?.[TICKET_TABLE_NAME],
    TICKET_TABLE_FIELDS_CONFIG,
  ]);

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={handleOpenDetailsDrawer}
      itemData={opendedTicketDetailsDataRef.current}
      filteringData={ticketFilteringData?.value}
      itemType={"ticket"}
      isLoading={
        getTicketDetailsDataFn.loading || !opendedTicketDetailsDataRef.current
      }
      onOpenDialogParamsAction={setIsDialogWithParamsClosed}
      onUpdateDataPromise={onUpdateTicketDataPromise}
      onRefetchData={() =>
        executeGetTicketDetailsDataFn(opendedTicketDetailsDataRef.current?.id)
      }
      onRefetchTableData={fullRefreshTable}
      availableActionButtons={[
        "enclosures",
        "copy_ticket",
        "claim_ticket",
        "my_offers",
        "questionnaire",
      ]}
      readOnly={true}
      maxAllowedLevelNumber={showAllObjectsAndLevels ? 5 : 4}
    >
      <NavigationDrawer pageName={pageName}>
        <DefaultPageWrapper titleKey={"my_tickets"}>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <UniversalToolBar
                style={{ marginBlock: "5px" }}
                massActionButtonOptions={massActionButtonOptions}
                onClickSearch={onOpenFilterDialog}
                onClickMyFilters={onOpenUserFiltersDialog}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                onClickCreateItem={onOpenCreateTicketDialog}
              />
            </Grid>
            <Grid item xs={12}>
              <LoaderWrapper showLoader={isLoading}>
                <TicketTable
                  showDetailsIcon={true}
                  showChangesCircle={showChangesCircle}
                  data={ticketDataLocaly}
                  showCheckbox={true}
                  checkedAll={checkedAll}
                  showCleanFilterIcon={false}
                  onClickRow={rowClickDetails}
                  onClickCheck={handleClickCheck}
                  onClickCheckAll={handleClickCheckAll}
                  countRecords={countRecords}
                  page={ticketDataPage}
                  onPageChange={handleChangeTicketDataPage}
                  rowsPerPage={ticketDataPageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={ticketFilteringData}
                  hiddenColumns={
                    hiddenColumnsForTables
                      ? hiddenColumnsForTables[TICKET_TABLE_NAME]
                      : []
                  }
                  columnsOrders={
                    columnsOrdersForTables
                      ? columnsOrdersForTables[TICKET_TABLE_NAME]
                      : undefined
                  }
                  selectedTicketId={openedTicketIdRef.current}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={TICKET_TABLE_FIELDS_CONFIG}
                  showExportToFileButton={true}
                  exportToFileUrl={exportMyTicketsToFileUrl}
                  exportToFileSearchParams={searchParams}
                  exportToFileHeaders={ticketExportHeaders}
                  exportToFileFileName={`${t(
                    "page.ticket_page.my_tickets"
                  ).replace(" ", "_")}.xlsx`}
                />
              </LoaderWrapper>
            </Grid>
          </Grid>
          <FilterDialog
            open={openFilterDialog}
            onCleanFilters={onCleanFlitersInFilterDialog}
            onClose={onCloseFilterDialog}
            resetPageNumber={resetPageNumber}
            filterForm={
              <UniversalFilterForm
                filteringData={ticketFilteringData}
                filtersConfig={TICKET_TABLE_FIELDS_CONFIG}
                includeOpenCloseFilter={true}
                includeHasChangesDotFilter={true}
              />
            }
          />
          {openUserFiltersDialog && (
            <UserFilterDialog
              open={openUserFiltersDialog}
              onClose={onCloseUserFiltersDialog}
              pageName={pageName}
              filterForm={
                <UniversalFilterForm
                  filteringData={ticketFilteringData}
                  filtersConfig={TICKET_TABLE_FIELDS_CONFIG}
                  includeOpenCloseFilter={true}
                  includeHasChangesDotFilter={true}
                />
              }
            />
          )}
          {openTableColumnVisibilityDialog && (
            <TableColumnVisibilityDialog
              open={openTableColumnVisibilityDialog}
              onClose={onCloseTableColumnVisibilityDialog}
              onSubmit={handleUpdateUserPreferences}
              tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                TABLE_CONFIGS,
                columnsOrdersForTables
              )}
              tablesHiddenColumns={hiddenColumnsForTables}
              isLoading={userPreferencesForPage.loading}
            />
          )}

          {openCreateTicketDialog && (
            <CreateTicketDialog
              open={openCreateTicketDialog}
              onClose={onCloseCreateTicketDialogLocal}
            />
          )}
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
