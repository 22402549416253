import React, { useRef, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Badge } from "@mui/material";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import DraftsIcon from "@mui/icons-material/Drafts";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import EngineeringIcon from "@mui/icons-material/Engineering";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import EditIcon from "@mui/icons-material/Edit";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import IconButtonWithTooltip from "../../../button/IconButtonWithTooltip";
import useDialog from "../../../../hooks/useDialog";
import RelatedTicketsDialog from "../../../dialog/RelatedTicketsDialog/";
import ChangeStatusDialog from "../../../dialog/ChangeStatusDialog";
import CreateTaskDialog from "../../../dialog/CreateTaskDialog";
import CreateTicketDialog from "../../../dialog/CreateTicketDialog";
import OffersForTicketDialog from "../../../dialog/OffersForTicketDialog/OffersForTicketDialog";
import MyOffersForTicketDialog from "../../../dialog/MyOffersForTicketDialog/MyOffersForTicketDialog";
import EnclosuresByItemDialog from "../../../dialog/EnclosuresByItemDialog/EnclosuresByItemDialog";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import { isSearchParamsEmpty } from "../../../../helpers/methods";
import Stack from "@mui/material/Stack";
import CreatePostDialog from "../../../dialog/CreatePostDialog/CreatePostDialog";
import PostDocsByItemDialog from "../../../dialog/PostDocsByItemDialog";
import Delete from "@mui/icons-material/Delete";
import useCorrespondenceService from "../../../../services/correspondenceService";
import { useAsyncFn } from "../../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../../context/snackbarAlert";
import SnackbarAlert from "../../../base/SnackbarAlert";
import ConfirmationDialog from "../../../dialog/ConfirmationDialog/confirmationDialog";
import TicketProtocolDialog from "../../../dialog/TicketProtocolDialog";

import { APP_STAGE } from "../../../../helpers/constants";

const ActionIconsForm = ({
  itemId,
  itemNumber,
  isItemClosed,
  isItemFinallyClosed,
  isTicketClaim,
  onChangeStatus,
  isLoading,
  enclosuresCount,
  offerCount,
  myOfferCount,
  ticketRelationsCount,
  availableActionButtons,
  onRefetchData,
  onRefetchTableData,
  onOpenDialogParamsAction,
  onCloseDialogParamsAction,
  itemType = "ticket",
  isAllowedToChangeStatusManualy = true,
  isAllowedToCopyTicket = true,
  autocompleteData,
  itemData,
  setOpenDrawer,
  backdropColor,
  readOnly = false,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { getSearchParamsByFilterPrefix } = useFilterSearchParams(searchParams);

  const [searchParamsFromPage, setSearchParamsFromPage] = useState(() => {
    return getSearchParamsByFilterPrefix();
  });

  const itemTypeForEnclosureDialog = itemType === "task" ? "order" : itemType;
  const snackbarAlert = useSnackbarAlert();
  const { updatePostData } = useCorrespondenceService();
  const updatePostFn = useAsyncFn(updatePostData);

  const isAllowedToChangeStatus = () =>
    !isItemFinallyClosed && isAllowedToChangeStatusManualy;

  const [
    openCreatePostDialog,
    onOpenCreatePostDialog,
    onCloseCreatePostDialog,
  ] = useDialog();

  const [
    openChangeStatusDialog,
    onOpenChangeStatusDialog,
    onCloseChangeStatusDialog,
  ] = useDialog();

  const [
    openRelatedTicketsDialog,
    onOpenRelatedTicketsDialog,
    onCloseRelatedTicketsDialog,
  ] = useDialog();

  const [
    openCreateTicketDialog,
    onOpenCreateTicketDialog,
    onCloseCreateTicketDialog,
  ] = useDialog();

  const [
    openCreateTaskDialog,
    onOpenCreateTaskDialog,
    onCloseCreateTaskDialog,
  ] = useDialog();

  const [
    openOffersForTicketDialog,
    onOpenOffersForTicketDialog,
    onCloseOffersForTicketDialog,
  ] = useDialog();

  const [
    openMyOffersForTicketDialog,
    onOpenMyOffersForTicketDialog,
    onCloseMyOffersForTicketDialog,
  ] = useDialog();

  const [
    openEnclosuresDialog,
    onOpenEnclosuresDialog,
    onCloseEnclosuresDialog,
  ] = useDialog();

  const [
    openPostDocsByItemDialog,
    onOpenPostDocsByItemDialog,
    onClosePostDocsByItemDialog,
  ] = useDialog();

  const [
    openConfimartionDialog,
    onOpenConfimartionDialog,
    onCloseConfimartionDialog,
  ] = useDialog();

  const [
    openTicketProtocolDialog,
    onOpenTicketProtocolDialog,
    onCloseTicketProtocolDialog,
  ] = useDialog();

  const ticticKindRef = useRef(null);
  const ticketDialogReadOnlyModeRef = useRef(false);
  const handleOpenCreateTicketDialogInCopyMode = () => {
    ticticKindRef.current = null;
    ticketDialogReadOnlyModeRef.current = false;
    onOpenCreateTicketDialog();
  };
  const postRef = useRef(null);
  const postDialogReadOnlyModeRef = useRef(false);

  const handleOpenCreatePostDialogInCopyMode = (itemData, actionType) => {
    let newItemData = { ...itemData };
    if (actionType === "copy") {
      delete newItemData.id;
      delete newItemData.post_nr;
    }
    postRef.current = newItemData;
    postDialogReadOnlyModeRef.current = false;
    setCreatePostDialogFormValue(newItemData);
    onOpenCreatePostDialog();
  };

  const [createPostDialogFormValue, setCreatePostDialogFormValue] =
    useState(null);

  const handleOpenCreateTicketDialogInClaimMode = () => {
    ticticKindRef.current = "claim";
    ticketDialogReadOnlyModeRef.current = false;
    onOpenCreateTicketDialog();
  };

  const handleOpenCreateTicketDialogInReadOnlyMode = () => {
    ticticKindRef.current = isTicketClaim ? "claim" : null;
    ticketDialogReadOnlyModeRef.current = true;

    onOpenCreateTicketDialog();
  };

  const handleCloseCreateTicketDialog = (existsChanges) => {
    onCloseCreateTicketDialog();
    if (existsChanges) {
      if (onRefetchData) onRefetchData();

      if (isSearchParamsEmpty(searchParams)) {
        if (onRefetchTableData) onRefetchTableData();
      } else {
        setSearchParams({});
      }
    }
  };

  const handleCloseRelatedTicketsDialog = (existsChanges) => {
    onCloseRelatedTicketsDialog();
    if (existsChanges) {
      if (onRefetchData) onRefetchData();
      if (onRefetchTableData) onRefetchTableData();
    }
  };

  const handleOpenDialogParamsAction = () => {
    if (onOpenDialogParamsAction) {
      onOpenDialogParamsAction();
    }
    setSearchParams({});
    setSearchParamsFromPage(getSearchParamsByFilterPrefix());
  };

  const handleCloseDialogParamsAction = () => {
    if (onCloseDialogParamsAction) {
      onCloseDialogParamsAction();
    }
    setSearchParams({ ...searchParamsFromPage });
    setSearchParamsFromPage({});
  };

  const handleOpenOffersForTicketDialog = () => {
    handleOpenDialogParamsAction();

    onOpenOffersForTicketDialog();
  };

  const handleOpenMyOffersForTicketDialog = () => {
    handleOpenDialogParamsAction();

    onOpenMyOffersForTicketDialog();
  };

  const handleCloseOffersForTicketDialog = () => {
    handleCloseDialogParamsAction();
    onCloseOffersForTicketDialog();
  };

  const handleCloseMyOffersForTicketDialog = (existsChanges) => {
    if (existsChanges) {
      onRefetchData();
    }
    handleCloseDialogParamsAction();
    onCloseMyOffersForTicketDialog();
  };
  const handleClosePostDocsByItemDialogDialog = (existsChanges) => {
    if (existsChanges) {
      onRefetchData();
    }
    onClosePostDocsByItemDialog();
  };

  const handleOpenEnclosuersDialog = () => {
    handleOpenDialogParamsAction();
    onOpenEnclosuresDialog();
  };

  const handleOpenPostDocsByItemDialog = () => {
    onOpenPostDocsByItemDialog();
  };

  const handleCloseEnclosuresDialog = (existsChanges) => {
    if (existsChanges) {
      onRefetchData();
      onRefetchTableData();
    }
    handleCloseDialogParamsAction();
    onCloseEnclosuresDialog();
  };

  const handleClickSendOffer = () => {
    navigate("/offers/", {
      // replace: true,
      state: {
        open_configurator: true,
        ticket_id: itemId,
        from: `${location.pathname}${location.search}`,
      },
    });
  };

  const preparePostToHide = (postId) => {
    return {
      id: postId,
      post_active: false,
    };
  };

  const handleHidePost = (postId) => {
    updatePostFn
      .execute(postId, preparePostToHide(postId))
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.post_deleted")
        );
        onRefetchData();
        setOpenDrawer(false);
      })
      .catch(() => {
        SnackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_post_deleting")
        );
      });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-around">
        {itemType === "post" ? (
          <>
            {availableActionButtons.includes("create_post") &&
              !isItemClosed && (
                <IconButtonWithTooltip
                  onClick={onOpenCreatePostDialog}
                  title={t("form.action_icons.create_post")}
                >
                  <NoteAddIcon />
                </IconButtonWithTooltip>
              )}
            {availableActionButtons.includes("copy_post") &&
              !isTicketClaim &&
              isAllowedToCopyTicket && (
                <IconButtonWithTooltip
                  onClick={() =>
                    handleOpenCreatePostDialogInCopyMode(itemData, "copy")
                  }
                  title={t("form.action_icons.copy_post")}
                >
                  <FileCopyOutlinedIcon />
                </IconButtonWithTooltip>
              )}
            {availableActionButtons.includes("edit_post") && (
              <IconButtonWithTooltip
                onClick={() =>
                  handleOpenCreatePostDialogInCopyMode(itemData, "edit")
                }
                title={t("form.action_icons.edit_post")}
              >
                <EditIcon />
              </IconButtonWithTooltip>
            )}
            {availableActionButtons.includes("enclosures") && (
              <IconButtonWithTooltip
                onClick={handleOpenPostDocsByItemDialog}
                title={t("form.action_icons.enclosures")}
              >
                <Badge
                  color="primary"
                  badgeContent={enclosuresCount}
                  max={999}
                  size="small"
                >
                  <FindInPageIcon />
                </Badge>
              </IconButtonWithTooltip>
            )}
            {availableActionButtons.includes("delete") && (
              <IconButtonWithTooltip
                onClick={onOpenConfimartionDialog}
                title={t("form.action_icons.delete_post")}
              >
                <Delete />
              </IconButtonWithTooltip>
            )}
          </>
        ) : (
          <>
            {availableActionButtons.includes("related_tickets") && (
              <IconButtonWithTooltip
                onClick={onOpenRelatedTicketsDialog}
                title={t("form.action_icons.related_tickets")}
              >
                <Badge
                  color="primary"
                  badgeContent={ticketRelationsCount}
                  max={999}
                  size="small"
                >
                  <RouteOutlinedIcon />
                </Badge>
              </IconButtonWithTooltip>
            )}
            {availableActionButtons.includes("create_task") &&
              !isItemClosed && (
                <IconButtonWithTooltip
                  onClick={onOpenCreateTaskDialog}
                  title={t("form.action_icons.create_task")}
                >
                  <EngineeringIcon />
                </IconButtonWithTooltip>
              )}
            {availableActionButtons.includes("send_offer") && (
              <IconButtonWithTooltip
                onClick={handleOpenOffersForTicketDialog}
                title={t("form.action_icons.offers")}
              >
                <Badge
                  color="primary"
                  badgeContent={offerCount}
                  max={999}
                  size="small"
                >
                  <DraftsIcon />
                </Badge>
              </IconButtonWithTooltip>
            )}
            {availableActionButtons.includes("my_offers") &&
              myOfferCount > 0 && (
                <IconButtonWithTooltip
                  onClick={handleOpenMyOffersForTicketDialog}
                  title={t("form.action_icons.offers")}
                >
                  <Badge
                    color="primary"
                    badgeContent={myOfferCount}
                    max={999}
                    size="small"
                  >
                    <DraftsIcon />
                  </Badge>
                </IconButtonWithTooltip>
              )}
            {availableActionButtons.includes("copy_ticket") &&
              !isTicketClaim &&
              isAllowedToCopyTicket && (
                <IconButtonWithTooltip
                  onClick={handleOpenCreateTicketDialogInCopyMode}
                  title={t("form.action_icons.copy_ticket")}
                >
                  <FileCopyOutlinedIcon />
                </IconButtonWithTooltip>
              )}
            {availableActionButtons.includes("claim_ticket") &&
              isItemClosed && (
                <IconButtonWithTooltip
                  onClick={handleOpenCreateTicketDialogInClaimMode}
                  title={t("form.action_icons.claim_ticket")}
                >
                  <CancelScheduleSendIcon />
                </IconButtonWithTooltip>
              )}
            {availableActionButtons.includes("change_status") &&
              isAllowedToChangeStatus() && (
                <IconButtonWithTooltip
                  onClick={onOpenChangeStatusDialog}
                  title={t("form.action_icons.change_status")}
                >
                  <CheckCircleIcon />
                </IconButtonWithTooltip>
              )}
            {availableActionButtons.includes("enclosures") && (
              <IconButtonWithTooltip
                onClick={handleOpenEnclosuersDialog}
                title={t("form.action_icons.enclosures")}
              >
                <Badge
                  color="primary"
                  badgeContent={enclosuresCount}
                  max={999}
                  size="small"
                >
                  <FindInPageIcon />
                </Badge>
              </IconButtonWithTooltip>
            )}
            {availableActionButtons.includes("questionnaire") && (
              <IconButtonWithTooltip
                onClick={handleOpenCreateTicketDialogInReadOnlyMode}
                title={t("form.action_icons.questionnaire")}
              >
                <AssignmentOutlinedIcon />
              </IconButtonWithTooltip>
            )}
          </>
        )}
        {![APP_STAGE.PROD].includes(
          window._env_.APP_STAGE.toString().toLowerCase()
        ) &&
          availableActionButtons.includes("ticket_protocol") &&
          isItemClosed && (
            <IconButtonWithTooltip
              onClick={onOpenTicketProtocolDialog}
              title={t("form.action_icons.ticket_protocol")}
            >
              <CollectionsBookmarkIcon />
            </IconButtonWithTooltip>
          )}
      </Stack>

      {openChangeStatusDialog && (
        <ChangeStatusDialog
          open={openChangeStatusDialog}
          onSubmit={onChangeStatus}
          onClose={onCloseChangeStatusDialog}
          isItemClosed={isItemClosed}
          itemId={itemId}
          isLoading={isLoading}
          itemType={itemType}
        />
      )}

      {openRelatedTicketsDialog && (
        <RelatedTicketsDialog
          ticketId={itemId}
          open={openRelatedTicketsDialog}
          onClose={handleCloseRelatedTicketsDialog}
          readOnly={isTicketClaim}
        />
      )}

      {openCreateTicketDialog && (
        <CreateTicketDialog
          open={openCreateTicketDialog}
          onClose={handleCloseCreateTicketDialog}
          ticticKind={ticticKindRef.current}
          parentTicketId={itemId}
          readOnly={ticketDialogReadOnlyModeRef.current}
        />
      )}

      {openCreateTaskDialog && (
        <CreateTaskDialog
          open={openCreateTaskDialog}
          onClose={onCloseCreateTaskDialog}
          onSubmit={onRefetchData}
          orderId={itemId}
        />
      )}
      {openCreatePostDialog && (
        <CreatePostDialog
          open={openCreatePostDialog}
          onClose={onCloseCreatePostDialog}
          onSubmit={onRefetchData}
          onRefetchData={onRefetchData}
          postId={itemId}
          postIn={itemData.post_in}
          formValue={createPostDialogFormValue}
          autocompleteData={autocompleteData}
          backdropColor={backdropColor}
        />
      )}

      {openOffersForTicketDialog && (
        <OffersForTicketDialog
          open={openOffersForTicketDialog}
          onClose={handleCloseOffersForTicketDialog}
          ticketId={itemId}
          ticketNumber={itemNumber}
          onClickSendOffer={handleClickSendOffer}
          readOnly={readOnly}
        />
      )}

      {openMyOffersForTicketDialog && (
        <MyOffersForTicketDialog
          open={openMyOffersForTicketDialog}
          onClose={(changes) => handleCloseMyOffersForTicketDialog(changes)}
          ticketId={itemId}
          ticketNumber={itemNumber}
          readOnly={readOnly}
        />
      )}

      {openEnclosuresDialog && (
        <EnclosuresByItemDialog
          open={openEnclosuresDialog}
          onClose={handleCloseEnclosuresDialog}
          itemId={itemId}
          itemType={itemTypeForEnclosureDialog}
        />
      )}
      {openPostDocsByItemDialog && (
        <PostDocsByItemDialog
          open={openPostDocsByItemDialog}
          onClose={handleClosePostDocsByItemDialogDialog}
          itemId={itemId}
          itemType={itemType}
        />
      )}
      {openConfimartionDialog && (
        <ConfirmationDialog
          open={openConfimartionDialog}
          onYes={() => handleHidePost(itemId)}
          onNo={onCloseConfimartionDialog}
          title={t(`other.enclosure_data_row.remove_post`)}
          content={`${t("other.enclosure_data_row.are_you_sure_to_remove")}${
            itemData.post_title
          }`}
          maxWidth={"xs"}
          titleSx={{ color: "var(--alert)" }}
          contentSx={{ fontFamily: "Roboto", textAlign: "center" }}
          btnYesColor={"error"}
          btnNoColor={"secondary"}
          showDialogContentDividers={false}
        />
      )}
      {openTicketProtocolDialog && (
        <TicketProtocolDialog
          open={openTicketProtocolDialog}
          onClose={onCloseTicketProtocolDialog}
          ticketId={itemId}
        />
      )}
    </>
  );
};

ActionIconsForm.propTypes = {
  itemId: PropTypes.string,
  isItemClosed: PropTypes.bool,
  isItemFinallyClosed: PropTypes.bool,
  isTicketClaim: PropTypes.bool,
  onClickMergeTickets: PropTypes.func,
  onCloseCopyTicketDialog: PropTypes.func,
  onClickEnclosures: PropTypes.func,
  availableActionButtons: PropTypes.array,
  onOpenDialogParamsAction: PropTypes.func,
  onCloseDialogParamsAction: PropTypes.func,
  isLoading: PropTypes.bool,
  enclosuresCount: PropTypes.number,
  offerCount: PropTypes.number,
  myOfferCount: PropTypes.number,
  onRefetchData: PropTypes.func,
  ticketRelationsCount: PropTypes.number,
  itemType: PropTypes.oneOf(["ticket", "order", "post"]),
  orderId: PropTypes.string,
};

ActionIconsForm.defaultProps = {
  isLoading: false,
  availableActionButtons: [
    "claim_ticket",
    "change_status",
    "related_tickets",
    "send_offer",
    "copy_ticket",
    "enclosures",
    "questionnaire",
  ],
  itemType: "ticket",
  isItemClosed: false,
};

export default ActionIconsForm;
