import PropTypes from "prop-types";

import BasicDialog from "../../base/BasicDialog";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import RcpScheduleDialogSearchBar from "../../bar/RcpScheduleDialogSearchBar";
import RcpGraphicSchedule from "../../other/RcpGraphicSchedule";

const RcpScheduleDialog = (props) => {
  const { t } = useTranslation();

  const getDialogContent = () => (
    <Grid
      container
      item
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12}>
        <RcpScheduleDialogSearchBar />
      </Grid>
      <Grid item xs={12}>
        <RcpGraphicSchedule />
      </Grid>
    </Grid>
  );
  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth={"xxl"}
      title={t("dialog.rcp_schedule_dialog.schedule")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

RcpScheduleDialog.propTypes = {
  open: PropTypes.bool,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func,
};

RcpScheduleDialog.defaultProps = {};

export default RcpScheduleDialog;
