import { Grid, Typography, useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import ContextMenu from "../../../wrapper/ContextMenu";
import { useTranslation } from "react-i18next";
import { STATUS_KIND } from "../../../../helpers/constants";
import {
  blendHexWithOpacity,
  convertRGBToRGBWithOpacity,
  isEmptyValue,
} from "../../../../helpers/methods";
import useTimelineService from "../../../../services/timelineService";
import {
  taskDateStartTPlanField,
  taskDateStartTRealField,
  taskDateStopTPlanField,
  taskDateStopTRealField,
} from "../../../../helpers/fieldNames";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";

const PlannerItem = ({
  item,
  timelineContext,
  itemContext,
  getItemProps,
  getResizeProps,
  onDuplicate,
  onDelete,
  onSave,
  onShowDetails,
  hasPermissionToManagePlanner
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const BORDER_RADIUS = 4;

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  // const backgroundColor = itemContext.selected ? "orange" : defaultBgColor;
  const borderColor = itemContext.resizing ? "red" : item.color;

  const { calculatePixelPerMinute } = useTimelineService();

  const onExecuteCallbackAction = useCallback(
    (action) => {
      if (action) {
        action(item);
      }
    },
    [item]
  );

  const onSaveCallback = useCallback(() => {
    onExecuteCallbackAction(onSave);
  }, [onExecuteCallbackAction, onSave]);

  const onDuplicateCallback = useCallback(() => {
    onExecuteCallbackAction(onDuplicate);
  }, [onExecuteCallbackAction, onDuplicate]);

  const onDeleteCallback = useCallback(() => {
    onExecuteCallbackAction(onDelete);
  }, [onExecuteCallbackAction, onDelete]);

  const onShowDetailsCallback = useCallback(() => {
    onExecuteCallbackAction(onShowDetails);
  }, [onExecuteCallbackAction, onShowDetails]);

  const contextMenuActions = useMemo(() => {
    let actions = [];
    if (item.allow_edit_on_planner && hasPermissionToManagePlanner) {
      if (item.added || item.edited) {
        actions.push({
          label: t("other.planner_item.save_changes"),
          callback: onSaveCallback,
        });
      }
      actions = actions.concat([
        {
          label: t("other.planner_item.duplicate"),
          callback: onDuplicateCallback,
        },
      ]);
      if (item.allow_delete_on_planner !== false) {
        actions.push({
          label: t("other.planner_item.delete"),
          callback: onDeleteCallback,
        });
      }
    }
    if (!item.added && item.id) {
      actions.push({
        label: t("other.planner_item.details"),
        callback: onShowDetailsCallback,
      });
    }
    return actions;
  }, [
    item.edited,
    item.added,
    item.allow_edit_on_planner,
    onSaveCallback,
    onDuplicateCallback,
    onDeleteCallback,
    t,
    onShowDetailsCallback,
    hasPermissionToManagePlanner
  ]);

  const isDuringExecute = useMemo(() => {
    return (
      !isEmptyValue(item.task_date_startT_real) &&
      isEmptyValue(item.task_date_stopT_real)
    );
  }, [item.task_date_startT_real, item.task_date_stopT_real]);

  const backgroundColor = useMemo(() => {
    if (itemContext.selected) {
      return "#ffa500";
    }
    const defaultBgColor = "var(--light-blue)";
    switch (item?.status?.status_kind) {
      case STATUS_KIND.CLOSE:
        return theme.palette.success.light;
      case STATUS_KIND.PENDING:
        return theme.palette.error.light;
      case STATUS_KIND.DURING:
        return theme.palette.warning.light;
      case STATUS_KIND.NORMAL:
        return defaultBgColor;
      case STATUS_KIND.UNFINISHED:
        return "#b803ff";
      default:
        return defaultBgColor;
    }
  }, [item.status, itemContext.selected, theme, isDuringExecute]);

  const icon = useMemo(() => {
    const style = { height: itemContext.dimensions.height - 2 };
    switch (item?.status?.status_kind) {
      case STATUS_KIND.PENDING:
        return <PauseCircleOutlineOutlinedIcon style={style} />;
      case STATUS_KIND.DURING:
        return <PlayCircleOutlinedIcon style={style} />;
      case STATUS_KIND.NORMAL:
        return <CalendarMonthOutlinedIcon style={style} />;
      case STATUS_KIND.CLOSE:
        return <TaskAltOutlinedIcon style={style} />;
      case STATUS_KIND.UNFINISHED:
        if (item?.substatus?.substatus_is_system) {
          return <CancelOutlinedIcon style={style} />;
        }
        return <ManageAccountsOutlinedIcon style={style} />;
      default:
        return <CalendarMonthOutlinedIcon style={style} />;
    }
  }, [
    item.status,
    itemContext?.dimensions?.height,
    item?.substatus?.substatus_is_system,
  ]);

  const pixelPerMinute = useMemo(() => {
    return calculatePixelPerMinute(
      timelineContext.visibleTimeStart,
      timelineContext.visibleTimeEnd,
      timelineContext.timelineWidth
    );
  }, [
    timelineContext.visibleTimeStart,
    timelineContext.visibleTimeEnd,
    timelineContext.timelineWidth,
    calculatePixelPerMinute,
  ]);

  const opacityBackgroundColor = useMemo(() => {
    //return color in hex
    const opacity = 0.7;
    if (backgroundColor.startsWith("#")) {
      return blendHexWithOpacity(backgroundColor, opacity);
    } else if (backgroundColor.startsWith("rgb(")) {
      return convertRGBToRGBWithOpacity(backgroundColor, opacity);
    }
    return null;
  }, [backgroundColor]);

  const minutesStartBeforePlanShowingOnTimeline = useMemo(() => {
    //number of minutes start realize task before plan showing on timeline
    if (!isEmptyValue(item[taskDateStartTRealField])) {
      let startRealTOnTimeline =
        item[taskDateStartTRealField] > timelineContext.canvasTimeStart
          ? item[taskDateStartTRealField]
          : timelineContext.canvasTimeStart;
      return (
        (item[taskDateStartTPlanField] - startRealTOnTimeline) / (1000 * 60)
      );
      // return 120
    }
    return 0;
  }, [item, timelineContext]);

  const minutesEndAfterPlanShowingOnTimeline = useMemo(() => {
    if (!isEmptyValue(item[taskDateStopTRealField])) {
      let endStopPlanTOnTimeline =
        item[taskDateStopTRealField] < timelineContext.canvasTimeEnd
          ? item[taskDateStopTRealField]
          : timelineContext.canvasTimeEnd;
      return (
        (endStopPlanTOnTimeline - item[taskDateStopTPlanField]) / (1000 * 60)
      );
      // return 120
    }
    return 0;
  }, [item, timelineContext]);

  const getNumberOfPixelForMinutes = useCallback(
    (minutes) => {
      if (minutes > 0) {
        return minutes * pixelPerMinute;
      }
      return 0;
    },
    [pixelPerMinute]
  );

  const numberOfPixelStartBeforePlan = useMemo(() => {
    return getNumberOfPixelForMinutes(minutesStartBeforePlanShowingOnTimeline);
  }, [minutesStartBeforePlanShowingOnTimeline, getNumberOfPixelForMinutes]);

  const numberOfPixelEndAfterPlan = useMemo(() => {
    return getNumberOfPixelForMinutes(minutesEndAfterPlanShowingOnTimeline);
  }, [minutesEndAfterPlanShowingOnTimeline, getNumberOfPixelForMinutes]);

  return (
    <ContextMenu
      actions={contextMenuActions}
      showMenu={true}
      Component={
        <div
          {...getItemProps({
            style: {
              color: item.color,
              borderColor,
              borderStyle: "solid",
              borderWidth: 0,
              borderRadius: BORDER_RADIUS,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              backgroundColor,
            },
          })}
        >
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
          {!itemContext.selected && numberOfPixelStartBeforePlan > 0 && (
            <div
              style={{
                width: `${numberOfPixelStartBeforePlan}px`,
                backgroundColor: opacityBackgroundColor,
                height: "100%",
                position: "absolute",
                zIndex: -1,
                borderRadius: `${BORDER_RADIUS}px 0 0 ${BORDER_RADIUS}px`,
              }}
            />
          )}
          <Grid
            container
            style={{
              height: itemContext.dimensions.height,
              overflow: "hidden",
              paddingLeft: 3,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              borderRadius: 4,
            }}
          >
            <Grid item xs={12} display={"flex"}>
              {icon}
              <Typography
                variant="overline"
                style={{
                  // fontSize: itemContext.dimensions.height - 6,
                  // lineHeight: 1.5,
                  fontSize: itemContext.dimensions.height - 8,
                  lineHeight: 1.75,
                  textTransform: "capitalize",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {itemContext.title}
              </Typography>
            </Grid>
          </Grid>
          {!itemContext.selected && numberOfPixelEndAfterPlan > 0 && (
            <div
              style={{
                width: `${numberOfPixelEndAfterPlan}px`,
                backgroundColor: opacityBackgroundColor,
                height: "100%",
                position: "absolute",
                zIndex: -1,
                borderRadius: `0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0`,
                top: 0,
                right: 0,
              }}
            />
          )}

          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
        </div>
      }
    />
  );
};

export default PlannerItem;
