import { Grid } from "@mui/material";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { NAVIGATION_DRAWER_TYPE_CORRESPONDENCE} from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import CorrespondenceAdminTabs from "../../components/tabs/CorrespondenceAdminTabs";


export default function CorrespondenceAdminPage(props) {
  const { pageName } = props;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_CORRESPONDENCE}
    >
      <DefaultPageWrapper titleKey={"correspondence_admin"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <CorrespondenceAdminTabs pageName={pageName} />
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
