import { AUTOCOMPLETE_FIELD_TYPE,  BOOLEAN_FIELD_TYPE,  TEXT_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import SelectFieldService from "../../../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import PrefixSuffixFieldTableWrapper from "../../../wrapper/PrefixSuffixFieldTableWrapper"
import WarningAlertIcon from "../../../other/WarningAlertIcon"
import { formatRackShellData } from "../../../../page/StockWarehousePage/PageTablesConfig";


export const WAREHOUSE_TABLE_NAME = "warehouse_table";
export const WAREHOUSE_TABLE_CONFIG = [
  {
    name: "department_name",
    getValueCallback: (rowData) => rowData.department_name,
    label: i18n.t("table.correspondence_admin.department_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "department_mail",
    getValueCallback: (rowData) => rowData.department_mail,
    label: i18n.t("table.correspondence_admin.department_e-mail"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  
  {
    name: "department_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.department_active),
    label: i18n.t("table.correspondence_admin.department_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickMultipleLocationAlert, onClickEdit) => {
      const content = (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      );
      return rowData.department_active ? content : "";
    },
    label: i18n.t("table.correspondence_admin.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
