import { useState, useCallback } from "react";
import PropTypes from "prop-types";

import MessagesDialog from "../MessagesDialog";
import EnclosuresDialog from "../EnclosuresDialog";
import useMessagesData from "../../../hooks/useMessagesData";
import useEnclosuresData from "../../../hooks/useEnclosuresData";
import usePermissionService from "../../../services/permissionService";
import { getKeysFromFilterDict } from "../../../helpers/methods";

import i18n from "../../../i18n";

function UniversalCommunicationLogDialog(props) {
  const { availableEnclosuresFilters, availableMessagesFilters } =
    usePermissionService();
  const {
    messages,
    localMessageForm,
    sendNewMessage,
    hideMessage,
    chooseFilterGroup,
    chosenFilterGroup,
    isLoading,
    isCorrectedSend,
    showMessageType,
    canHideMessages,
  } = useMessagesData(
    props.itemId,
    getKeysFromFilterDict(availableMessagesFilters),
    props.itemType
  );

  const {
    enclosures,
    saveEnclosures,
    updateEnclosure,
    deleteEnclosureForItem,
    chooseEnclosuresFilterGroup,
    chosenEnclosuresFilterGroup,
    isEnclosuresLoading,
    onDownloadEnclosure,
    onPreviewEnclosure,
    onAddEnclosureToFavorite,
    defaultEnclosureType,
    showExtendedAddEnclosureDialog,
    showFilters,
    showEnclosureType,
    showSubcontractorSelect,
    canHideEnclosures,
  } = useEnclosuresData(
    props.itemId,
    getKeysFromFilterDict(availableEnclosuresFilters),
    props.itemType
  );

  const [showEnlosuresDialog, setShowEnlosuresDialog] = useState(false);

  const [existsChanges, setExistsChanges] = useState(false);

  const addNewMessageFromUser = useCallback((message) => {
    sendNewMessage(message);
    setExistsChanges(true);
  }, []);

  const hideMessageCallback = useCallback((messageId) => {
    hideMessage(messageId);
    setExistsChanges(true);
  }, []);

  const addNewEnclosureFromUser = useCallback((enclosures, commonData) => {
    saveEnclosures(enclosures, commonData);
    setExistsChanges(true);
  }, []);

  const updateEnclosureCallback = useCallback((enclosure) => {
    updateEnclosure(enclosure);
    setExistsChanges(true);
  }, []);

  const deleteEnclosureCallback = useCallback((enclosureId) => {
    deleteEnclosureForItem(enclosureId);
    setExistsChanges(true);
  }, []);

  const downloadEnclosure = useCallback((enclosureId, enclosureName) => {
    onDownloadEnclosure(enclosureId, enclosureName);
  }, []);

  const previewEnclosure = useCallback((enclosureId, index) => {
    onPreviewEnclosure(enclosureId, index);
  }, []);

  const addEnclosureToFavorite = useCallback((enclosureId) => {
    onAddEnclosureToFavorite(enclosureId);
  }, []);

  const onCloseEnclosureDialog = () => {
    setShowEnlosuresDialog(false);
    if (props.onCloseEnclosureDialog)
      props.onCloseEnclosureDialog(existsChanges);
  };

  const getInputMessageErrorText = () => {
    if (
      ["ticket", "ticket_order"].includes(props.itemType) &&
      props.isFinallyClosed
    ) {
      return i18n.t("form.communication_log_form.ticket_is_closed");
    }
  };

  return (
    <>
      <MessagesDialog
        open={props.open}
        messages={messages}
        showDialogAsForm={props.showDialogAsForm}
        showMessageType={showMessageType}
        onClose={() => props.onClose(existsChanges)}
        onSubmit={(message) => addNewMessageFromUser(message)}
        showFilters={showFilters}
        inputMessage={localMessageForm}
        onChooseFilterGroup={chooseFilterGroup}
        chosenFilterGroup={chosenFilterGroup}
        showLoader={isLoading}
        messageWindowHeight={{ height: props.messageWindowHeight }}
        isCorrectedSend={isCorrectedSend}
        onOpenEnclosuresDialog={() => setShowEnlosuresDialog(true)}
        filterTypes={availableMessagesFilters}
        canHideMessages={canHideMessages}
        onHideMessage={(messageId) => hideMessageCallback(messageId)}
        inputMessageFormShowOnlyAttachmentsIcon={
          props.inputMessageFormShowOnlyAttachmentsIcon
        }
        inputMessageFormGridJustifyContent={
          props.inputMessageFormGridJustifyContent
        }
        inputMessageFormEnterButtonGridSmGrid={
          props.inputMessageFormEnterButtonGridSmGrid
        }
        inputMessageErrorText={getInputMessageErrorText()}
      />
      <EnclosuresDialog
        open={showEnlosuresDialog}
        enclosures={enclosures}
        onClose={onCloseEnclosureDialog}
        onSubmit={(enclosures, commonData) =>
          addNewEnclosureFromUser(enclosures, commonData)
        }
        onDownload={(enclosureId, enclosureName) =>
          downloadEnclosure(enclosureId, enclosureName)
        }
        onAddEnclosureToFavorite={addEnclosureToFavorite}
        onPreview={previewEnclosure}
        showFilters={showFilters}
        onChooseFilterGroup={chooseEnclosuresFilterGroup}
        chosenFilterGroup={chosenEnclosuresFilterGroup}
        showLoader={isEnclosuresLoading}
        attachmentWindowHeight={{ height: 400 }}
        filterTypes={availableEnclosuresFilters}
        showEnclosureType={showEnclosureType}
        showSubcontractorSelect={showSubcontractorSelect}
        defaultEnclosureType={defaultEnclosureType}
        onUpdateEnclosure={(enclosure) => updateEnclosureCallback(enclosure)}
        onDeleteEnclosure={(enclosureId) =>
          deleteEnclosureCallback(enclosureId)
        }
        showExtendedAddEnclosureDialog={showExtendedAddEnclosureDialog}
        canHideEnclosures={canHideEnclosures}
      />
    </>
  );
}

UniversalCommunicationLogDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  itemId: PropTypes.string,
  itemType: PropTypes.oneOf(["ticket", "order", "ticket_order"]),
  showDialogAsForm: PropTypes.bool,
  showExtendedAddEnclosureDialog: PropTypes.bool,
  onCloseEnclosureDialog: PropTypes.func,
  messageWindowHeight: PropTypes.number,
  inputMessageFormShowOnlyAttachmentsIcon: PropTypes.bool,
  inputMessageFormSGridJustifyContent: PropTypes.string,
  inputMessageFormSEnterButtonGridSmGrid: PropTypes.number,
  isFinallyClosed: PropTypes.bool,
};

UniversalCommunicationLogDialog.defaultProps = {
  open: false,
  showDialogAsForm: false,
  showExtendedAddEnclosureDialog: false,
  messageWindowHeight: 400,
};

export default UniversalCommunicationLogDialog;
