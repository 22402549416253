import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";

import { Grid } from "@mui/material";
import { BackGroundImage } from "../../media/background";
import CardIconWithText from "../../components/other/CardIconWithText";
import { useAuth } from "../../context/auth";
import useLogout from "../../hooks/useLogout";
import { isAppTesting } from "../../helpers/methods";
import { APP_STAGE, APP_STAGE_BAR_HEIGHT } from "../../helpers/constants";

import hasUserPermission, {
  IS_K,
  IS_KT,
  IS_OM,
  IS_T,
  IS_TB,
  IS_R,
  IS_RR,
  IS_G,
  IS_ADMIN,
  IS_LEGAL,
  IS_POST,
  IS_WAREHOUSE,
  IS_WAREHOUSE_ADMIN,
  IS_SCM,
  IS_FINANCE,
  IS_ESG,
  IS_ACCOUNT,
  isAADUser,
  IS_CONTROLLING,
  IS_COMMERCE,
  IS_USERS_ADMIN,
  IS_TENANTS_ADMIN,
  IS_OBJECTS_ADMIN,
  IS_STD_TICKETS_ADMIN,
  IS_AUTO_TICKETS_ADMIN,
  IS_REPORT_FM,
  IS_REPORT_SAFETY,
} from "../../helpers/userPermissions";

const cardAreaStyle = {
  height: "13vh",
  alignItems: "center",
  textAlign: "center",
};

const GRID_COLS = 8;
const GRID_ROWS = 6;

const generateGrid = (
  gridRows,
  gridCols,
  gridConfig,
  generateConfigElement
) => {
  const rowsArray = Array.from({ length: gridRows }, (x, i) => i + 1);
  const colsArray = Array.from({ length: gridCols }, (x, i) => i + 1);

  return (
    <>
      {rowsArray.map((row) =>
        colsArray.map((col) => {
          const configElement = generateConfigElement(
            gridConfig[`${row}_${col}`]
          );
          return (
            <Grid
              item
              key={`${row}_${col}`}
              xs={gridCols / 2}
              sm={gridCols / 3}
              lg={1}
              sx={{
                display: { xs: configElement ? "block" : "none", lg: "block" },
              }}
            >
              {configElement}
            </Grid>
          );
        })
      )}
    </>
  );
};

export default function HomePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { handleLogout } = useLogout();
  const config = {
    "1_1": {
      label: t("page.home_page.tickets"),
      icon: "GroupsIcon",
      isActive: true,
      // hasUserPermission(
      //   [IS_K, IS_KT, IS_OM, IS_T, IS_TB, IS_R, IS_RR, IS_ADMIN, IS_LEGAL, IS_WAREHOUSE,IS_FINANCE],
      //   user
      // ),
      onClick: () => navigate("/redirect_page/"),
    },
    "2_1": {
      label: t("page.home_page.offers"),
      icon: "DraftsIcon",
      isActive: hasUserPermission([IS_K, IS_KT, IS_TB, IS_RR, IS_ADMIN], user),
      onClick: () =>
        hasUserPermission([IS_K, IS_ADMIN], user)
          ? navigate("/offers/")
          : hasUserPermission([IS_KT, IS_TB], user)
          ? navigate("/join_offers/")
          : hasUserPermission([IS_RR], user)
          ? navigate("/accept_offers/"):
          undefined
    },
    "3_1": {
      label: t("page.home_page.documents"),
      icon: "DescriptionIcon",
      isActive: false,
      onClick: () => {},
    },
    "4_1": {
      label: t("page.home_page.payment"),
      icon: "RequestQuoteIcon",
      isActive: hasUserPermission([IS_ACCOUNT, IS_ADMIN], user),
      onClick: () =>
        hasUserPermission([IS_ACCOUNT, IS_ADMIN], user)
          ? navigate("/grt_report/")
          : null,
    },
    "5_1": {
      label: t("page.home_page.guarantees"),
      icon: "WorkspacePremiumIcon",
      isActive: hasUserPermission([IS_G, IS_TB, IS_ADMIN], user),
      onClick: () =>
        hasUserPermission([IS_G, IS_TB, IS_ADMIN], user)
          ? navigate("/guarantee/")
          : null,
    },
    "6_1": {
      label: t("page.home_page.warehouses"),
      icon: "WarehouseIcon",
      isActive:
        hasUserPermission([IS_WAREHOUSE, IS_ADMIN, IS_WAREHOUSE_ADMIN], user) &&
        [APP_STAGE.STAGE, APP_STAGE.LOCAL].includes(
          window._env_.APP_STAGE.toString().toLowerCase()
        ),
      onClick: () =>
        hasUserPermission([IS_WAREHOUSE, IS_ADMIN], user) &&
        [APP_STAGE.STAGE, APP_STAGE.LOCAL].includes(
          window._env_.APP_STAGE.toString().toLowerCase()
        )
          ? navigate("/stocks/")
          :      hasUserPermission([IS_WAREHOUSE_ADMIN], user) &&
          [APP_STAGE.STAGE, APP_STAGE.LOCAL].includes(
            window._env_.APP_STAGE.toString().toLowerCase()
          )
            ? navigate("/warehouse_admin/")
            : null,
    },
    "1_2": {
      label: t("page.home_page.counters"),
      icon: "DeviceThermostatIcon",
      isActive: false,
      onClick: () => {},
    },
    "2_2": {
      label: t("page.home_page.subcontractors"),
      icon: "Diversity3Icon",
      isActive: hasUserPermission([IS_K, IS_KT,  IS_ADMIN], user),
      onClick:  () => navigate("/subcontractors/")
    },
    "3_2": {
      label: t("page.home_page.notifications"),
      icon: "RunningWithErrorsIcon",
      isActive: false,
      onClick: () => {},
    },
    "4_2": {
      label: t("page.home_page.tools"),
      icon: "HeatPumpIcon",
      isActive: false,
      onClick: () => {},
    },
    "5_2": {
      label: t("page.home_page.shopping"),
      icon: "ShoppingCartIcon",
      isActive:hasUserPermission([IS_SCM, IS_ADMIN], user) &&
      [APP_STAGE.STAGE, APP_STAGE.LOCAL].includes(
        window._env_.APP_STAGE.toString().toLowerCase()
      ),
      onClick: () => hasUserPermission([IS_SCM, IS_ADMIN], user) &&
      [APP_STAGE.STAGE, APP_STAGE.LOCAL].includes(
        window._env_.APP_STAGE.toString().toLowerCase()
      )
        ? navigate("/po_registry/")
        : undefined,

    },
    "6_2": {
      label: t("page.home_page.tpm"),
      icon: "EngineeringIcon",
      isActive: false,
      onClick: () => {},
    },
    "1_3": {
      label: t("page.home_page.correspondence"),
      icon: "AlternateEmailIcon",
      isActive:
        hasUserPermission([IS_LEGAL, IS_POST, IS_ADMIN], user) &&
        [APP_STAGE.STAGE, APP_STAGE.LOCAL].includes(
          window._env_.APP_STAGE.toString().toLowerCase()
        ),
      onClick: () =>
        hasUserPermission([IS_LEGAL, IS_POST, IS_ADMIN], user) &&
        [APP_STAGE.STAGE, APP_STAGE.LOCAL].includes(
          window._env_.APP_STAGE.toString().toLowerCase()
        )
          ? navigate("/correspondence/")
          : null,
    },
    "2_3": {
      label: t("page.home_page.esg"),
      icon: "EuroIcon",
      isActive: false,
      onClick: () => {},
    },
    "3_3": {
      label: t("page.home_page.releases"),
      icon: "AddBusinessIcon",
      isActive: hasUserPermission([IS_COMMERCE, IS_ADMIN], user),
      onClick: () =>
        hasUserPermission([IS_COMMERCE, IS_ADMIN], user)
          ? navigate("/releases/")
          : null,
    },
    "4_3": {
      label: t("page.home_page.capital_group"),
      icon: "BusinessIcon",
      isActive: hasUserPermission(
        [
          IS_LEGAL,
          IS_CONTROLLING,
          IS_FINANCE,
          IS_ACCOUNT,
          IS_COMMERCE,
          IS_ADMIN,
        ],
        user
      ),
      onClick: () =>
        hasUserPermission(
          [
            IS_LEGAL,
            IS_CONTROLLING,
            IS_FINANCE,
            IS_ACCOUNT,
            IS_COMMERCE,
            IS_ADMIN,
          ],
          user
        )
          ? navigate("/owners/")
          : null,
    },
    "5_3": {
      label: t("page.home_page.rr_panel"),
      icon: "PersonIcon",
      isActive: hasUserPermission([IS_RR, IS_ADMIN], user),
      onClick: () =>
        hasUserPermission([IS_RR, IS_ADMIN], user)
          ? navigate("/rr_panel/")
          : null,
    },
    "6_3": {
      label: t("page.home_page.agreements"),
      icon: "HandshakeIcon",
      isActive:
        hasUserPermission(
          [IS_LEGAL, IS_CONTROLLING, IS_COMMERCE, IS_ADMIN],
          user
        ) &&
        ![APP_STAGE.PROD].includes(
          window._env_.APP_STAGE.toString().toLowerCase()
        ),
      onClick: () =>
        hasUserPermission(
          [IS_LEGAL, IS_CONTROLLING, IS_COMMERCE, IS_ADMIN],
          user
        ) &&
        ![APP_STAGE.PROD].includes(
          window._env_.APP_STAGE.toString().toLowerCase()
        )
          ? navigate("/agreements/")
          : null,
    },
    "1_8": {
      label: t("page.home_page.logout"),
      icon: "LogoutIcon",
      isActive: true,
      onClick: handleLogout,
    },
    "4_8": {
      label: t("page.home_page.dashboard_sla"),
      icon: "DashboardIcon",
      isActive: false,
      onClick: () => {},
    },
    "5_8": {
      label: t("page.home_page.reports"),
      icon: "AssessmentIcon",
      isActive: hasUserPermission(
        [IS_KT, IS_REPORT_SAFETY, IS_REPORT_FM, IS_ADMIN],
        user
      ),
      onClick: () =>
        hasUserPermission(
          [IS_KT, IS_REPORT_SAFETY, IS_REPORT_FM, IS_ADMIN],
          user
        )
          ? navigate("/reports_redirect_page/")
          : null,
    },
    "6_8": {
      label: t("page.home_page.admin_panel"),
      icon: "AdminPanelSettingsIcon",
      isActive: hasUserPermission(
        [
          IS_ADMIN,
          IS_USERS_ADMIN,
          IS_TENANTS_ADMIN,
          IS_OBJECTS_ADMIN,
          IS_STD_TICKETS_ADMIN,
          IS_AUTO_TICKETS_ADMIN,
        ],
        user
      ),
      onClick: () =>
        hasUserPermission(
          [
            IS_ADMIN,
            IS_USERS_ADMIN,
            IS_TENANTS_ADMIN,
            IS_OBJECTS_ADMIN,
            IS_STD_TICKETS_ADMIN,
            IS_AUTO_TICKETS_ADMIN,
          ],
          user
        )
          ? navigate("/admin_panel_redirect_page/")
          : null,
    },
  };

  const getCardIconWithText = (config) => {
    if (!config) return null;

    return (
      <CardIconWithText
        cardAreaStyle={cardAreaStyle}
        label={config.label}
        icon={config.icon}
        isSelected={config.isActive}
        onClick={config.onClick}
      />
    );
  };

  if (!isAADUser(user)) return <Navigate to={"/redirect_page/"} />;

  const marginTop = isAppTesting() ? APP_STAGE_BAR_HEIGHT : "0px";

  return (
    <Grid
      container
      direction="columns"
      justifyContent="center"
      alignItems="center"
      columnSpacing={2}
      marginY={{ xs: 1, sm: 0 }}
      rowGap={{ xs: 1, sm: 0 }}
      paddingX={1}
      columns={GRID_COLS}
      style={{
        backgroundImage: `url(${BackGroundImage})`,
        minHeight: `calc(100vh - ${marginTop})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "right",
        marginTop: marginTop,
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
      }}
    >
      {generateGrid(GRID_ROWS, GRID_COLS, config, getCardIconWithText)}
    </Grid>
  );
}
