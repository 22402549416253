import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import FieldWithLabel from "../../../field/FieldWithLabel";
import TextFieldFormControl from "../../../field/TextFieldFormControl";
import TrueFalseSelectField from "../../../field/TrueFalseSelectField";
import SelectField from "../../../field/SelectField";


const SubcontractorForm = (props) => {
  const { t } = useTranslation();

  const [isTextFieldChanged, setIsTextFieldChanged] = useState(false);
  const [textFieldsFormValueLocal, setTextFieldsFormValueLocal] = useState(props.subcontractorData)
  const onChangeTextFieldLocal = (e) => {
    setIsTextFieldChanged(true);
    setTextFieldsFormValueLocal((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeTextField = (e) => {
    if (isTextFieldChanged) {
      setIsTextFieldChanged(false);
      props.onChangeTextField(e);
    }
  };



  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={2}
    >
      <Grid item xs={12}>
        <FieldWithLabel
          label={t(`form.subcontractor_form.subcontractor_full_name`)}
          field={
            <TextFieldFormControl
            name={"subcontractor_full_name"}
              value={textFieldsFormValueLocal?.subcontractor_full_name}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t(`form.subcontractor_form.subcontractor_short_name`)}
          field={
            <TextFieldFormControl
              name={"subcontractor_short_name"}
              value={textFieldsFormValueLocal?.subcontractor_short_name}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t(`form.subcontractor_form.subcontractor_legal`)}
          field={
            <TextFieldFormControl
              name={"subcontractor_legal"}
              value={textFieldsFormValueLocal?.subcontractor_legal}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t(`form.subcontractor_form.nip`)}
          field={
            <TextFieldFormControl
            name={"subcontractor_nip"}
              value={textFieldsFormValueLocal?.subcontractor_nip}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.subcontractor_form.krs")}
          field={
            <TextFieldFormControl
              name="subcontractor_krs"
              value={textFieldsFormValueLocal?.subcontractor_krs}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t(`form.subcontractor_form.regon`)}
          field={
            <TextFieldFormControl
            name={"subcontractor_regon"}
              value={textFieldsFormValueLocal?.subcontractor_regon}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.subcontractor_form.contractor")}
          field={
            <TrueFalseSelectField
              name="subcontractor_is_contractor"
              value={textFieldsFormValueLocal?.subcontractor_is_contractor}
              valuesAsBool
              addEmptyOptions={false}
              readOnly={props.readOnly}
              onChange={props.onChangeSelectField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.subcontractor_form.producer")}
          field={
            <TrueFalseSelectField
              name="subcontractor_is_producer"
              value={textFieldsFormValueLocal?.subcontractor_is_producer}
              valuesAsBool
              addEmptyOptions={false}
              readOnly={props.readOnly}
              onChange={props.onChangeSelectField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.subcontractor_form.supplier")}
          field={
            <TrueFalseSelectField
              name="subcontractor_is_supplier"
              value={textFieldsFormValueLocal?.subcontractor_is_supplier}
              valuesAsBool
              addEmptyOptions={false}
              readOnly={props.readOnly}
              onChange={props.onChangeSelectField}
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.subcontractor_form.subcontractor_active")}
          field={
            <TrueFalseSelectField
              name="subcontractor_active"
              value={textFieldsFormValueLocal?.subcontractor_active}
              valuesAsBool
              addEmptyOptions={false}
              readOnly={props.readOnly}
              onChange={props.onChangeSubcontractorActivate}
            />
          }
        />
      </Grid>

    </Grid>
  );
};

SubcontractorForm.propTypes = {
  subcontractorData: PropTypes.object,
  readOnly: PropTypes.bool,
};

SubcontractorForm.defaultProps = {
  readOnly: true,
};

export default SubcontractorForm;
