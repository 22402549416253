import React from "react";
import PropTypes from "prop-types";

import TextFieldTable from "../TextFieldTable/TextFieldTable";
import FloatFieldTable from "../FloatFieldTable/FloatFieldTable";
import CheckboxFieldTable from "../CheckboxFieldTable/CheckboxFieldTable";
import SelectFieldTable from "../SelectFieldTable/SelectFieldTable";

import DateService from "../../../services/dateService";

import {
  TEXT_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  CHECKBOX_FIELD_TYPE,
  SELECT_FIELD_TYPE,
  DATE_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
} from "../../../helpers/constants";
import AutocompleteFieldTable from "../AutocompleteFieldTable/AutocompleteFieldTable";
import DateField from "../DateField/DateField";
import DateFieldTable from "../DateFieldTable/DateFieldTable";

const FieldTable = React.memo(
  ({ type, name, value, idx, editable, onChange, onChangeAutocomplete, onClickAddItemToAutocompletedField, fieldConfig}) => {
    if (type == CHECKBOX_FIELD_TYPE)
      return (
        <CheckboxFieldTable
          name={name}
          value={value}
          idx={idx}
          editable={editable}
          onChange={(e) => {
            onChange(name, e.target.checked, idx);
          }}
        />
      );
    if (type == NUMERIC_FIELD_TYPE)
      return (
        <FloatFieldTable
          name={name}
          value={value}
          readOnly={!editable}
          onChange={(e) => {
            onChange(name, e.target.value, idx);
          }}
        />
      );

    if (type == TEXT_FIELD_TYPE)
      return (
        <TextFieldTable
          name={name}
          value={value}
          readOnly={!editable}
          onChange={(e) => {
            onChange(name, e.target.value, idx);
          }}
    
        />
      );

    if (type == SELECT_FIELD_TYPE)
      return (
        <SelectFieldTable
          name={name}
          value={value}
          readOnly={!editable}
          onChange={(e) => {
            onChange(name, e.target.value, idx);
          }}
          {...fieldConfig}
        />
      );

      if (type == AUTOCOMPLETE_FIELD_TYPE)
        return (
          <AutocompleteFieldTable
            index = {idx}
            name={name}
            value={value}
            readOnly={!editable}
            onChange={onChangeAutocomplete}
            onClickAddItemToAutocompletedField = {onClickAddItemToAutocompletedField}
            {...fieldConfig}
          />
        );
  

    if (type == DATE_FIELD_TYPE)
      return (
        <DateFieldTable
          name={name}
          value={value? DateService.convertDatetimeFromBackendToFormatYYYYMMDD(value): undefined}
          readOnly={!editable}
          onClose ={()=>undefined}
          onChange={(e) => {
            onChange(name, e.target.value, idx);
          }}
        />
      );

    return value;
  }
);

FieldTable.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  idx: PropTypes.number,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  selectFieldConfig: PropTypes.object,
  onClickAddItemToAutocompletedField :PropTypes.func
};

export default FieldTable;
