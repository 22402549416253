import {
  rcpActivityChoicesUrl,
  rcpPlanMassUpdateOrCreateUrl,
  rcpPlanUrl,
  rcpPlanDetailsUrl,
  rcpPlanFilteringDataUrl,
  rcpDoneUrl,
  rcpDoneDetailsUrl,
  rcpDoneMassUpdateOrCreateUrl,
  rcpTimeTrackingUrl,
  rcpScheduleUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import DateService from "./dateService";

export default function useRcpService() {
  const axiosPrivate = useAxiosPrivate();

  const getRcpPlan = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(rcpPlanUrl, searchParams)
    );
  };

  const getRcpPlanFilteringData = () => {
    return makeRequest(axiosPrivate, rcpPlanFilteringDataUrl);
  };

  const getRcpActivityChoices = () => {
    return makeRequest(axiosPrivate, rcpActivityChoicesUrl);
  };

  const massCreateOrUpdateRcpPlan = (dataToSend, exclude_free_days) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(rcpPlanMassUpdateOrCreateUrl, {
        hours_timezone_offset: DateService.getHoursTimezoneOffset(),
        exclude_free_days,
      }),
      {
        method: "POST",
        data: dataToSend,
      }
    );
  };

  const getRcpPlanDetails = (rcpPlanId) => {
    return makeRequest(axiosPrivate, rcpPlanDetailsUrl(rcpPlanId));
  };

  const deleteRcpPlan = (rcpPlanId) => {
    return makeRequest(axiosPrivate, rcpPlanDetailsUrl(rcpPlanId), {
      method: "DELETE",
    });
  };

  const updateRcpPlan = (dataToSend, rcpPlanId) => {
    return makeRequest(axiosPrivate, rcpPlanDetailsUrl(rcpPlanId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getRcpDone = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(rcpDoneUrl, searchParams)
    );
  };

  const massCreateOrUpdateRcpDone = (dataToSend, exclude_free_days) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(rcpDoneMassUpdateOrCreateUrl, {
        hours_timezone_offset: DateService.getHoursTimezoneOffset(),
        exclude_free_days,
      }),
      {
        method: "POST",
        data: dataToSend,
      }
    );
  };

  const getRcpDoneDetails = (rcpDoneId) => {
    return makeRequest(axiosPrivate, rcpDoneDetailsUrl(rcpDoneId));
  };

  const deleteRcpDone = (rcpDoneId) => {
    return makeRequest(axiosPrivate, rcpDoneDetailsUrl(rcpDoneId), {
      method: "DELETE",
    });
  };

  const updateRcpDone = (dataToSend, rcpDoneId) => {
    return makeRequest(axiosPrivate, rcpDoneDetailsUrl(rcpDoneId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getRcpTimeTracking = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(rcpTimeTrackingUrl, searchParams)
    );
  };

  const getRcpSchedule = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(rcpScheduleUrl, searchParams)
    );
  };

  return {
    getRcpPlan,
    getRcpPlanDetails,
    updateRcpPlan,
    deleteRcpPlan,
    getRcpPlanFilteringData,
    getRcpActivityChoices,
    massCreateOrUpdateRcpPlan,
    getRcpDone,
    getRcpDoneDetails,
    updateRcpDone,
    deleteRcpDone,
    massCreateOrUpdateRcpDone,
    getRcpTimeTracking,
    getRcpSchedule,
  };
}
