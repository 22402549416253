import { useTranslation } from "react-i18next";
import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useSnackbarAlert } from "../context/snackbarAlert";
import {
  docsByAgreementUrl,
  createManyDocsUrl,
  docsDataUrl,
  docsDownloadUrl,
  docsFilteringDataUrl,
  certificatesSelectListUrl,
  certificateUrl,
  certsByPartUrl,
  createManyCertsUrl,
  certsDataUrl,
  certificationDownloadUrl,
  docsByPostUrl
} from "../helpers/apiUrls";
import { getFieldsFromObject, prepareUrlWithQueryParams } from "../helpers/methods";
import useThumbnailService from "./thumbnailService";
import useFileService from "./fileService";


export default function useDocsService() {
  const axiosPrivate = useAxiosPrivate();

  const snackbarAlert = useSnackbarAlert();

  const { t } = useTranslation();

  const { convertToThumbnail } = useThumbnailService()

  const {
    getBlobFromAzureByUrl,
    downloadFileByBlobUrl,
    showFileByBlobUrl,
    generateSASUploadTokens,
    uploadDataToAzureStorage } = useFileService()


  const getDocsForItemData = (
    itemId,
    searchParams = {},
    itemType = "agreement"
  ) => {
    if (itemId) {
      if (itemType === "agreement")
        return getDocsForAgreeementsData(itemId, searchParams);

      if (itemType === "post")
        return getDocsForPostsData(itemId, searchParams);
    }
    return Promise.resolve([]);
  };

  const getDocsFilteringData = () => {
    return makeRequest(axiosPrivate, docsFilteringDataUrl);
  };


  const getDocsForAgreeementsData = (
    agreementId,
    searchParams = {}
  ) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        docsByAgreementUrl(agreementId),
        searchParams
      )
    );
  };

  const getDocsForPostsData = (
    postId,
    searchParams = {}
  ) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        docsByPostUrl(postId),
        searchParams
      )
    );
  };


  const saveManyDocs = async (docs, commonDcosData, searchParams= {}) => {
    let results = await uploadFiles(docs, commonDcosData)
    let uploadedData = results['uploadedData']
    let showWaring = results['showWaring']
    return new Promise(async (resolve, reject) => {
      if (uploadedData.length > 0) {
        await createManyDocs(uploadedData, searchParams)
          .then((res) => {
            if (showWaring) {
              snackbarAlert.openWarningSnackbarAlert(
                t("snackbar_alert.occurred_warning_during_upload_file")
              );
            } else {
              snackbarAlert.openSuccessSnackbarAlert(
                t("snackbar_alert.docs_saved")
              );
              resolve(res)
            }
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_upload_file")
        );

      }
    });
  }

  const uploadFiles = async (docs, commonDocData={}) => {
    let showWarning = false
    let getDataToGenerateTokens = docs.map((doc) => doc.token_data);
    let results = await Promise.all([generateSASUploadTokens(getDataToGenerateTokens)])

    let mergedData = []
    results[0].forEach(tokenData => {

      let docData = docs.find(obj => obj.token_data.unique_index === tokenData.unique_index);
      let contentType = docData['token_data']['content_type']
      docData['content_type'] = contentType

      if (docData) {
        mergedData.push({ ...docData, ...tokenData, ...commonDocData })
      }

    });

    let uploadedData = [];
    for (let data of mergedData) {
      delete data['token_data']
      try {
        await uploadDataToAzureStorage(data.file, data.upload_url);
        if (data.miniature_upload_url) {
          convertToThumbnail(data.file, 200, 200, async function (thumbnailBlob) {

            await uploadDataToAzureStorage(thumbnailBlob, data.miniature_upload_url);
          });

        }
        uploadedData.push(getDataToSave(data));
      } catch (error) {
        showWarning = true
      }
    }
    return { 'uploadedData': uploadedData, 'showWaring': showWarning }
  };

  const getDataToSave = (files) => {
    const fieldsToSave = ['docs_name',
                'docs_note', 
                'docs_area', 'docs_owner', 
                'miniature_file_name',
                'file_ori_name',
                'file_name',
                'agreement',
                'user',
                'content_type',
                'part',
                'po',
                'post',
                'cert_name',
                'certification_date',
                'certification_validation',
                'certificate']
    files = getFieldsFromObject(files, fieldsToSave)
    return files
  }

  const createManyDocs = async (data, searchParams) => {
    return makeRequest(axiosPrivate,
      prepareUrlWithQueryParams(createManyDocsUrl, searchParams),
      {
        method: "POST",
        data: data,
      })
  }

  const detailsDocsData = (docsId) => {
    return makeRequest(axiosPrivate, docsDataUrl(docsId));
  };

  const updateDocsData = (docsId, dataToSend) => {
    return makeRequest(axiosPrivate, docsDataUrl(docsId), {
      method: "PATCH",
      data: dataToSend,
    });
  };


  const handleDownloadDoc = async (docId, docName) => {
    let data = await getSasTokenToGetDocFromAzure(docId)
    let url = await getBlobFromAzureByUrl(data.download_link, data.content_type)
    downloadFileByBlobUrl(url, docName)
  };

  const handlePreviewDoc = async (docId) => {
    let data = await getSasTokenToGetDocFromAzure(docId)
    let url = await getBlobFromAzureByUrl(data.download_link, data.content_type)
    showFileByBlobUrl(url)
  };

  const getSasTokenToGetDocFromAzure = (enclosureId) => {
    return makeRequest(axiosPrivate, docsDownloadUrl(enclosureId))
  }

  const getCertificateSelectList = () => {
    return makeRequest(axiosPrivate, certificatesSelectListUrl);
  }

  const createCertificate = (certData) => {
    return makeRequest(axiosPrivate, certificateUrl, {
      method: "POST",
      data: certData,
    });
  };

  const getCertsForItemData = (
    itemId,
    searchParams = {},
    itemType = "part"
  ) => {
    if (itemId) {
      if (itemType === "part")
        return getCertsForPartData(itemId, searchParams);
    }
    return Promise.resolve([]);
  }


  const getCertsForPartData = (
    certId,
    searchParams = {}
  ) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        certsByPartUrl(certId),
        searchParams
      )
    );
  };


  const saveManyCerts = async (docs, searchParams) => {
    let results = await uploadFiles(docs)
    let uploadedData = results['uploadedData']
    let showWaring = results['showWaring']
    return new Promise(async (resolve, reject) => {
      if (uploadedData.length > 0) {
        await createManyCerts(uploadedData, searchParams)
          .then((res) => {
            if (showWaring) {
              snackbarAlert.openWarningSnackbarAlert(
                t("snackbar_alert.occurred_warning_during_upload_file")
              );
            } else {
              snackbarAlert.openSuccessSnackbarAlert(
                t("snackbar_alert.docs_saved")
              );
              resolve(res)
            }
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_upload_file")
        );

      }
    });
  }

  const createManyCerts = async (data, searchParams) => {
    return makeRequest(axiosPrivate,
      prepareUrlWithQueryParams(createManyCertsUrl, searchParams),
      {
        method: "POST",
        data: data,
      })
  }

  const updateCertData = (certId, dataToSend) => {
    return makeRequest(axiosPrivate, certsDataUrl(certId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  
  const handleDownloadCert = async (certId, certName) => {
    let data = await getSasTokenToGetCertFromAzure(certId)
    let url = await getBlobFromAzureByUrl(data.download_link, data.content_type)
    downloadFileByBlobUrl(url, certName)
  };

  const handlePreviewCert = async (certId) => {
    let data = await getSasTokenToGetCertFromAzure(certId)
    let url = await getBlobFromAzureByUrl(data.download_link, data.content_type)
    showFileByBlobUrl(url)
  };

  const getSasTokenToGetCertFromAzure = (certId) => {
    return makeRequest(axiosPrivate, certificationDownloadUrl(certId))
  }


  return {
    getDocsForItemData,
    saveManyDocs,
    updateDocsData,
    handleDownloadDoc,
    handlePreviewDoc,
    getDocsFilteringData,
    detailsDocsData,
    getCertificateSelectList,
    createCertificate,
    getCertsForItemData,
    saveManyCerts,
    updateCertData,
    handleDownloadCert,
    handlePreviewCert,
    
  };
}
