import WarningAlertIcon from "../../components/other/WarningAlertIcon";
import PrefixSuffixFieldTableWrapper from "../../components/wrapper/PrefixSuffixFieldTableWrapper";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import { checkIfValueIsObject } from "../../helpers/methods";
import i18n from "../../i18n";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Tooltip } from "@mui/material";

export const ORDER_TABLE_FIELDS_CONFIG = [
  {
    name: "order_nr",
    getValueCallback: (rowData) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ zIndex: 0, marginLeft: "20px" }}>
            {rowData.order_nr}
          </div>
          {rowData.order_has_enclosures && (
            <div style={{ zIndex: 0, marginRight: "20px" }}>
              <Tooltip
                title={i18n.t("table.order.order_has_enclosures")}
                placement={"right"}
              >
                <AttachFileIcon />
              </Tooltip>
            </div>
          )}
        </div>
      );
    },
    label: i18n.t("table.order.order_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "order_name",
    getValueCallback: (rowData) => rowData.order_name,
    label: i18n.t("table.order.order_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },

  {
    name: "status",
    getValueCallback: (rowData) => rowData.status,
    label: i18n.t("table.order.status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.status,
      isObjectOption: true,
      optionLabelKey: "status_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "order_priority",
    getValueCallback: (rowData) => rowData.order_priority,
    label: i18n.t("table.order.order_priority"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.order_priority,
      isObjectOption: true,
      optionLabelKey: "ordpri_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_1",
    getValueCallback: (rowData, onClickMultipleLocationAlert) => {
      return rowData.is_multiple_locations ? (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <WarningAlertIcon
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickMultipleLocationAlert(e, rowData.id)}
            />
          }
        >
          {rowData.locations[0].level_1}
        </PrefixSuffixFieldTableWrapper>
      ) : (
        rowData.locations[0].level_1
      );
    },
    label: i18n.t("table.ticket.object_level_1"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "std_time_value",
    getValueCallback: (rowData) => rowData.order_std_time_value,
    onlyData: true,
  },
  {
    name: "task_planner_label",
    getValueCallback: (rowData) => rowData.task_planner_label,
    onlyData: true,
  },
  {
    name: "objects_level_2",
    getValueCallback: (rowData) => rowData.locations[0].level_2,
    label: i18n.t("table.ticket.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3",
    getValueCallback: (rowData) => rowData.locations[0].level_3,
    label: i18n.t("table.ticket.object_level_3"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4",
    getValueCallback: (rowData) => rowData.locations[0].level_4,
    label: i18n.t("table.ticket.object_level_4"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5",
    getValueCallback: (rowData) => rowData.locations[0].level_5,
    label: i18n.t("table.ticket.object_level_5"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
];

export const UNFINISHED_TASK_TABLE_FIELDS_CONFIG = [
  {
    name: "task_nr",
    getValueCallback: (rowData) => rowData.task_nr,
    label: i18n.t("table.task.task_nr"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "task_name",
    getValueCallback: (rowData) => rowData.task_name,
    label: i18n.t("table.task.task_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "status",
    getValueCallback: (rowData) => {
      return checkIfValueIsObject(rowData.status)
        ? rowData.status.status_name
        : rowData.status;
    },
    label: i18n.t("table.task.status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.status,
      isObjectOption: true,
      optionLabelKey: "status_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "substatus",
    getValueCallback: (rowData) => rowData.substatus,
    label: i18n.t("table.task.reason"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.substatus,
      isObjectOption: true,
      optionLabelKey: "substatus_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "std_time_value",
    getValueCallback: (rowData) => rowData.order_std_time_value,
    onlyData: true,
  },
  {
    name: "task_planner_label",
    getValueCallback: (rowData) => rowData.task_planner_label,
    onlyData: true,
  },
];
