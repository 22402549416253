import BasicDialog from "../../base/BasicDialog";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import PostForm from "../../form/PostForm/PostForm";
import useDialog from "../../../hooks/useDialog";
import OwnerDetailsDialog from "../OwnerDetailsDialog/OwnerDetailsDialog";
import useCorrespondenceService from "../../../services/correspondenceService";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

const CreatePostDialog = (props) => {
  const { t } = useTranslation();
  const { createNewPost, updatePostData } = useCorrespondenceService();
  const createPostFn = useAsyncFn(createNewPost);
  const updatePostFn = useAsyncFn(updatePostData);
  const snackbarAlert = useSnackbarAlert();

  const onSubmit = (newPostData) => {

    if (newPostData.id) {
      updatePostFn
        .execute(newPostData.id, newPostData)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.post_updated"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_updating_post")
          );
        });
    } else {
      createPostFn
        .execute(newPostData)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.post_created"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_creating_new_post"));
        });
    };
  }
  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="full"
      backdropColor={props.backdropColor}
      title = {
        props.formValue && props.formValue?.id
          ? props.postIn
            ? t("dialog.post_dialog.edit_post_in")
            : t("dialog.post_dialog.edit_post_out")
          : props.postIn
            ? t("dialog.post_dialog.add_post_in")
            : t("dialog.post_dialog.add_post_out")
      }
    >
      <PostForm
        onSubmit={onSubmit}
        loadingSubmitButton={createPostFn.loading}
        readOnly={props.readOnly}
        postIn={props.postIn}
        formValue={props.formValue}
        postId={props.postId}
        autocompleteData={props.autocompleteData}
      />
    </BasicDialog>
  );
};

CreatePostDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  postIn: PropTypes.bool.isRequired,
  formValue: PropTypes.object
};

CreatePostDialog.defaultProps = {
  readOnly: false,
};

export default CreatePostDialog;
