import { useState, useEffect, useMemo, useRef } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync } from "../../../hooks/useAsync";

import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

import useUserService from "../../../services/userService";

import UserTenantDialog from "../../dialog/UserTenantDialog";
import useDialogWithId from "../../../hooks/useDialogWithId";

import RoleDescriptionDialog from "../../dialog/RoleDescriptionDialog";
import useDialog from "../../../hooks/useDialog";

import UniversalToolBarWithDialogs from "../../bar/UniversalToolBarWithDialogs";

import TableService from "../../../services/tableService";
import UserTenantTable from "../../table/UserTenantTable";
import { exportUserTenantListToFileUrl } from "../../../helpers/apiUrls";

import { isSearchParamsObjectEmpty } from "../../../helpers/methods";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {
  centerVericalAlignIconStyle,
  overflowButtonStyle,
} from "../../../helpers/styles";

import {
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const UserTenantTableWithToolBar = (props) => {
  const { t } = useTranslation();

  const tableConfigForUserPreferencesDialog = useMemo(
    () => ({
      name: props.tableName,
      config: props.tableConfig,
    }),
    [props.tableName, props.tableConfig]
  );

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(
    props.tableFilterPrefix,
    undefined,
    props.defaultPageSize
  );

  const [
    openRoleDescriptionDialog,
    onOpenRoleDescriptionDialog,
    onCloseRoleDescriptionDialog,
  ] = useDialog();

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);
  const [userTenantsSearchParams, setUserTenantsSearchParams] = useState(
    getSearchParamsByFilterPrefix(props.tableFilterPrefix)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      props.tableFilterPrefix,
      userTenantsSearchParams,
      setUserTenantsSearchParams
    );
  }, [searchParams]);

  const { getUserTenantList, getUserTenantFilteringData } = useUserService();

  const userTenantData = useAsync(
    () =>
      getUserTenantList({
        ...userTenantsSearchParams,
        ...props.userTenantDataSearchParams,
        page_size: userTenantsSearchParams.page_size || props.defaultPageSize,
      }),

    [userTenantsSearchParams]
  );
  const userTenantFilteringData = useAsync(() =>
    getUserTenantFilteringData({ ...props.userTenantFilteringDataSearchParams })
  );

  const [userTenantDataLocaly, setUserTenantDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (userTenantData.loading) {
      return;
    }
    setUserTenantDataLocaly(userTenantData.value.results);
    setCountRecords(userTenantData.value.count);
  }, [userTenantData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setUserTenantDataLocaly(undefined);
    userTenantData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setUserTenantDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const userTenantExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      props.tableConfig,
      hiddenColumnsForTables?.[props.tableName],
      columnsOrdersForTables?.[props.tableName]
    );
  }, [
    hiddenColumnsForTables?.[props.tableName],
    columnsOrdersForTables?.[props.tableName],
    props.tableConfig,
  ]);

  const [
    openUserTenantDialog,
    userTenantId,
    onOpenUserTenantDialog,
    onCloseUserTenantDialog,
  ] = useDialogWithId();

  const userTenantDialogModeRef = useRef();
  const handleOpenUserTenantDialogInCreateMode = () => {
    userTenantDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenUserTenantDialog(undefined);
  };

  const handleOpenUserTenantDialogInEditMode = (userTenantId) => {
    userTenantDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenUserTenantDialog(userTenantId);
  };

  const getUserTenantDialogCallback = () => {
    if (
      userTenantDialogModeRef.current === DIALOG_CREATE_MODE &&
      !isSearchParamsObjectEmpty(userTenantsSearchParams)
    ) {
      setSearchParams(clearSearchParamsByFilterPrefix(props.tableFilterPrefix));
    } else {
      userTenantData.refetch();
    }
  };

  const isLoading =
    userTenantDataLocaly === undefined || userTenantFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  const getExtraButtonListForToolBar = () => {
    let extraButtonList = [
      <Button
        color="success"
        onClick={handleOpenUserTenantDialogInCreateMode}
        variant="contained"
        size="small"
        startIcon={
          <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
        }
        sx={overflowButtonStyle}
        fullWidth
      >
        {props.addUserTenantButtonTitle}
      </Button>,
    ];

    if (props.showRoleDescriptionDialog) {
      extraButtonList.push(
        <Button
          color="primary"
          onClick={onOpenRoleDescriptionDialog}
          variant="contained"
          size="small"
          startIcon={<InfoOutlinedIcon sx={centerVericalAlignIconStyle} />}
          sx={overflowButtonStyle}
          fullWidth
        >
          {t("page.admin_users.roles")}
        </Button>
      );
    }

    return extraButtonList;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <UniversalToolBarWithDialogs
          pageName={props.pageName}
          showOpenCloseFilterButton={false}
          tableConfig={tableConfigForUserPreferencesDialog}
          filteringData={userTenantFilteringData}
          refreshTable={refreshTable}
          onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
          resetPageNumber={resetPageNumber}
          hiddenColumnsForTables={hiddenColumnsForTables}
          setHiddenColumnsForTables={setHiddenColumnsForTables}
          columnsOrdersForTables={columnsOrdersForTables}
          setColumnsOrdersForTables={setColumnsOrdersForTables}
          filterPrefix={props.tableFilterPrefix}
          extraButtonList={getExtraButtonListForToolBar()}
        />
      </Grid>
      <Grid item xs={12}>
        {hiddenColumnsForTables && (
          <UserTenantTable
            showCleanFilterIcon={true}
            style={{ maxHeight: "60vh" }}
            showFilters={true}
            data={userTenantDataLocaly}
            filteringData={userTenantFilteringData}
            filterPrefix={props.tableFilterPrefix}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onClickEdit={handleOpenUserTenantDialogInEditMode}
            resetPageNumber={resetPageNumber}
            tableConfig={props.tableConfig}
            hiddenColumns={hiddenColumnsForTables[props.tableName]}
            columnsOrders={columnsOrdersForTables[props.tableName]}
            showExportToFileButton={true}
            exportToFileUrl={exportUserTenantListToFileUrl}
            exportToFileSearchParams={{
              ...userTenantsSearchParams,
              ...props.userTenantDataSearchParams,
            }}
            exportToFileHeaders={userTenantExportHeaders}
            exportToFileFileName={props.exportToFileFileName}
            initialExpandedFilterHeader={props.initialExpandedFilterHeader}
          />
        )}
      </Grid>
      {openUserTenantDialog && (
        <UserTenantDialog
          open={openUserTenantDialog}
          onClose={onCloseUserTenantDialog}
          onSubmitCallback={getUserTenantDialogCallback}
          userTenantId={userTenantId}
          dialogMode={userTenantDialogModeRef.current}
          fieldsToSave={props.userTenantDialogFieldsToSave}
          hiddenFields={props.userTenantDialogHiddenFields}
          readOnlyFields={props.userTenantDialogReadOnlyFields}
          isForRR={props.pageName === "rr_admin_page"}
        />
      )}
      {openRoleDescriptionDialog && (
        <RoleDescriptionDialog
          open={openRoleDescriptionDialog}
          onClose={onCloseRoleDescriptionDialog}
        />
      )}
    </Grid>
  );
};

UserTenantTableWithToolBar.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
  tableName: PropTypes.string,
  tableConfig: PropTypes.string,
  tableFilterPrefix: PropTypes.string,
  userTenantDataSearchParams: PropTypes.string,
  userTenantFilteringDataSearchParams: PropTypes.string,
  addUserTenantButtonTitle: PropTypes.string,
  exportToFileFileName: PropTypes.string,
  userTenantDialogFieldsToSave: PropTypes.array,
  userTenantDialogHiddenFields: PropTypes.array,
  userTenantDialogReadOnlyFields: PropTypes.array,
  showRoleDescriptionDialog: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  initialExpandedFilterHeader: PropTypes.bool,
};

UserTenantTableWithToolBar.defaultProps = {
  showRoleDescriptionDialog: true,
  defaultPageSize: 10,
};

export default UserTenantTableWithToolBar;
