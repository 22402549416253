import { useState, useEffect, useMemo, useCallback } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";

import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";

import useUserService from "../../../../services/userService";

import useDialog from "../../../../hooks/useDialog";

import UniversalToolBarWithDialogs from "../../../bar/UniversalToolBarWithDialogs";

import TableService from "../../../../services/tableService";
import UserEmailNotificationsTable from "../../../table/UserEmailNotificationsTable/UserEmailNotificationTable";
import { exportUserEmailNotificationsToFileUrl } from "../../../../helpers/apiUrls";

import { isSearchParamsObjectEmpty } from "../../../../helpers/methods";
import {
  overflowButtonStyle,
  centerVericalAlignIconStyle,
} from "../../../../helpers/styles";
import EditAttributesOutlinedIcon from "@mui/icons-material/EditAttributesOutlined";

import UserMailNotificationsDialog from "../../../dialog/UserMailNotificationsDialog";

import {
  USER_EMAIL_NOTIFICATIONS_TABLE_FILTER_PREFIX,
  USER_EMAIL_NOTIFICATIONS_TABLE_NAME,
  USER_EMAIL_NOTIFICATIONS_TABLE_CONFIG,
} from "./TableConfig";

const tableConfig = {
  name: USER_EMAIL_NOTIFICATIONS_TABLE_NAME,
  config: USER_EMAIL_NOTIFICATIONS_TABLE_CONFIG,
};

const RRAdminUserNotificationsTab = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(
    USER_EMAIL_NOTIFICATIONS_TABLE_FILTER_PREFIX,
    undefined,
    100
  );

  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);
  const [
    userEmailNotificationsSearchParams,
    setUserEmailNotificationsSearchParams,
  ] = useState(
    getSearchParamsByFilterPrefix(USER_EMAIL_NOTIFICATIONS_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      USER_EMAIL_NOTIFICATIONS_TABLE_FILTER_PREFIX,
      userEmailNotificationsSearchParams,
      setUserEmailNotificationsSearchParams
    );
  }, [searchParams]);

  const { getUserEmailNotifications, getUserEmailNotificationsFilteringData } =
    useUserService();

  const userEmailNotificationsData = useAsync(
    () =>
      getUserEmailNotifications({
        ...userEmailNotificationsSearchParams,
        is_rr_visibility: true,
        page_size: userEmailNotificationsSearchParams.page_size || 100,
      }),
    [userEmailNotificationsSearchParams]
  );

  const userEmailNotificationsFilteringData = useAsync(() =>
    getUserEmailNotificationsFilteringData({ tenant_rr_visibility: true })
  );

  const [
    userEmailNotificationsDataLocaly,
    setUserEmailNotificationsDataLocaly,
  ] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (userEmailNotificationsData.loading) {
      return;
    }
    setUserEmailNotificationsDataLocaly(
      userEmailNotificationsData.value.results
    );
    setCountRecords(userEmailNotificationsData.value.count);
  }, [userEmailNotificationsData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setUserEmailNotificationsDataLocaly(undefined);
    userEmailNotificationsData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setUserEmailNotificationsDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const userEmailNotificationsExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      USER_EMAIL_NOTIFICATIONS_TABLE_CONFIG,
      hiddenColumnsForTables?.[USER_EMAIL_NOTIFICATIONS_TABLE_NAME],
      columnsOrdersForTables?.[USER_EMAIL_NOTIFICATIONS_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[USER_EMAIL_NOTIFICATIONS_TABLE_NAME],
    columnsOrdersForTables?.[USER_EMAIL_NOTIFICATIONS_TABLE_NAME],
    USER_EMAIL_NOTIFICATIONS_TABLE_CONFIG,
  ]);

  const handleClickCheck = useCallback((id) => {
    setUserEmailNotificationsDataLocaly((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      selectedRow.selected = !selectedRow.selected;
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, []);

  const [checkedAll, setCheckedAll] = useState(false);
  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setUserEmailNotificationsDataLocaly((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );

  const getSeletedUserIds = () =>
    userEmailNotificationsDataLocaly
      .filter((user) => user.selected)
      .map((user) => user.id);

  const [
    openEditEmailNotificationsDialog,
    onOpenEmailNotificationsDialog,
    onCloseEmailNotificationsDialog,
  ] = useDialog();

  const handleOpenEditEmailNotificationsDialog = () => {
    if (getSeletedUserIds().length === 0) return;
    onOpenEmailNotificationsDialog();
  };

  const isLoading =
    userEmailNotificationsDataLocaly === undefined ||
    userEmailNotificationsFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <UniversalToolBarWithDialogs
          pageName={props.pageName}
          showOpenCloseFilterButton={false}
          tableConfig={tableConfig}
          filteringData={userEmailNotificationsFilteringData}
          refreshTable={refreshTable}
          onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
          resetPageNumber={resetPageNumber}
          hiddenColumnsForTables={hiddenColumnsForTables}
          setHiddenColumnsForTables={setHiddenColumnsForTables}
          columnsOrdersForTables={columnsOrdersForTables}
          setColumnsOrdersForTables={setColumnsOrdersForTables}
          filterPrefix={USER_EMAIL_NOTIFICATIONS_TABLE_FILTER_PREFIX}
          extraButtonList={[
            <Button
              color="success"
              onClick={handleOpenEditEmailNotificationsDialog}
              variant="contained"
              size="small"
              startIcon={
                <EditAttributesOutlinedIcon sx={centerVericalAlignIconStyle} />
              }
              sx={overflowButtonStyle}
              fullWidth
            >
              {t("dialog.user_email_notifications.edit_notifications")}
            </Button>,
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        {hiddenColumnsForTables && (
          <UserEmailNotificationsTable
            style={{ maxHeight: "60vh" }}
            onClickCheck={handleClickCheck}
            checkedAll={checkedAll}
            onClickCheckAll={handleClickCheckAll}
            showCleanFilterIcon={true}
            showFilters={true}
            showCheckbox={true}
            initialExpandedFilterHeader={true}
            data={userEmailNotificationsDataLocaly}
            filteringData={userEmailNotificationsFilteringData}
            filterPrefix={USER_EMAIL_NOTIFICATIONS_TABLE_FILTER_PREFIX}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            resetPageNumber={resetPageNumber}
            tableConfig={USER_EMAIL_NOTIFICATIONS_TABLE_CONFIG}
            hiddenColumns={
              hiddenColumnsForTables[USER_EMAIL_NOTIFICATIONS_TABLE_NAME]
            }
            columnsOrders={
              columnsOrdersForTables[USER_EMAIL_NOTIFICATIONS_TABLE_NAME]
            }
            showExportToFileButton={true}
            exportToFileUrl={exportUserEmailNotificationsToFileUrl}
            exportToFileSearchParams={{
              ...userEmailNotificationsSearchParams,
              is_rr_visibility: true,
            }}
            exportToFileHeaders={userEmailNotificationsExportHeaders}
            exportToFileFileName={`${t("tabs.rr_admin.users")}.xlsx`}
          />
        )}
      </Grid>
      {openEditEmailNotificationsDialog && (
        <UserMailNotificationsDialog
          open={openEditEmailNotificationsDialog}
          onClose={onCloseEmailNotificationsDialog}
          userIds={getSeletedUserIds()}
          onSubmit={userEmailNotificationsData.refetch}
        />
      )}
    </Grid>
  );
};

RRAdminUserNotificationsTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

RRAdminUserNotificationsTab.defaultProps = {};

export default RRAdminUserNotificationsTab;
