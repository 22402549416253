import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField/trueFalseSelectField";

import useTicketService from "../../../services/ticketService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import QuestionForQuestionList from "../../other/QuestionsForQuestionList/QuestionForQuestionList";

import {
  getErrorMsg,
  isEmptyValue,
  isArraysEquals,
} from "../../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const REQUIRED_FIELDS = [
  "std_ticket_area",
  "std_ticket_name",
  "std_ticket_name_eng",
  "ticket_category",
  "std_ticket_external",
  "std_ticket_paid",
  "std_ticket_active",
  "std_ticket_is_end_list",
];

const FIELDS_TO_SAVE = [
  ...REQUIRED_FIELDS,
  "std_ticket_1question",
  "std_orders",
];

const StdTicketDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const dialogTitle = useMemo(() => {
    if (props.title) return props.title;

    return props.dialogMode === DIALOG_CREATE_MODE
      ? t("dialog.std_ticket.add_new_std_ticket")
      : props.dialogMode === DIALOG_EDIT_MODE
      ? t("dialog.std_ticket.edit_std_ticket")
      : t("dialog.std_ticket.std_ticket_details");
  }, [props.dialogMode, props.title]);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const fieldsToSave = useMemo(
    () => props.fieldsToSave || FIELDS_TO_SAVE,
    [props.fieldsToSave]
  );
  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();

  const {
    getStdTicketAdminDetailsData,
    getStdTicketAdminFilteringData,
    createStdTicket,
    updateStdTicket,
  } = useTicketService();

  const stdTicketInitialData = useAsync(() => {
    if (props.dialogMode === DIALOG_CREATE_MODE) {
      return Promise.resolve({
        std_ticket_active: true,
        std_orders: [],
        std_ticket_area: [],
      });
    }

    return getStdTicketAdminDetailsData(props.stdTicketId);
  }, [props.stdTicketId, props.dialogMode]);

  useEffect(() => {
    setFormValue(stdTicketInitialData.value);
  }, [stdTicketInitialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (fieldsToSave.includes(key)) {
        if (key === "std_ticket_area" || key === "std_orders") {
          if (!isArraysEquals(value, stdTicketInitialData.value[key])) {
            dataToSend[key] = value;
          }
          continue;
        }

        dataToSend[key] = value;
      }
    }
    return dataToSend;
  };

  const stdTicketFilteringData = useAsync(
    () =>
      getStdTicketAdminFilteringData({
        include_std_orders: true,
        include_std_ticket_1question_ids: true,
      }),
    []
  );

  const updateStdTicketFn = useAsyncFn(updateStdTicket);
  const addStdTicketFn = useAsyncFn(createStdTicket);
  const submitFn = useMemo(
    () =>
      props.dialogMode === DIALOG_CREATE_MODE
        ? addStdTicketFn
        : updateStdTicketFn,
    [props.dialogMode]
  );

  const handleSubmit = (dataToSend) => {
    submitFn
      .execute(dataToSend, props.stdTicketId)
      .then((res) => {
        const successMsg =
          props.dialogMode === DIALOG_CREATE_MODE
            ? t("snackbar_alert.std_ticket_added")
            : t("snackbar_alert.std_ticket_updated");
        snackbarAlert.openSuccessSnackbarAlert(successMsg);
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid =
    formValue &&
    REQUIRED_FIELDS.every((field) => !isEmptyValue(formValue[field]));

  const isLoading = formValue === undefined || stdTicketFilteringData.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        <Grid item xs={12} sm={6}>
          <AutocompleteField
            name="ticket_category"
            optionLabelKey={"tc_name"}
            value={formValue.ticket_category}
            options={stdTicketFilteringData.value.ticket_category}
            label={t("dialog.std_ticket.ticket_category")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            readOnly={isReadOnly}
            addNewValue={false}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutocompleteField
            name="std_ticket_area"
            optionLabelKey={"ta_area"}
            value={formValue.std_ticket_area}
            options={stdTicketFilteringData.value.std_ticket_area}
            label={t("dialog.std_ticket.std_ticket_area")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            multiple={true}
            addNewValue={false}
            readOnly={isReadOnly}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldFormControl
            name="std_ticket_name"
            required
            value={formValue.std_ticket_name}
            label={t("dialog.std_ticket.std_ticket_name")}
            readOnly={isReadOnly}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldFormControl
            name="std_ticket_name_eng"
            required
            value={formValue.std_ticket_name_eng}
            label={t("dialog.std_ticket.std_ticket_name_eng")}
            readOnly={isReadOnly}
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TrueFalseSelectField
            name={"std_ticket_paid"}
            label={t("dialog.std_ticket.std_ticket_paid")}
            value={formValue.std_ticket_paid}
            onChange={onChange}
            addEmptyOptions={false}
            valuesAsBool
            readOnly={isReadOnly}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TrueFalseSelectField
            name={"std_ticket_is_end_list"}
            label={t("dialog.std_ticket.std_ticket_is_end_list")}
            value={formValue.std_ticket_is_end_list}
            onChange={onChange}
            addEmptyOptions={false}
            valuesAsBool
            readOnly={isReadOnly}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TrueFalseSelectField
            name={"std_ticket_active"}
            label={t("dialog.std_ticket.std_ticket_active")}
            value={formValue.std_ticket_active}
            onChange={onChange}
            addEmptyOptions={false}
            valuesAsBool
            readOnly={isReadOnly}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TrueFalseSelectField
            name={"std_ticket_external"}
            label={t("dialog.std_ticket.std_ticket_external")}
            value={formValue.std_ticket_external}
            onChange={onChange}
            addEmptyOptions={false}
            valuesAsBool
            readOnly={isReadOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteField
            name="std_orders"
            optionLabelKey={"std_order_name"}
            value={formValue.std_orders}
            options={stdTicketFilteringData.value.std_orders}
            label={t("dialog.std_ticket.std_orders")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            multiple={true}
            addNewValue={false}
            readOnly={isReadOnly}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteField
            name="std_ticket_1question"
            value={formValue.std_ticket_1question}
            options={stdTicketFilteringData.value.std_ticket_1question}
            isObjectOption={true}
            optionLabelKey={"question_text"}
            label={t("dialog.std_ticket.std_ticket_1question")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <QuestionForQuestionList
            questionId={formValue.std_ticket_1question}
            onSumbitQuestion={stdTicketFilteringData.refetch}
          />
        </Grid>

        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={addStdTicketFn.loading || updateStdTicketFn.loading}
              disabled={!isFormValid}
              onClick={() => handleSubmit(prepareDataToSend())}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={true}
      title={dialogTitle}
      maxWidth="lg"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

StdTicketDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  stdTicketId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmitCallback: PropTypes.func,
  fieldsToSave: PropTypes.array,
};

StdTicketDialog.defaultProps = {
  open: false,
};

export default StdTicketDialog;
