import { Button, Grid } from "@mui/material";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useRcpService from "../../../services/rcpService";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import ScheduleTable from "../../table/ScheduleTable/ScheduleTable";
import { useEffect, useState, useRef } from "react";
import DateService from "../../../services/dateService";
import CreateScheduleDialog from "../../dialog/CreateScheduleDialog";
import RcpScheduleDialog from "../../dialog/RcpScheduleDialog";
import useDialog from "../../../hooks/useDialog";
import useDialogWithFormValue from "../../../hooks/useDialogWithFormValue";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../../helpers/styles";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import {
  isSearchParamsObjectEmpty,
  getErrorMsg,
} from "../../../helpers/methods";
import { RCP_ACTIVITIES } from "../../../helpers/constants";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

const SCHEDULE_TABLE_FILTER_PREFIX = "schedule_plan";

const SchedulePlan = () => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const { getRcpPlan, deleteRcpPlan, getRcpPlanFilteringData } =
    useRcpService();
  const {} = useSnackbarAlert();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(SCHEDULE_TABLE_FILTER_PREFIX);
  const { setNewItemSearchParamsIfAreChanged, getSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);

  const [schedulePlanSearchParams, setSchedulePlanSearchParams] = useState(
    getSearchParamsByFilterPrefix(SCHEDULE_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      SCHEDULE_TABLE_FILTER_PREFIX,
      schedulePlanSearchParams,
      setSchedulePlanSearchParams
    );
  }, [searchParams]);

  const rcpPlan = useAsync(
    () => getRcpPlan(schedulePlanSearchParams),
    [schedulePlanSearchParams]
  );

  const rcpFilteringData = useAsync(getRcpPlanFilteringData);

  const [rcpPlanData, setRcpPlanData] = useState();
  const [countRecords, setCountRecords] = useState();
  useEffect(() => {
    if (!rcpPlan.loading && rcpPlan.value.results) {
      let rcpPlanDataTemp;
      rcpPlanDataTemp = [];
      for (let rcpp of rcpPlan.value.results) {
        const isWorkActivity = rcpp.rcpplan_activity === RCP_ACTIVITIES.WORK;
        const rcpplan_date_start = new Date(rcpp.rcpplan_date_start);
        const rcpplan_date_stop = new Date(rcpp.rcpplan_date_stop);
        rcpp.day = rcpplan_date_start.toLocaleDateString();
        rcpp.time_start = isWorkActivity
          ? DateService.getHoursAndMinutesStringFromData(rcpplan_date_start)
          : "-";
        rcpp.time_stop = isWorkActivity
          ? DateService.getHoursAndMinutesStringFromData(rcpplan_date_stop)
          : "-";
        rcpPlanDataTemp.push(rcpp);
      }
      setCountRecords(rcpPlan.value.count);
      setRcpPlanData(rcpPlanDataTemp);
    }
  }, [rcpPlan.loading]);

  const formValueConverterForCreateScheduleDialog = (formValue) => {
    return {
      id: formValue.id,
      rcpplan_date_start: formValue.rcpplan_date_start,
      rcpplan_date_stop: formValue.rcpplan_date_stop,
      rcpplan_hours: formValue.rcpplan_hours,
      rcpplan_note: formValue.rcpplan_note,
      rcpplan_activity: formValue.rcpplan_activity,
      rcpplan_shift: formValue.rcpplan_shift,
      employees: formValue.employee.id,
      hour_start: formValue.time_start,
      hour_stop: formValue.time_stop,
    };
  };

  const [
    openCreateScheduleDialog,
    createScheduleDialogFormValue,
    handleOpenCreateScheduleDialog,
    handleCloseCreateScheduleDialog,
  ] = useDialogWithFormValue({
    formValueConverter: formValueConverterForCreateScheduleDialog,
  });

  const [
    openRcpScheduleDialog,
    onOpenRcpScheduleDialog,
    onCloseRcpScheduleDialog,
  ] = useDialog();

  const onCloseCreateScheduleDialog = (refresh) => {
    if (refresh) {
      rcpPlan.refetch();
    }
    handleCloseCreateScheduleDialog();
  };

  const deleteRcpPlanFn = useAsyncFn(deleteRcpPlan);
  const handleDeteleRcpPlan = (e, rcpPlanId) => {
    deleteRcpPlanFn
      .execute(rcpPlanId)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.rcp_plan_deleted")
        );
        setRcpPlanData((prev) => prev.filter((rcp) => rcp.id !== rcpPlanId));
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_delete_rcp_plan")
          )
        );
      });
  };

  const createScheduleDialogModeRef = useRef("create_mass");
  const handleOpenCreateScheduleDialogInEditMode = (e, rcpPlanId) => {
    const rcpPlanToEdit = rcpPlanData.find(
      (rcpPlan) => rcpPlan.id === rcpPlanId
    );
    createScheduleDialogModeRef.current = "edit";
    handleOpenCreateScheduleDialog(rcpPlanToEdit);
  };

  const handleOpenCreateScheduleDialogInCreateMassMode = () => {
    createScheduleDialogModeRef.current = "create_mass";
    handleOpenCreateScheduleDialog();
  };

  const isLoading = rcpFilteringData.loading || rcpPlanData === undefined;

  return (
    <LoaderWrapper showLoader={isLoading}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} sm={4} textAlign={"left"}>
              <Button
                variant="contained"
                size="small"
                startIcon={
                  <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
                }
                onClick={handleOpenCreateScheduleDialogInCreateMassMode}
              >
                {t("other.schedule_plan.mass_adding_hours")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} textAlign={"right"}>
              <Button
                variant="contained"
                size="small"
                startIcon={
                  <CalendarMonthIcon sx={centerVericalAlignIconStyle} />
                }
                onClick={onOpenRcpScheduleDialog}
              >
                {t("other.schedule_plan.display_schedule")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ScheduleTable
            scheduleData={rcpPlanData}
            filteringData={rcpFilteringData.value}
            filterPrefix={SCHEDULE_TABLE_FILTER_PREFIX}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            initialExpandedFilterHeader={
              !isSearchParamsObjectEmpty(schedulePlanSearchParams)
            }
            resetPageNumber={resetPageNumber}
            onClickDelete={handleDeteleRcpPlan}
            onClickEdit={handleOpenCreateScheduleDialogInEditMode}
          />
        </Grid>
      </Grid>
      {openCreateScheduleDialog && (
        <CreateScheduleDialog
          open={openCreateScheduleDialog}
          onClose={onCloseCreateScheduleDialog}
          initialFormValue={createScheduleDialogFormValue}
          dialogMode={createScheduleDialogModeRef.current}
        />
      )}
      {openRcpScheduleDialog && (
        <RcpScheduleDialog
          open={openRcpScheduleDialog}
          onClose={onCloseRcpScheduleDialog}
        />
      )}
    </LoaderWrapper>
  );
};

export default SchedulePlan;
