import PropTypes from "prop-types";
import i18n from "../../../i18n";
import BaseTable from "../../base/BaseTable/BaseTable";
import TableService from "../../../services/tableService";
import { AUTOCOMPLETE_FIELD_TYPE, DATETIME_TO_DATE_RANGE_FIELD_TYPE, NUMERIC_RANGE_FIELD_TYPE } from "../../../helpers/constants";
import DateService from "../../../services/dateService";
import { getTranslatedTransactionList } from "../../../helpers/methods";



export const STOCK_TRANSACTION_TABLE_NAME = "StockTransaction";
export const STOCK_TRANSACTION_TABLE_FIELDS_CONFIG = [
  {
    name: "warehouse",
    getValueCallback: (rowData) => rowData.warehouse,
    label: i18n.t("table.stock_warehouse_table.warehouse_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "transaction_kind",
    getValueCallback: (rowData) =>  i18n.t(`tabs.stock_transaction_related_tables.${rowData.transaction_kind}`),
    label: i18n.t("tabs.stock_transaction_related_tables.transaction"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: getTranslatedTransactionList(filteringData?.value?.kinds),
      isObjectOption: true,
      optionLabelKey: "name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "transaction_price",
    getValueCallback: (rowData) => rowData.transaction_price,
    label: i18n.t("table.transaction_table.transaction_price"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "transaction_amount",
    getValueCallback: (rowData) => rowData.transaction_amount,
    label: i18n.t("table.transaction_table.transaction_amount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "transaction_value",
    getValueCallback: (rowData) => rowData.transaction_value,
    label: i18n.t("table.transaction_table.transaction_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) => DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.transaction_table.transaction_created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: () => undefined,
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("tabs.stock_transaction_related_tables.transaction_executor"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.users,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "fv_nr",
    getValueCallback: (rowData) => rowData.fv_nr,
    label: i18n.t("tabs.stock_transaction_related_tables.invoice_number"),
    filterType: undefined,
  },
  {
    name: "fv_date",
    getValueCallback: (rowData) =>  DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.fv_date),
    label: i18n.t("tabs.stock_transaction_related_tables.invoice_date"),
    filterType: undefined,
  },
  {
    name: "fv_payer",
    getValueCallback: (rowData) => rowData.fv_payer,
    label: i18n.t("tabs.stock_transaction_related_tables.invoice_company"),
    filterType: undefined,
  },
];

const StockTransactionTable = (props) => {

  return (
    <>
      <BaseTable
        headers={TableService.getHeaderLables(
          STOCK_TRANSACTION_TABLE_FIELDS_CONFIG,
          props.hiddenColumns
        )}
        showNoRecordsPaper={props.showNoRecordsPaper}
        style={props.style}
        rows={TableService.getPreparedDataForBaseTable(
          STOCK_TRANSACTION_TABLE_FIELDS_CONFIG,
          props.hiddenColumns,
        )}
      />
    </>
  );
};

StockTransactionTable.propTypes = {
  style: PropTypes.object,
  hiddenColumns: PropTypes.array,
  showNoRecordsPaper: PropTypes.bool,
};

StockTransactionTable.defaultProps = {
  hiddenColumns: [],
  showNoRecordsPaper: true,
};

export default StockTransactionTable;
