import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import FilterTable from "../FilterTable";
import TableService from "../../../services/tableService";
import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import BaseBox from "../../base/BaseBox/baseBox";
import useDialog from "../../../hooks/useDialog";
import { NOTIFICATION_OUT_TABLE_FIELDS_CONFIG, NOTIFICATION_TABLE_FIELDS_CONFIG, NOTIFICATION_TABLE_NAME } from "./TablesConfig";
import PostRecipientsDialog from "../../dialog/PostRecipientsDialog";
import { useEmptyArrayMemo } from "../../../hooks/useEmptyArrayMemo";
import { overflowButtonStyle } from "../../../helpers/styles";
import EditableTable from "../EditableTable/EditableTable";

const PostNotificationTable = (props) => {
  const { t } = useTranslation();
  const [openPostUserDialog, onOpenPostUserDialog, onClosePostUserDialog] = useDialog();
  const { emptyArrayMemo } = useEmptyArrayMemo()

  const mapDataToDisplay = (recipients, departmentOptions, userDepartmentOptions) => {
    return recipients.map(recipient => {
      const departmentName = departmentOptions.find(opt => opt.id === recipient.postrec_department)?.department_name || recipient.postrec_department;
      const userDepartmentName = userDepartmentOptions.find(opt => opt.id === recipient.postrec_person)?.full_name || recipient.postrec_person;
      const userDepartmentId = userDepartmentOptions.find(opt => opt.id === recipient.postrec_person)?.id || recipient.id;

      return {
        ...recipient,
        id: userDepartmentId,
        postrec_department: departmentName,
        postrec_person: userDepartmentName,
      };
    });
  };

  const recipientsToDisplay = useMemo(() => {
    return mapDataToDisplay(props.recipients, props.autocompleteData.department, props.autocompleteData.user_department);
  }, [props.recipients, props.autocompleteData]);

  const handleDeleteRecipient = (rowData) => {
    const index = props.recipients.findIndex(recipient => recipient.postrec_person === rowData.id);
    if (index !== -1) {
      props.setRecipients(prevRecipients => {
        return prevRecipients.filter(recipient => recipient.postrec_person !== rowData.id);
      });
    }
  };


  const fieldsConfig = props.postIn ? NOTIFICATION_TABLE_FIELDS_CONFIG : NOTIFICATION_OUT_TABLE_FIELDS_CONFIG;

  const notificationTableData = useMemo(
    () => {
      if (!Array.isArray(recipientsToDisplay) || recipientsToDisplay.length === 0) {
        return [];
      }
      return TableService.getPreparedDataForCollapsibleTable(
        fieldsConfig,
        recipientsToDisplay,
        emptyArrayMemo,
        emptyArrayMemo,
        handleDeleteRecipient,
      );
    },
    [props.postIn, recipientsToDisplay]
  );

  return (
    <BaseBox>
      <Grid container spacing={2}>
        <Grid item xs={9}></Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onOpenPostUserDialog}
            sx={overflowButtonStyle}
          >
            {(props.postIn ? t("dialog.post_dialog.add_post_user") : t("dialog.post_dialog.add_post_out_user")) + " *"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <EditableTable
            data={notificationTableData || []}
            tableConfig={fieldsConfig}
            filteringData={props.autocompleteData}
            withPagination={false}
            showCheckbox={false}
            showFilters={false}
            showCounterRecords={false}
            showCleanFilterIcon={false}
            showCount={false}
            selectedItemId={false}
          />
        </Grid>
        {openPostUserDialog && (
          <PostRecipientsDialog
            open={openPostUserDialog}
            onClose={onClosePostUserDialog}
            postIn={props.postIn}
            autocompleteData={props.autocompleteData}
            onChangeAutocompleteFieldWithObjectOptions={props.onChangeAutocompleteFieldWithObjectOptions}
            handleAutocompleteChange={props.handleAutocompleteChange}
            onChange={props.onChange}
            onAddRecipient={props.handleAddRecipient}
            existingRecipients={props.recipients}
          />
        )}
      </Grid>
    </BaseBox>
  );
};

PostNotificationTable.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  postIn: PropTypes.bool.isRequired,
};

PostNotificationTable.defaultProps = {
  readOnly: false,
};

export default PostNotificationTable;