import { useTranslation } from "react-i18next";
import { useCallback, useEffect,  useState } from "react";
import useOfferTableService from "../services/offerTableService";
import { getFieldsFromObject } from "../helpers/methods";
import useOfferService from "../services/offerService";
import { useAsync } from "./useAsync";
import { useAuth } from "../context/auth";
import { useSnackbarAlert } from "../context/snackbarAlert";
import { OFFER_SERVICES_TABLE_FIELDS_CONFIG } from "../components/dialog/OfferConfiguratorDialog/DialogTablesConfig";

const useOfferServiceData = (
  offerId,
  setHasChangesOnPage,
  isReadOnly
) => {
  const { t } = useTranslation();
 
  const [serviceDataLocal, setServiceDataLocal] = useState(undefined)

  const [requiredServiceofferFields, setRequiredServiceofferFields] = useState([])

  const [serviceSummaryRow, setServiceSummaryRow] = useState()

  const [isOfferServiceTableValid, setIsOfferServiceTableValid] = useState(true)

  const {user} = useAuth()

  const snackbarAlert = useSnackbarAlert();

  const {
    getOfferServicesData
  } = useOfferService ()

  const {
    summaryElementsFields,
    getFieldsNameToSave,
    calculateElement,
    calculateMarginProcOnMarginPlnInput,
    calculateDiscountProcOnDiscountPlnInput,
    isTableValid,
    setServiceOfferDataAfterChange
} = useOfferTableService();

const offerServicesList = useAsync(
    () =>  getOfferServicesData(offerId),
    [offerId]
  );


  useEffect(() => {
    if (serviceDataLocal && !isReadOnly) {
        setIsOfferServiceTableValid(isTableValid(serviceDataLocal))
    }
}, [serviceDataLocal, isReadOnly]);


useEffect(() => {
    if (offerServicesList.loading) {
      return;
    }
  if (offerServicesList.value) {
      setServiceDataLocal(offerServicesList.value)
      setServiceSummaryRow(summaryElementsFields(offerServicesList.value, 'seof'))
  }
}, [offerServicesList.loading]);

useEffect(() => {
    setRequiredServiceofferFields(OFFER_SERVICES_TABLE_FIELDS_CONFIG.filter((config) => config.required === true).map((c) => c.name))
}, [OFFER_SERVICES_TABLE_FIELDS_CONFIG]);

const onChangeServiceAutocompleteField = useCallback((
    e,
    value_object,
    value_key,
    state_value_name,
    index) => {
    let tempDataLocal = serviceDataLocal
    let element = tempDataLocal[index]
    element.isEdited = true
    if (state_value_name === 'service') {
        element = setServiceOfferDataAfterChange(element, value_object)
    } else {
        element[state_value_name] = value_object[value_key]
    }
    onCalculateElementLocal(element, index, tempDataLocal)
}, [serviceDataLocal, requiredServiceofferFields])

const onChangeServiceBySelectDialog = useCallback((index, service) =>{
    let tempDataLocal = serviceDataLocal
    let element = tempDataLocal[index]
    element.isEdited = true
    element = setServiceOfferDataAfterChange(element, service)
    onCalculateElementLocal(element, index, tempDataLocal)

},[serviceDataLocal, requiredServiceofferFields])

const onCalculateElementLocal = (element, index, tempDataLocal) =>{
    element = calculateElement(element, 'seof', requiredServiceofferFields)
    tempDataLocal[index] = { ...element }
    setServiceSummaryRow(summaryElementsFields(tempDataLocal, 'seof'))
    setServiceDataLocal([...tempDataLocal])
    setHasChangesOnPage(true)
}

const onChangeServiceTableData = useCallback((name, value, idx) => {
    let tempDataLocal = serviceDataLocal
    let element = tempDataLocal[idx]
    element[name] = value
    element.isEdited = true

    let calulate_margin_pln = true
    let calulate_discount_pln = true

    if (name === 'seof_margin_pln') {
        element.seof_margin_proc = calculateMarginProcOnMarginPlnInput(element.seof_price, element.seof_margin_pln)
        calulate_margin_pln = false
    }
    else if (name === 'seof_discount_pln'){
        element.seof_discount_proc = calculateDiscountProcOnDiscountPlnInput(element.seof_price_with_margin, element.seof_discount_pln)
        calulate_discount_pln = false
    }
    element = calculateElement(element, 'seof', requiredServiceofferFields, calulate_margin_pln, calulate_discount_pln)
    tempDataLocal[idx] = { ...element }

    setServiceSummaryRow(summaryElementsFields(tempDataLocal, 'seof'))
    setServiceDataLocal([...tempDataLocal])
    setHasChangesOnPage(true)
}, [serviceDataLocal, requiredServiceofferFields])

const onHandleServiceAddEmptyRow = useCallback(() => {
    let tempDataLocal = [...serviceDataLocal]
    tempDataLocal.push({ isEdited: true, editable: true,  isValid: false })
    setServiceDataLocal([...tempDataLocal])
    setServiceSummaryRow(summaryElementsFields(tempDataLocal, 'seof'))
    setHasChangesOnPage(true)
}, [serviceDataLocal])

const getServiceOffersToSend = () => {
    let serviceOfferDataToSend = []
    if (serviceDataLocal) {
        for (let offerServiceItem of serviceDataLocal) {
            if (offerServiceItem.isEdited || offerServiceItem.isDeleted) {
                serviceOfferDataToSend.push(prepareElementDataToSave(offerServiceItem));
            }
        }
    }
    return serviceOfferDataToSend
}

const prepareElementDataToSave = (element) => {
    return getFieldsFromObject(element, getFieldsNameToSave('seof'));
};


const handleRemoveOfferFromService = useCallback((rowIndex) => {
    let tempDataLocal = serviceDataLocal
    let element = tempDataLocal[rowIndex]
    
    if (element.id === undefined) {
        tempDataLocal.splice(rowIndex, 1)
    } else if  (user.is_k || user.user_id === element.seof_user){
            element.isDeleted = true
            tempDataLocal[rowIndex] = { ...element }
    } else{
        snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.you_are_not_allowed_to_delete_row")
          );
    }
    setServiceSummaryRow(summaryElementsFields(tempDataLocal, 'seof'))
    setServiceDataLocal([...tempDataLocal])
    setHasChangesOnPage(true)
}, [serviceDataLocal])



  return {
    offerServiceData : serviceDataLocal,
    setOfferServiceData : setServiceDataLocal,
    offerServiceSummaryRow : serviceSummaryRow,
    onChangeServiceAutocompleteField,
    onChangeServiceBySelectDialog,
    onChangeServiceTableData,
    onHandleServiceAddEmptyRow,
    getServiceOffersToSend,
    handleRemoveOfferFromService,
    isOfferServiceTableValid,
    isOfferServiceLoading : offerServicesList.loading,
    refetchOfferServiceData : offerServicesList.refetch
  };
};

export default useOfferServiceData;
