import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputMask from "react-input-mask";
import { TextField } from "@mui/material";

export default function MaskedTextFieldForm(props) {

    const handleChange = (e) => {
        props.onChange(e);
      };

    return (
        <InputMask
            mask= {props.mask}
            disabled={props.disabled}
            onChange={handleChange}
            value={props.value}
            maskChar="_"
        >
            {() =>
                <FormControl
                    fullWidth={true}
                    variant={"outlined"}
                >
                    <TextField
                        name={props.name}
                        label={props.label}
                        onChange={handleChange}
                        size={"small"}
                        required={props.required}
                        helperText={props.showHelperText ? props.helperText : undefined }
                        FormHelperTextProps={{ sx: { color: 'red' } }}
                    />
                </FormControl>}
        </InputMask>
    );
}

MaskedTextFieldForm.propTypes = {
    mask : PropTypes.string,
    value : PropTypes.string,
    maskChar : PropTypes.string,
    disabled : PropTypes.bool,
    name : PropTypes.string,
    label : PropTypes.string,
    showHelperText : PropTypes.bool,
    required : PropTypes.bool,
    FormHelperTextProps : PropTypes.object,
    helperText : PropTypes.string
};

MaskedTextFieldForm.defaultProps = {
    maskChar :"_",
    disabled : false,
    showHelperText : false,
    required : false
};
