import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../i18n";
import PropTypes from "prop-types";
import GridDrawer from "../GridDrawer";
import TicketDrawerContent from "./UnivervalDetailsDrawerContents/TicketDrawerContent";
import OfferDrawerContent from "./UnivervalDetailsDrawerContents/OfferDrawerContent/OfferDrawerContent";
import OrderDrawerContent from "./UnivervalDetailsDrawerContents/OrderDrawerContent/OrderDrawerContent";
import TaskDrawerContent from "./UnivervalDetailsDrawerContents/TaskDrawerContent";
import FeedbackDrawerContent from "./UnivervalDetailsDrawerContents/FeedbackDrawerContent";
import { DETAILS_DRAWER_WIDTH } from "../../../helpers/constants";
import OwnerDrawerContent from "./UnivervalDetailsDrawerContents/OwnerDrawerContent";
import PartDrawerContent from "./UnivervalDetailsDrawerContents/PartDrawerContent";
import PostDrawerContent from "./UnivervalDetailsDrawerContents/PostDrawerContent";
import SubcontractorDrawerContent from "./UnivervalDetailsDrawerContents/SubcontractorDrawerContent";

const DetailsDrawer = (props) => {
  const getDrawerTilte = () => {
    if (props.itemType === "ticket" || props.itemType === "ticket_order")
      return `${i18n.t("drawer.details_drawer.ticket")} ${
        props.itemData?.ticket_nr || ""
      }`;
    if (props.itemType === "order")
      return `${i18n.t("drawer.details_drawer.order")} ${
        props.itemData?.order_nr || ""
      }`;
    if (props.itemType === "task")
      return `${i18n.t("drawer.details_drawer.task")} ${
        props.itemData?.task_nr || ""
      }`;
    if (props.itemType === "offer")
      return `${i18n.t("drawer.details_drawer.offer")} ${
        props.itemData?.offer_nr || ""
      }`;
    if (props.itemType === "feedback")
      return `${i18n.t("drawer.details_drawer.ticket")} ${
        props.itemData?.ticket.ticket_nr || ""
      }`;
    if (props.itemType === "post")
      return `${props.itemData?.post_title || ""}`;
    if (props.itemType === "owner")
      return `${props.itemData?.owner_short_name || ""}`;
    if (props.itemType === "part")
      return `${props.itemData?.part_name || ""}`;
    if (props.itemType === "subcontractor")
      return `${props.itemData?.subcontractor_short_name || ""}`;
    return null;
  };

  const getDrawerContent = () => {
    if (props.itemType === "ticket" || props.itemType === "ticket_order")
      return (
        <TicketDrawerContent
          itemData={props.itemData}
          itemType={props.itemType}
          filteringData={props.filteringData}
          onUpdateDataPromise={props.onUpdateDataPromise}
          onOpenDialogParamsAction={props.onOpenDialogParamsAction}
          onRefetchData={props.onRefetchData}
          readOnly={props.readOnly || !props.hasPermissionToManageTickets}
          availableActionButtons={props.availableActionButtons}
          onRefetchTableData={props.onRefetchTableData}
          maxAllowedLevelNumber={props.maxAllowedLevelNumber}
        />
      );
    if (props.itemType === "order")
      return (
        <OrderDrawerContent
          itemData={props.itemData}
          itemType={props.itemType}
          filteringData={props.filteringData}
          onUpdateDataPromise={props.onUpdateDataPromise}
          onOpenDialogParamsAction={props.onOpenDialogParamsAction}
          onRefetchData={props.onRefetchData}
          readOnly={props.readOnly || !props.hasPermissionToManageOrders}
          availableActionButtons={props.availableActionButtons}
          onRefetchTableData={props.onRefetchTableData}
        />
      );
    if (props.itemType === "task")
      return (
        <TaskDrawerContent
          itemData={props.itemData}
          itemType={props.itemType}
          filteringData={props.filteringData}
          onUpdateDataPromise={props.onUpdateDataPromise}
          onOpenDialogParamsAction={props.onOpenDialogParamsAction}
          onRefetchData={props.onRefetchData}
          readOnly={props.readOnly || !props.hasPermissionToManageTasks}
          availableActionButtons={props.availableActionButtons}
          onRefetchTableData={props.onRefetchTableData}
        />
      );
    if (props.itemType === "post")
      return (
        <PostDrawerContent
          itemData={props.itemData}
          itemType={props.itemType}
          autocompleteData={props.filteringData.value}
          onUpdateDataPromise={props.onUpdateDataPromise}
          onRefetchData={props.onRefetchData}
          readOnly={props.readOnly}
          availableActionButtons={props.availableActionButtons}
          setOpenDrawer={props.setOpenDrawer}
          backdropColor={props.backdropColor}
        />
      );
    if (props.itemType === "offer")
      return (
        <OfferDrawerContent
          onChangeOfferDetails={props.onChangeOfferDetails}
          filteringData={props.filteringData}
          itemData={props.itemData}
          readOnly={props.readOnly}
          onChangeAutocompletedOfferDetails={
            props.onChangeAutocompletedOfferDetails
          }
          onChangeDate={props.onChangeDate}
          dialogMode={props.dialogMode}
          unfillRequiredFields={props.unfillRequiredFields}
          onDecrementPayers={props.onDecrementPayers}
          settlements = {props.settlements}
          showSettlements = {props.showSettlements}
          allowEditKNote={props.allowEditKNote}
          showLogsButton={props.showLogsButton}
        />
      );
    if (props.itemType === "feedback")
      return (
        <FeedbackDrawerContent
          onRefetchData={props.onRefetchData}
          filteringData={props.filteringData}
          itemData={props.itemData}
          readOnly={props.readOnly}
          availableActionButtons={props.availableActionButtons}
        />
      );
    if (props.itemType === "owner")
      return (
        <OwnerDrawerContent
          ownerData={props.itemData}
          filteringData={props.filteringData}
          onUpdateDataPromise={props.onUpdateDataPromise}
          onOpenDialogParamsAction={props.onOpenDialogParamsAction}
          onRefetchData={props.onRefetchData}
          readOnly={props.readOnly}
          onRefetchTableData={props.onRefetchTableData}
          ownerId={props.itemData?.id}
        />
      );
      if (props.itemType === "part")
        return (
          <PartDrawerContent
            partData={props.itemData}
            readOnly={props.readOnly}
            partChoiceSelectData = {props.partChoiceSelectData}
          />
        );
        if (props.itemType === "subcontractor")
          return (
            <SubcontractorDrawerContent
              subcontractorData={props.itemData}
              onUpdateDataPromise={props.onUpdateDataPromise}
              onOpenDialogParamsAction={props.onOpenDialogParamsAction}
              onRefetchData={props.onRefetchData}
              readOnly={props.readOnly}
              onRefetchTableData={props.onRefetchTableData}
              subcontractorId={props.itemData?.id}
            />
          );
    return null;
  };

  return (
    <GridDrawer
      isPageDrawer={props.isPageDrawer}
      openDrawer={props.openDrawer}
      setOpenDrawer={props.setOpenDrawer}
      drawerTitle={getDrawerTilte()}
      drawerContentElement={getDrawerContent()}
      arrowIcon={true}
      drawerWidth={props.drawerWidth}
      spacing={0}
      isLoading={props.isLoading}
      showMiniDrawer={props.showMiniDrawer}
    >
      {props.children}
    </GridDrawer>
  );
};

DetailsDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  drawerTitle: PropTypes.string,
  drawerContentElement: PropTypes.element,
  itemData: PropTypes.object,
  onUpdateDataPromise: PropTypes.func,
  itemType: PropTypes.oneOf([
    "ticket",
    "ticket_order",
    "order",
    "task",
    "offer",
    "feedback",
    "post"
  ]),
  isLoading: PropTypes.bool,
  filteringData: PropTypes.object,
  onOpenDialogParamsAction: PropTypes.func,
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  availableActionButtons: PropTypes.array,
  drawerWidth: PropTypes.number,
  showMiniDrawer: PropTypes.bool,
  isPageDrawer: PropTypes.bool,
  onChangeOfferDetails: PropTypes.func,
  onChangeAutocompletedOfferDetails: PropTypes.func,
  dialogMode: PropTypes.oneOf(["newOffer", "existOffer"]),
  onRefetchTableData: PropTypes.func,
  hasPermissionToManageTickets: PropTypes.bool,
  hasPermissionToManageOrders: PropTypes.bool,
  hasPermissionToManageTasks : PropTypes.bool
};

DetailsDrawer.defaultProps = {
  itemType: "ticket",
  isLoading: false,
  readOnly: false,
  drawerWidth: DETAILS_DRAWER_WIDTH,
  showMiniDrawer: false,
  isPageDrawer: true,
  hasPermissionToManageTickets: false,
  hasPermissionToManageOrders: false,
  hasPermissionToManageTasks : false
};

export default DetailsDrawer;
