import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import DateService from "../../../services/dateService";
import { Grid, IconButton, Typography, Tooltip } from "@mui/material";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EnclosureDetailsDialog from "../../dialog/EnclosureDetailsDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import StarsIcon from "@mui/icons-material/Stars";
import { EXTERNAL_TYPE, INTERNAL_TYPE } from "../../../helpers/constants";
import useDialog from "../../../hooks/useDialog";
import ConfirmationDialog from "../../dialog/ConfirmationDialog/confirmationDialog";
import DocsDetailsDialog from "../../dialog/DocsDetailsDialog";
import AddPartCertificationDialog from "../../dialog/AddPartCertificationDialog";


function EnclosureDataRow(props) {
  const { t } = useTranslation();

  const isShowPreview = props.file.content_type === 'application/pdf'
  const canBeRemoved = ((props.canHideEnclosures || props.canRemoveEnclosures) && !props.readOnly) || (props.file.id === undefined)
  const miniatureUrl = props.file.id ? props.file.miniature_download_link : props.file?.blob_url

  const getFileName = () => {
    switch (props.fileType) {
      case "docs":
        return props.file.docs_name
      case "certs":
        return props.file.cert_name
      default:
        return props.file.enclosure_name
    }
  }
  const fileName = getFileName()

  const [
    openNotesDialog,
    onOpenNotesDialog,
    onCloseNotesDialog,
  ] = useDialog();

  const [
    openConfimartionDialog,
    onOpenConfimartionDialog,
    onCloseConfimartionDialog,
  ] = useDialog();

  const getEnclosureStyle = () => {
    if (props.file.copied === true) {
      return {
        padding: "5px",
        textAlign: "left",
      }
    }
    if (props.file?.enclosure_external || props.file?.enclosure_type === EXTERNAL_TYPE) {
      return {
        padding: "5px",
        textAlign: "left",
        backgroundColor: "#e1f1f5",
        border: "1px solid #b3dbe7",
      };
    } else if (props.file?.enclosure_external === false || props.file?.enclosure_type === INTERNAL_TYPE) {
      return {
        padding: "5px",
        textAlign: "left",
        backgroundColor: "#b7e4ab",
        border: "1px solid #85e5a6",
      };
    }
    return {
      padding: "5px",
      textAlign: "left",
    }
  };


  const downloadFile = useCallback(() => {
    let file_id = props.fileType === 'enclosure' ? props.file.enclosure_id : props.file.id
    let file_name = fileName
    props.onDownload(
      file_id,
      file_name,
      props.index
    );
  }, [props.file, props.index, props.fileType]);

  const previewFile = useCallback(() => {
    let file_id = props.fileType === 'enclosure' ? props.file.enclosure_id : props.file.id
    props.onPreview(file_id, props.index);
  }, [props.file, props.index, props.fileType]);

  const updateEnclosure = useCallback((enclosure) => {
    props.onUpdateEnclosure(enclosure, props.index);
    onCloseNotesDialog();
  }, [props.index]);

  const deleteEnclosure = useCallback(() => {
    props.onDeleteEnclosure(props.file.id, props.index);
    onCloseConfimartionDialog();
  }, [props.file, props.index]);

  const copyEnclosure = useCallback(() => {
    props.onCopyEnclosure(props.file.id);
    onCloseNotesDialog();
  }, [props.file]);

  const addEnclosureToFavorite = useCallback(() => {
    props.onAddEnclosureToFavorite(props.file.enclosure_id);
    onCloseNotesDialog();
  }, [props.file.enclosure_id]);


  const manageHideOrDeleteIcon = () => {
    return props.file.id === undefined || props.file?.copied || props.canRemoveEnclosures
  }


  return (
    <>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        {props.showCreator && (
          <>
            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={11} sx={{ padding: "0", textAlign: "left" }}>
              <Typography variant="caption">
                {" "}
                {props.file?.user_full_name}:
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={1}>
          <Typography
            variant="body2"
            sx={{ padding: "5px", textAlign: "center" }}
          >
            {" "}
            {props.index + 1}{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={props.showAddToFavorite ? 5 : 6}>
          <Typography variant="body2" sx={{ ...getEnclosureStyle() }}>
            {" "}
            {fileName}{" "}
          </Typography>
        </Grid>
        {props.readOnly && props.onCopyEnclosure ?
          <Tooltip title={t("other.enclosure_data_row.copy")}>
            <IconButton
              color="primary"
              onClick={copyEnclosure}
              sx={{ padding: "5px" }}
            >
              <AddCircleIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          :
          props.showPublishButton ?
            <Grid item xs={12} sm={1}>
              <IconButton color="primary" disabled sx={{ padding: "5px" }}>
                <DriveFileMoveIcon fontSize="medium" />
              </IconButton >
            </Grid>
            :
            <Grid item xs={12} sm={1}></Grid>
        }
        {props.showAddToFavorite &&
          <Grid item xs={12} sm={1}>
            <Tooltip title={t("other.enclosure_data_row.add_to_favorite")}>
              <IconButton color="primary" onClick={addEnclosureToFavorite} sx={{ padding: "5px" }} disabled={props.file.is_favorite}>
                <StarsIcon fontSize="medium" />
              </IconButton >
            </Tooltip>
          </Grid>
        }
        {props.showDetailsButton ?
          <Grid item xs={12} sm={1}>
            <Tooltip title={t("other.enclosure_data_row.details")}>
              <IconButton color="primary" onClick={onOpenNotesDialog} sx={{ padding: "5px" }}>
                <InfoIcon fontSize="medium" />
              </IconButton >
            </Tooltip>
          </Grid>
          :
          <Grid item xs={12} sm={1}></Grid>
        }
        {props.showDownloadButton ?
          <Grid item xs={12} sm={1}>
            <Tooltip title={t("other.enclosure_data_row.download")}>
              <IconButton color="primary" onClick={downloadFile} sx={{ padding: "5px" }}>
                <DownloadIcon fontSize="medium" />
              </IconButton >
            </Tooltip>
          </Grid>
          :
          <Grid item xs={12} sm={1}></Grid>
        }
        <Grid item xs={12} sm={1}>
          {isShowPreview && (
            <Tooltip title={t("other.enclosure_data_row.preview")}>
              <IconButton
                color="primary"
                onClick={previewFile}
                sx={{ padding: "5px" }}
              >
                <PreviewOutlinedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}
          {miniatureUrl ? (
            <Tooltip title={t("other.enclosure_data_row.preview")}>
              <img
                id="pictureId"
                src={miniatureUrl}
                width="70%"
                height="70%"
                alt={""}
                onClick={previewFile}
              ></img>
            </Tooltip>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} sm={1}>
          {canBeRemoved && <Tooltip title={t(manageHideOrDeleteIcon() ? "other.enclosure_data_row.delete" : "other.enclosure_data_row.hide")}>
            <IconButton color="primary" onClick={onOpenConfimartionDialog} sx={{ padding: "5px" }}>
              {manageHideOrDeleteIcon() ? <DeleteIcon fontSize="medium" /> : <VisibilityOffIcon fontSize="medium" />}
            </IconButton >
          </Tooltip>
          }
        </Grid>

        <Grid
          item
          xs={12}
          sm={props.showAddToFavorite ? 6 : 7}
          sx={{ textAlign: "end" }}
        >
          <Typography variant="caption" sx={{ padding: "0", textAlign: "end" }}>
            {" "}
            {DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
              props.file?.created
            )}
          </Typography>
        </Grid>

      </Grid>
      {openNotesDialog && props.fileType === "enclosure" && (
        <EnclosureDetailsDialog
          showSubcontractorSelect={props.showSubcontractorSelect}
          showEnclosureType={props.showEnclosureType}
          showEnclosurePartKind= {props.showEnclosurePartKind}
          open={openNotesDialog}
          onClose={onCloseNotesDialog}
          enclosure={props.file}
          editable={!props.readOnly}
          onSubmit={(enclosure) => updateEnclosure(enclosure)}
        />
      )}
      {openNotesDialog && props.fileType === "docs" && (
        <DocsDetailsDialog
          open={openNotesDialog}
          onClose={onCloseNotesDialog}
          docsId={props.file.id}
          doc = {props.file}
          editable={!props.readOnly}
          onSubmit={(doc) => updateEnclosure(doc)}
        />
      )}
      {openNotesDialog && props.fileType === "certs" && (
        <AddPartCertificationDialog
          open={openNotesDialog}
          onClose={onCloseNotesDialog}
          certificate = {props.file}
          editable={!props.readOnly}
          onSubmit={(doc) => updateEnclosure(doc)}
          mode={"view"}
        />
      )}
      {openConfimartionDialog && (
        <ConfirmationDialog
          open={openConfimartionDialog}
          onYes={deleteEnclosure}
          onNo={onCloseConfimartionDialog}
          title={manageHideOrDeleteIcon() ? t(`other.enclosure_data_row.remove_${props.fileType}`) : t(`other.enclosure_data_row.hide_${props.fileType}`)}
          content={manageHideOrDeleteIcon() ? t(`other.enclosure_data_row.are_you_sure_to_remove_${props.fileType}`) : t(`other.enclosure_data_row.are_you_sure_to_hide_${props.fileType}`)}
          maxWidth={"xs"}
          titleSx={{ color: "var(--alert)" }}
          contentSx={{ fontFamily: "Roboto", textAlign: "center" }}
          btnYesColor={"error"}
          btnNoColor={"secondary"}
          showDialogContentDividers={false}
        />
      )

      }
    </>
  );
}

EnclosureDataRow.propTypes = {
  enclosure: PropTypes.object,
  index: PropTypes.number,
  onDownload: PropTypes.func,
  onPreview: PropTypes.func,
  onAddEnclosureToFavorite: PropTypes.func,
  showSubcontractorSelect: PropTypes.bool,
  showEnclosureType: PropTypes.bool,
  onUpdateEnclosure: PropTypes.func,
  showCreator: PropTypes.bool,
  readOnly: PropTypes.bool,
  onCopyEnclosure: PropTypes.func,
  canHideEnclosures: PropTypes.bool,
  canHideEnclosures: PropTypes.bool,
  showAddToFavorite: PropTypes.bool,
  showPublishButton: PropTypes.bool,
  showDetailsButton: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  canRemoveEnclosures: PropTypes.bool,
  fileType: PropTypes.string
}

EnclosureDataRow.defaultProps = {
  enclosure: {},
  index: 1,
  showSubcontractorSelect: false,
  showEnclosureType: false,
  showCreator: true,
  readOnly: false,
  canHideEnclosures: false,
  canHideEnclosures: false,
  showAddToFavorite: true,
  showPublishButton: true,
  showDetailsButton: true,
  showDownloadButton: true,
  showHideIcon: true,
  canRemoveEnclosures: false,
  fileType: 'enclosure'
}

export default EnclosureDataRow;  