import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React from "react";
import OfferSettlementsForm from "../../form/OfferSettlementsForm";
import { SETTLEMENT_TABLE_FIELDS_CONFIG } from "../../form/OfferSettlementsForm/FormTablesConfig";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

function OfferSettlementDialog(props) {
  const { t } = useTranslation();

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      title={t("dialog.decrement_setlement_dialog.decrement_setlements")}
      maxWidth="xl"
      showTopActionButton={false}
      showBottomActionButton={false}
      bottomActionStyle={{ padding: "10px" }}
      showCustomFooter={true}
      showDialogActions={false}
    >

    <LoaderWrapper showLoader={props.data === undefined || props.filteringData.loading}>
      <OfferSettlementsForm
        data={props.data}
        tableConfig={props.tableConfig}
        readOnly={props.readOnly}
        filteringData = {props.filteringData}
        onChangeAutocomplete = {props.onChangeAutocomplete}
        onHandleAddEmptyRow = {props.onHandleAddEmptyRow}
        showContextMenu={!props.readOnly}
        contextMenuActions={props.contextMenuActions}
        onDataChange = {props.onDataChange}
        isTableValid = {props.isTableValid}
        isSumValid = {props.isSumValid}
        showAddButton  = {props.showAddButton }
        showConfirmationForm = {props.showConfirmationForm}
        onConfirmAcceptOfferByOm = {props.onConfirmAcceptOfferByOm}
        onSendMessage = {props.onSendMessage}
        loading = {props.loading}
        isOfferCR= {props.isOfferCR}
      />
      </LoaderWrapper>
    </BasicDialog>
  );
}

OfferSettlementDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  data : PropTypes.array,
  onHandleSettlementAddEmptyRow : PropTypes.func,
  onChangeAutocomplete : PropTypes.func,
  showContextMenu : PropTypes.bool,
  contextMenuActions: PropTypes.func,
  isRequiredeFieldsValid: PropTypes.bool,
  sumSettlementRatesIsCorrectly: PropTypes.bool,
  tableConfig : PropTypes.object,
  showAddButton  : PropTypes.bool,
  filteringData : PropTypes.array,
  showMessageForm : PropTypes.bool,
  onConfirmAcceptOfferByOm : PropTypes.func,
  onSendMessage : PropTypes.func
};

OfferSettlementDialog.defaultProps = {
  open: false,
  readOnly: true,
  data : [],
  isRequiredeFieldsValid: true,
  sumSettlementRatesIsCorrectly: true,
  onDataChange : PropTypes.func,
  showContextMenu : false,
  contextMenuActions: [],
  tableConfig : SETTLEMENT_TABLE_FIELDS_CONFIG,
  showAddButton  : true,
  filteringData : [],
  showMessageForm : false
};

export default OfferSettlementDialog;
