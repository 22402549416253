import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TodayMarker,
} from "react-calendar-timeline";
import { defaultHeaderFormats as headerFormats } from "react-calendar-timeline/lib/lib/default-config";
import "react-calendar-timeline/lib/Timeline.css";
import { Grid, Tooltip } from "@mui/material";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import SidebarHeaderContent from "./SidebarHeaderContent";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import PlannerItem from "./PlannerItem/PlannerItem";
import containerResizeDetector from "react-calendar-timeline/lib/resize-detector/container";
import "./PlannerTimeline.css";

function PlannerTimeline(props) {
  const scrollRef = useRef();
  const timelineRef = useRef();

  useEffect(() => {
    if (props.onChangeScrollTimlineRef) {
      props.onChangeScrollTimlineRef(scrollRef);
    }
  }, [props.onChangeScrollTimlineRef, scrollRef]);

  useEffect(() => {
    if (props.onChangeTimlineRef) {
      props.onChangeTimlineRef(timelineRef);
    }
  }, [props.onChangeTimlineRef, timelineRef]);

  const headerFormatsLocal = {
    ...headerFormats,
    day: {
      ...headerFormats.day,
      long: "DD.MM.YYYY",
      medium: "DD.MM.YYYY",
      mediumLong: "DD.MM.YYYY",
      short: "DD.MM",
    },
    hour: {
      long: "HH:mm",
      medium: "HH:mm",
      mediumLong: "HH:mm",
      short: "HH:mm",
    },
  };

  const customTimeSteps = {
    second: 1, // 15 minut w sekundach
    minute: 15, // 15 minut w minutach
    hour: 1,
    day: 1,
    month: 1,
    year: 1,
  };

  function formatLabel([timeStart, timeEnd], unit, labelWidth) {
    let format;
    if (labelWidth >= 150) {
      format = headerFormatsLocal[unit]["long"];
    } else if (labelWidth >= 100) {
      format = headerFormatsLocal[unit]["mediumLong"];
    } else if (labelWidth >= 50) {
      format = headerFormatsLocal[unit]["medium"];
    } else {
      format = headerFormatsLocal[unit]["short"];
    }
    return timeStart.format(format);
  }

  const groupRenderer = ({ group }) => {
    return (
      <Grid container sx={{ paddingInline: group.dayOff ? 0 : undefined }}>
        <Grid
          item
          xs={6}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          className={group.dayOff ? "day-off" : undefined}
        >
          <Tooltip
            title={group.group_name}
            placement="top"
            arrow
            style={{ margin: 0 }}
          >
            {group.group_name}
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          className={group.dayOff ? "day-off" : undefined}
        >
          <Tooltip
            title={group.full_name}
            placement="top"
            arrow
            style={{ margin: 0 }}
          >
            {group.full_name}
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const onScrollRef = (ref) => {
    scrollRef.current = ref;
  };

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    return (
      <PlannerItem
        item={item}
        timelineContext={timelineContext}
        itemContext={itemContext}
        getItemProps={getItemProps}
        getResizeProps={getResizeProps}
        onSave={props.onSaveItem}
        onDuplicate={props.onDuplicateItem}
        onDelete={props.onDeleteItem}
        onShowDetails={props.onShowDetailsItem}
        hasPermissionToManagePlanner={props.hasPermissionToManagePlanner}
      />
    );
  };

  const onItemContextMenu = () => {
    console.log("context");
  };

  return (
    <LoaderWrapper showLoader={props.showLoader}>
      <Timeline
        resizeDetector={containerResizeDetector}
        groups={props.users}
        items={props.items}
        timeSteps={customTimeSteps}
        defaultTimeStart={props.visibleTimeStart}
        defaultTimeEnd={props.visibleTimeEnd}
        visibleTimeStart={props.visibleTimeStart}
        visibleTimeEnd={props.visibleTimeEnd}
        groupRenderer={groupRenderer}
        horizontalLineClassNamesForGroup={(group) =>
          group.dayOff ? ["day-off"] : undefined
        }
        sidebarWidth={props.sidebarWidth}
        stackItems={true}
        onItemMove={props.onItemMove}
        scrollRef={onScrollRef}
        ref={timelineRef}
        itemRenderer={itemRenderer}
        onItemContextMenu={onItemContextMenu}
        keys={{
          groupIdKey: "id",
          groupTitleKey: "full_name",
          groupRightTitleKey: "rightTitle",
          itemIdKey: "id",
          itemTitleKey: "task_planner_label",
          itemDivTitleKey: "task_planner_desc",
          itemGroupKey: "task_t",
          itemTimeStartKey: "start",
          itemTimeEndKey: "end",
        }}
        onItemResize={props.onItemResize}
      >
        <TimelineHeaders>
          <SidebarHeader>
            {(propsTemp) => {
              return (
                <SidebarHeaderContent
                  {...propsTemp}
                  startHour={props.startHour}
                  endHour={props.endHour}
                  skillSelectList={props.skillsUserIsLeaderSelectList}
                  onChangeSkill={props.onChangeSkill}
                  skill={props.skill}
                  onChangeStartHour={props.onChangeStartHour}
                  onChangeEndHour={props.onChangeEndHour}
                  onClearStartHour={props.onClearStartHour}
                  onClearEndHour={props.onClearEndHour}
                />
              );
            }}
          </SidebarHeader>
          <DateHeader unit="day" labelFormat={formatLabel} />
          <DateHeader unit="hour" labelFormat={formatLabel} />
          <DateHeader unit="minute" />
        </TimelineHeaders>
        <TodayMarker>
          {({ styles, date }) => (
            <div style={{ ...styles, ...props.todayMarkerStyle }} />
          )}
        </TodayMarker>
      </Timeline>
    </LoaderWrapper>
  );
}

PlannerTimeline.propTypes = {
  users: PropTypes.array,
  items: PropTypes.array,
  visibleTimeStart: PropTypes.any,
  visibleTimeEnd: PropTypes.any,
  sidebarWidth: PropTypes.number,
  skill: PropTypes.string,
  skillSelectList: PropTypes.array,
  onChangeStartHour: PropTypes.func,
  onChangeEndHour: PropTypes.func,
  onClearStartHour: PropTypes.func,
  onClearEndHour: PropTypes.func,
  onChangeSkill: PropTypes.func,
  startHour: PropTypes.string,
  endHour: PropTypes.string,
  data: PropTypes.object,
  onSaveItem: PropTypes.func,
  onDuplicateItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  todayMarkerStyle: PropTypes.object,
  onShowDetailsItem: PropTypes.func,
};

PlannerTimeline.defaultProps = {
  todayMarkerStyle: { backgroundColor: "#ffa500" },
};

export default PlannerTimeline;
