
import { useEffect, useState } from "react";
import useUserService from "../services/userService";
import { useAsync } from "./useAsync";
import { useAuth } from "../context/auth";

const useCheckPermission = (permissionKind) => {
  
  const [checkedPermission, setCheckedPermission] = useState(false)
  const { user } = useAuth();
  const {
    checkUserPermission
  } = useUserService();

  const checkPermission = useAsync(() => {
    if (user.is_aad_user){
      return checkUserPermission({ 'perm_kind': permissionKind});
    }
    return Promise.resolve(false)
  },
    [user]);


    useEffect(() => {
      if (checkPermission.loading) {
        return;
      }
      if (checkPermission.value) {
        setCheckedPermission(checkPermission.value.has_permission)
      }
    }, [checkPermission.loading]);

  return [checkedPermission];
};

export default useCheckPermission;
