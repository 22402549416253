import { AUTOCOMPLETE_FIELD_TYPE, DATETIME_TO_DATE_RANGE_FIELD_TYPE, NUMERIC_RANGE_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import DateService from "../../../../services/dateService";

export const TRANSACTION_OUT_TABLE_NAME = "transaction_out_table";
export const TRANSACTION_OUT_TABLE_CONFIG = [
  {
    name: "warehouse",
    getValueCallback: (rowData) => rowData.warehouse,
    label: i18n.t("table.transaction_table.warehouse_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.warehouses,
      isObjectOption: true,
      optionLabelKey: "warehouse_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "part",
    getValueCallback: (rowData) => rowData.part,
    label: i18n.t("table.transaction_table.part_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "transaction_wz",
    getValueCallback: (rowData) => rowData.transaction_wz,
    label: i18n.t("table.transaction_table.wz_number"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "transaction_order",
    getValueCallback: (rowData) => rowData.transaction_order,
    label: i18n.t("table.transaction_table.order_number"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "transaction_price",
    getValueCallback: (rowData) => rowData.transaction_price,
    label: i18n.t("table.transaction_table.transaction_price"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "transaction_amount",
    getValueCallback: (rowData) => rowData.transaction_amount,
    label: i18n.t("table.transaction_table.transaction_amount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "part_unit",
    getValueCallback: (rowData) => i18n.t(`other.units.${rowData.part_unit}`),
    label: i18n.t("table.transaction_table.part_unit"), 
  },
  {
    name: "transaction_value",
    getValueCallback: (rowData) => rowData.transaction_value,
    label: i18n.t("table.transaction_table.transaction_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "created",
    getValueCallback: (rowData) => DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.transaction_table.transaction_created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: () => undefined,
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user,
    label: i18n.t("table.transaction_table.operator"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.users,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
  },
];
