import { Button, Grid, Typography } from "@mui/material";
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BaseBox from "../../base/BaseBox/baseBox";
import ShortUserForm from "../../form/ShortUserForm";



export default function UserFormBox({ users, onAddUser, onRemoveUser, readOnly, subcontractorId }) {
    const { t } = useTranslation();

    return (
        <BaseBox>
            <Grid container spacing={1}>
                <Grid item sm={8} xs={8} textAlign={"start"} display={"flex"}>
                    <Typography
                        variant="subtitle2"
                        className="capitalize-first-letter"
                    >
                        { t(`other.user_form_box.subcontractor_users`)}
                    </Typography>
                </Grid>
                <Grid item sm={4} xs={4} />
                {users?.map((user, index) => {
                    return (
                        <ShortUserForm
                            user={user}
                            index={index}
                            onRemoveUser={onRemoveUser}
                            readOnly={readOnly}
                            subcontractorId={subcontractorId}
                        />
                    )
                })
                }
                <Grid item xs={12}>

                    {!readOnly &&
                        <Button
                            fullWidth
                            variant="text"
                            size="small"
                            onClick={onAddUser}
                        >
                            {t("other.user_form_box.add_relation_user")}
                        </Button>}
                </Grid>
            </Grid>
        </BaseBox>
    );
}

UserFormBox.propTypes = {
    users: PropTypes.array,
    onAddUser: PropTypes.func,
    onRemoveUser: PropTypes.func,
    readOnly: PropTypes.bool
}


UserFormBox.defaultProps = {
    users: [],
    readOnly: true
}
