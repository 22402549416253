import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import { useTranslation } from "react-i18next";
import ItemRecord from "../../other/ItemRecord/ItemRecord";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";
import PropTypes from "prop-types";
import { MATERIAL_MAIN_BRANCH_NAME } from "../../../helpers/constants";
import { formatRackShellData } from "../../../page/StockWarehousePage/PageTablesConfig";


const TransactionMoveForm= (props
) => {
  const { t } = useTranslation();

  const getObjectedLocationList = (list) => {
    let translatedList = []
    if (list) {
      for (let permission of list) {
        translatedList.push({ 'id': permission.id, 'warehouse_location': formatRackShellData(permission) })
      }
    }
    return translatedList
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >

      <Grid item xs={12}>
        <ItemRecord
          itemLevels={props.items}
          onChangeItem={props.onChangeLevelItem}
          onChangeAutocompleteFieldWithObjectOptions={props.onChangeAutocomplete}
          partValue={props.formValue['part']}
          onCleanPart={props.onCleanPart}
          showMaterialLabel={true}
          required={false}
          onClickSearchItem={props.onClickSearchItem}
          defaultFirstLevelItemName={MATERIAL_MAIN_BRANCH_NAME}
        />
      </Grid>
      <Grid item xs={2.5}>
        <AutocompleteField
          name={`warehouse`}
          label={t(`form.transaction_form.warehouse_out`)}
          value={props.formValue[`warehouse`]}
          options={props.transactionSelectData?.value?.warehouse}
          isObjectOption={true}
          optionLabelKey={"warehouse_name"}
          addNewValue={false}
          onChange={props.onChangeAutocomplete}
          required
        />
      </Grid>
      <Grid item xs={2.5}>
        <AutocompleteField
          name={`warehouse_receiving`}
          label={t(`form.transaction_form.warehouse_in`)}
          value={props.formValue[`warehouse_receiving`]}
          options={props.transactionSelectData?.value?.warehouse}
          isObjectOption={true}
          optionLabelKey={"warehouse_name"}
          addNewValue={false}
          onChange={props.onChangeAutocomplete}
          required
        />
      </Grid>
        <Grid item xs={3}>
          <AutocompleteField
            name={`warehouse_location`}
            label={t(`form.transaction_form.warehouse_location`)}
            value={props.formValue[`warehouse_location`]}
            options={getObjectedLocationList(props.warehouseLocations?.value)}
            isObjectOption={true}
            optionLabelKey={"warehouse_location"}
            addNewValue={false}
            onChange={props.onChangeAutocomplete}
          />
        </Grid>
      <Grid item xs={1}>
        <FloatField
          name="transaction_amount"
          readOnly={props.readOnly}
          value={props.formValue[`transaction_amount`]}
          label={t("form.transaction_form.transaction_amount")}
          onChange={props.onChange}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <FloatField
          name="stock_availability"
          disabled={true}
          value={props.formValue[`stock_availability`]}
          label={t("form.buffer_details_form.stock_amount")}
        />
      </Grid>
      <Grid item xs={1}>
        <TextFieldFormControl
          disabled={true}
          value={props.formValue.part_unit? t(`other.units.${props.formValue.part_unit}`):undefined}
          label={t("form.transaction_form.part_unit")}
        />
      </Grid>
      <Grid item xs={1}>
        <FloatField
          name="transaction_price"
          disabled={true}
          value={props.formValue[`transaction_price`]}
          label={t("form.transaction_form.transaction_price")}
        />
      </Grid>
    </Grid>
  );
};

TransactionMoveForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocomplete: PropTypes.func,
  readOnly: PropTypes.bool,
  transactionSelectData: PropTypes.array,
  poPartList: PropTypes.array
};

TransactionMoveForm.defaultProps = {
  readOnly: false
};

export default TransactionMoveForm;
