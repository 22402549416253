import React from "react";

import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";

import RRAdminUserNotificationsTab from "./RRAdminUserNotificationsTab";
import RRAdminUserContactsTab from "./RRAdminUserContactsTab";
import RRAdminTenantsTab from "./RRAdminTenantsTab";
import RRAdminLocationsTab from "./RRAdminLocationsTab";

const RRAdminTabs = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <FullWidthTabs
      items={[
        {
          label: t("tabs.rr_admin.locations"),
          content: (
            <RRAdminLocationsTab
              readOnly={props.readOnly}
              pageName={props.pageName}
            />
          ),
        },
        {
          label: t("tabs.rr_admin.contacts"),
          content: (
            <RRAdminUserContactsTab
              readOnly={props.readOnly}
              pageName={props.pageName}
            />
          ),
        },
        {
          label: t("tabs.rr_admin.tenant_list"),
          content: (
            <RRAdminTenantsTab
              readOnly={props.readOnly}
              pageName={props.pageName}
            />
          ),
        },
        {
          label: t("tabs.rr_admin.email_notifications"),
          content: (
            <RRAdminUserNotificationsTab
              readOnly={props.readOnly}
              pageName={props.pageName}
            />
          ),
        },
      ]}
    />
  );
});

RRAdminTabs.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

RRAdminTabs.defaultProps = {
  readOnly: false,
};

export default RRAdminTabs;
