import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../context/snackbarAlert";
import {
  postDataUrl,
  postFilteringDataUrl,
  postCreate,
  postInListUrl,
  postOutListUrl,
  postSubcategoryListUrl,
  sideAdministrationListUrl,
  userOwnersSelectListUrl,
  ownersSelectListUrl,
  postParentUrl,
  userDepartmentListUrl,
  postDetailsDataUrl,
  departmentListUrl,
  postDefaultStatus,
  departmentSelectListUrl,
  userDepartmentSelectListUrl,
  departmentsListUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useFileService from "./fileService";

export default function useCorrespondenceService() {
  const axiosPrivate = useAxiosPrivate();
  const { uploadAttachments } = useFileService();
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const createNewPost = async (dataToSend) => {
    const files = dataToSend.docs_posts;
    delete dataToSend.docs_posts;

    let results = await uploadAttachments(files, {});
    let uploadedData = results["uploadedData"];
    let showWaring = results["showWaring"];

    dataToSend.docs_posts = uploadedData

    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, postCreate, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          if (showWaring) {
            snackbarAlert.openWarningSnackbarAlert(
              t("snackbar_alert.occurred_warning_during_upload_file")
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };



  const getPostFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postFilteringDataUrl, searchParams)
    );
  }

  const getPostsInData = (searchParams, isDialogWithParamsOpen = true) => {
    if (isDialogWithParamsOpen) {
      return makeRequest(
        axiosPrivate,
        prepareUrlWithQueryParams(postInListUrl, searchParams)
      );
    }

    return Promise.resolve([]);
  };

  const getPostsOutData = (searchParams, isDialogWithParamsOpen = true) => {
    if (isDialogWithParamsOpen) {
      return makeRequest(
        axiosPrivate,
        prepareUrlWithQueryParams(postOutListUrl, searchParams)
      );
    }

    return Promise.resolve([]);
  };

  const getPostData = (postId) => {
    return makeRequest(axiosPrivate, postDetailsDataUrl(postId));
  };

  const updatePostData = (postId, dataToSend) => {
    return makeRequest(axiosPrivate, postDataUrl(postId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getPostSubcategoryList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postSubcategoryListUrl, searchParams)
    );
  };
  const getSideAdministrationList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(sideAdministrationListUrl, searchParams)
    );
  };
  const getUserOwnersSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(userOwnersSelectListUrl, searchParams)
    );
  };
  const getOwnersSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(ownersSelectListUrl, searchParams)
    );
  };
  const getUserDepartmentSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(userDepartmentSelectListUrl, searchParams)
    );
  };
  const getDepartmentSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(departmentSelectListUrl, searchParams)
    );
  };
  const getDepartmentsList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(departmentsListUrl, searchParams)
    );
  };
  const getPostParent = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postParentUrl, searchParams)
    );
  };
  const getPostDefaultStatus = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postDefaultStatus, searchParams)
    );
  };

  return {
    createNewPost,
    getPostData,
    getPostFilteringData,
    getPostsInData,
    getPostsOutData,
    getPostSubcategoryList,
    getSideAdministrationList,
    getUserOwnersSelectList,
    getOwnersSelectList,
    getUserDepartmentSelectList,
    // getUserDepartmentList,
    getDepartmentSelectList,
    getDepartmentsList,
    getPostParent,
    updatePostData,
    getPostDefaultStatus
  };
}
