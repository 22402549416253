import React, { useState } from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import TrueFalseSelectField from "../../../field/TrueFalseSelectField/trueFalseSelectField";
import AutocompleteField from "../../../field/AutocompleteField";
import TextFieldFormControl from "../../../field/TextFieldFormControl";
import TimeDurationSelectField from "../../../field/TimeDurationSelectField";

import i18n from "../../../../i18n";

import FieldWithLabel from "../../../field/FieldWithLabel";

const ItemDetailsForm = ({
  isPaid,
  showIsPaid,
  isAllowedToChangeIsPaid,
  onChangeIsPaid,
  statusName,
  substatusName,
  categoryId,
  categoryOptions,
  onChangeCategory,
  isCategoryAllowedToChange = false,
  priorityId,
  priorityOptions,
  onChangePriority,
  isPriorityAllowedToChange = false,
  ticketDescCreator,
  orderDescCreator,
  taskDescCreator,
  itemName,
  onChangeTextField,
  isAllowedToChangeItemName = false,
  orderStdTimeValue,
  onChangeOrderStdTimeValue,
  isAllowedToChangeOrderStdTimeValue = false,
  orderType,
  orderTypeOptions,
  onChangeOrderType,
  isAllowedToChangeOrderType = false,
  itemType = "ticket",
  isAllowedToChangeTicketDescCreator = false,
  isAllowedToChangeOrderDescCreator = false,
  isAllowedToChangeTaskDescCreator = false,
}) => {
  const getItemNameByItemType = () =>
    itemType === "order"
      ? "order_name"
      : itemType === "task"
      ? "task_name"
      : "ticket_name";

  const [textFieldsFormValueLocaly, setTextFieldsFormValueLocaly] = useState(
    () => ({
      [getItemNameByItemType()]: itemName,
      ticket_desc_creator: ticketDescCreator,
      order_desc_creator: orderDescCreator,
      task_desc_creator: taskDescCreator,
    })
  );

  const [isTextFieldChanged, setIsTextFieldChanged] = useState(false);
  const onChangeTextFieldLocaly = (e) => {
    setIsTextFieldChanged(true);
    setTextFieldsFormValueLocaly((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeTextField = (e) => {
    if (isTextFieldChanged) {
      setIsTextFieldChanged(false);
      onChangeTextField(e);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        <FieldWithLabel
          label={
            itemType === "order"
              ? i18n.t("form.item_details.order_name")
              : itemType === "task"
              ? i18n.t("form.item_details.task_name")
              : i18n.t("form.item_details.ticket_name")
          }
          field={
            <TextFieldFormControl
              name={getItemNameByItemType()}
              value={textFieldsFormValueLocaly[getItemNameByItemType()]}
              disabled={!isAllowedToChangeItemName}
              onChange={onChangeTextFieldLocaly}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      {showIsPaid && (
        <Grid item xs={12}>
          <FieldWithLabel
            label={i18n.t("form.item_details.paid")}
            field={
              <TrueFalseSelectField
                name={itemType === "order" ? "order_paid" : "ticket_paid"}
                valuesAsBool
                value={isPaid}
                readOnly={!isAllowedToChangeIsPaid}
                disabled={!isAllowedToChangeIsPaid}
                onChange={onChangeIsPaid}
                addEmptyOptions={false}
              />
            }
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.item_details.status")}
          field={<TextFieldFormControl value={statusName} readOnly />}
        />
      </Grid>
      {itemType !== "order" && (
        <Grid item xs={12}>
          <FieldWithLabel
            label={
              itemType === "task"
                ? i18n.t("form.item_details.reason")
                : i18n.t("form.item_details.substatus")
            }
            field={<TextFieldFormControl value={substatusName} readOnly />}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.item_details.category")}
          field={
            <AutocompleteField
              name="ticket_category"
              value={categoryId}
              options={categoryOptions}
              isObjectOption={true}
              optionLabelKey={"tc_name"}
              onChange={onChangeCategory}
              disableClearable
              readOnly={!isCategoryAllowedToChange}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={i18n.t("form.item_details.priority")}
          field={
            <AutocompleteField
              name={
                itemType === "order" || itemType === "task"
                  ? "order_priority"
                  : "ticket_priority"
              }
              value={priorityId}
              options={priorityOptions}
              isObjectOption={true}
              optionLabelKey={
                itemType === "order" || itemType === "task"
                  ? "ordpri_name"
                  : "ticpri_name"
              }
              onChange={onChangePriority}
              disableClearable
              readOnly={!isPriorityAllowedToChange}
            />
          }
        />
      </Grid>
      {itemType === "order" && (
        <>
          <Grid item xs={12}>
            <FieldWithLabel
              label={i18n.t("form.item_details.order_type")}
              field={
                <AutocompleteField
                  name="order_type"
                  value={orderType}
                  options={orderTypeOptions}
                  isObjectOption={true}
                  optionLabelKey={"ordtyp_type"}
                  onChange={onChangeOrderType}
                  disableClearable
                  readOnly={!isAllowedToChangeOrderType}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FieldWithLabel
              label={i18n.t("form.item_details.order_std_time_value")}
              field={
                <TimeDurationSelectField
                  name="order_std_time_value"
                  value={orderStdTimeValue}
                  onChange={onChangeOrderStdTimeValue}
                  readOnly={!isAllowedToChangeOrderStdTimeValue}
                />
              }
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"ticket_desc_creator"}
          label={i18n.t("form.item_details.ticket_desc_creator")}
          value={textFieldsFormValueLocaly.ticket_desc_creator}
          onChange={onChangeTextFieldLocaly}
          onBlur={handleChangeTextField}
          multiline
          rows={4}
          resize={"vertical"}
          inputStyle={{
            "-webkit-text-fill-color": "black",
          }}
          disabled={!isAllowedToChangeTicketDescCreator}
        />
      </Grid>
      {(itemType === "order" || itemType === "task") && (
        <Grid item xs={12}>
          <TextFieldFormControl
            name={"order_desc_creator"}
            label={i18n.t("form.item_details.order_desc_creator")}
            value={textFieldsFormValueLocaly.order_desc_creator}
            onChange={onChangeTextFieldLocaly}
            onBlur={handleChangeTextField}
            multiline
            rows={4}
            resize={"vertical"}
            inputStyle={{
              "-webkit-text-fill-color": "black",
            }}
            disabled={!isAllowedToChangeOrderDescCreator}
          />
        </Grid>
      )}
      {itemType === "task" && (
        <Grid item xs={12}>
          <TextFieldFormControl
            name={"task_desc_creator"}
            label={i18n.t("form.item_details.task_desc_creator")}
            value={textFieldsFormValueLocaly.task_desc_creator}
            onChange={onChangeTextFieldLocaly}
            onBlur={handleChangeTextField}
            multiline
            rows={4}
            resize={"vertical"}
            disabled={!isAllowedToChangeTaskDescCreator}
          />
        </Grid>
      )}
    </Grid>
  );
};

ItemDetailsForm.propTypes = {
  isPaid: PropTypes.bool,
  showIsPaid: PropTypes.bool,
  isAllowedToChangeIsPaid: PropTypes.bool,
  onChangeIsPaid: PropTypes.func,
  isAccepted: PropTypes.bool,
  statusName: PropTypes.string,
  substatusName: PropTypes.string,
  categoryId: PropTypes.string,
  categoryOptions: PropTypes.array,
  onChangeCategory: PropTypes.func,
  isCategoryAllowedToChange: PropTypes.bool,
  priorityId: PropTypes.string,
  priorityOptions: PropTypes.array,
  onChangePriority: PropTypes.func,
  isPriorityAllowedToChange: PropTypes.bool,
  ticketDescCreator: PropTypes.string,
  orderDescCreator: PropTypes.string,
  itemName: PropTypes.string,
  onChangeOrderStdTimeValue: PropTypes.func,
  isAllowedToChangeItemName: PropTypes.bool,
  orderStdTimeValue: PropTypes.number,
  onChangeOrderStdTimeValue: PropTypes.func,
  isAllowedToChangeOrderStdTimeValue: PropTypes.bool,
  orderType: PropTypes.string,
  orderTypeOptions: PropTypes.array,
  onChangeOrderType: PropTypes.func,
  isAllowedToChangeOrderType: PropTypes.bool,
  itemType: PropTypes.oneOf(["order", "ticket", "task"]),
  isAllowedToChangeTicketDescCreator: PropTypes.bool,
  isAllowedToChangeOrderDescCreator: PropTypes.bool,
  isAllowedToChangeTaskDescCreator: PropTypes.bool,
};

ItemDetailsForm.defaultProps = {
  showIsPaid: true,
  isAllowedToChangeTicketDescCreator: false,
  isAllowedToChangeOrderDescCreator: false,
  isAllowedToChangeTaskDescCreator: false,
};

export default ItemDetailsForm;
