import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from 'react'
import EnclosureDataRow from "../../other/EnclosureDataRow";
import { useTranslation } from "react-i18next";


function EnclosuresWindow(props) {
  const { t } = useTranslation();
  const scrollableDivRef = useRef(null)

  useEffect(() => {
      scrollToPosition()
  }, [props.enclosures])

  const scrollToPosition = () => {  
    scrollableDivRef.current?.scrollTo(0, 0)
}

const getWindowNoFilesInfo = () =>{
  switch(props.fileType) {
    case "docs":
        return t("window.enclosures_window.no_docs")
    case "certs":
        return t("window.enclosures_window.no_certificates")
    default:
        return t("window.enclosures_window.no_enclosures") 
  } 
}

  return (
    <div ref={scrollableDivRef} style={{ overflow: 'auto', paddingRight: '5px', ...props.enclosureWindowStyle }} >
      {props.enclosures.length > 0 ?
        <Grid container spacing={1}
          alignItems="center"
          justifyContent="flex-start">
          {
            props.enclosures.map((enclosure, index) => {
              return (
                <Grid item xs={12} sm={12}>
                  <EnclosureDataRow
                    index={index}
                    file={enclosure}
                    onDownload={props.onDownload}
                    onPreview={props.onPreview}
                    onUpdateEnclosure={props.onUpdateEnclosure}
                    onDeleteEnclosure = {props.onDeleteEnclosure}
                    onAddEnclosureToFavorite={props.onAddEnclosureToFavorite}
                    showCreator={props.showCreator}
                    readOnly = {props.readOnly}
                    onCopyEnclosure = {props.onCopy}
                    canHideEnclosures = {props.canHideEnclosures}
                    canRemoveEnclosures = {props.canRemoveEnclosures}
                    showAddToFavorite={props.showAddToFavorite}
                    showPublishButton = {props.showPublishButton}
                    showDetailsButton = {props.showDetailsButton}
                    showDownloadButton = {props.showDownloadButton}
                    fileType = {props.fileType}
                    showSubcontractorSelect={props.showSubcontractorSelect}
                    showEnclosureType={props.showEnclosureType}
                    showEnclosurePartKind= {props.showEnclosurePartKind}
                  />
                </Grid>
              )
            })
          }
        </Grid>
        :
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center">
          
          <Typography variant="subtitle1" color="primary"> {getWindowNoFilesInfo()} </Typography>
        </Grid>
      }
    </div>
  );
};

EnclosuresWindow.propTypes = {
  enclosures: PropTypes.object,
  enclosureWindowStyle: PropTypes.object,
  showEnclosureType: PropTypes.bool,
  onUpdateEnclosure: PropTypes.func,
  onDeleteEnclosure : PropTypes.func,
  onAddEnclosureToFavorite: PropTypes.func,
  onDownload: PropTypes.func,
  onPreview: PropTypes.func,
  showCreator: PropTypes.bool,
  readOnly : PropTypes.bool,
  onCopy : PropTypes.func,
  canHideEnclosures : PropTypes.bool,
  showAddToFavorite: PropTypes.bool,
  showPublishButton : PropTypes.bool,
  showDetailsButton : PropTypes.bool,
  showDownloadButton : PropTypes.bool,
  showDeleteIcon : PropTypes.bool,
  canRemoveEnclosures : PropTypes.bool
}

EnclosuresWindow.defaultProps = {
  enclosures: {},
  enclosureWindowStyle: { height: 400 },
  showEnclosureType: false,
  readOnly : false,
  canHideEnclosures : false,
  showPublishButton : true,
  showDetailsButton : true,
  showDownloadButton : true,
  showDeleteIcon : false,
  canRemoveEnclosures : false
}

export default EnclosuresWindow;   