import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams, } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import TableService from "../../services/tableService";
import { PO_LINES_TABLE_FIELDS_CONFIG, PO_LINES_TABLE_NAME, PO_TABLE_FIELDS_CONFIG, PO_TABLE_NAME } from "./PageTablesConfig";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import { NAVIGATION_DRAWER_TYPE_WAREHOUSES } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import FilterTable from "../../components/table/FilterTable";
import usePurchaseService from "../../services/purchaseService";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import useDialog from "../../hooks/useDialog";
import UniversalToolBar from "../../components/bar/UniversalToolBar";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import useDocsData from "../../hooks/useDocsData";
import useDialogWithId from "../../hooks/useDialogWithId";
import PurchaseDialog from "../../components/dialog/PurchaseDialog";
import useUserService from "../../services/userService";
import FvPreviewDialog from "../../components/dialog/FvPreviewDialog";
import { CREATE_PURCHASE_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";
import { getErrorMsg } from "../../helpers/methods";


const TABLE_CONFIGS = [
  { name: PO_TABLE_NAME, config: PO_TABLE_FIELDS_CONFIG },
  { name: PO_LINES_TABLE_NAME, config: PO_LINES_TABLE_FIELDS_CONFIG },
];

const PO_FILTER_PREFIX = "po";

const PO_LINES_FILTER_PREFIX = "po_line";

export default function PoRegistryPage(props) {
  const { pageName } = props;
  const { t } = useTranslation();

  const snackbarAlert = useSnackbarAlert();

  const [poDataLocal, setPoDataLocal] = useState([])
  const [countPoRecords, setCountPoRecords] = useState();

  const [selectedPo, setSelectedPo] = useState()

  const [searchParams, setSearchParams] = useSearchParams();
  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState([]);
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState([]);

  const [poLinesDataLocal, setPoLinesDataLocal] = useState([])
  const [poTableSearchParams, setPoTableSearchParams] = useState({});
  const [poLinesTableSearchParams, setPoLinesSearchParams] = useState({});

  const [poNumberToInvoiceDialog, setPoNumberToInvoiceDialog] = useState()

  const [hasPermissionToManagePurchases] = useCheckPermission(CREATE_PURCHASE_PERMISSION )
  
  const {
    onDownloadDoc,
    onPreviewDoc,
  } = useDocsData();

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [
    openPoDialog,
    poEditId,
    handleOpenPoDialog,
    handleClosePoDialog,
    exportPoStatus
  ] = useDialogWithId();

  const [
    openFvListByPoDialog,
    poIdForFvList,
    handleOpenFvListByPoDialog,
    handleCloseFvListByPoDialog,
    exportPoListByFvStatus
  ] = useDialogWithId();


  const {
    page: poDataPage,
    pageSize: poDataPageSize,
    handleChangePageWithSearchParams: handleChangePoDataPage,
    handleChangeRowsPerPage: handleChangePoRowsPerPage,
    resetPoPageNumber,
  } = usePaginationWithSearchParams(PO_FILTER_PREFIX);


  const { setNewItemSearchParamsIfAreChanged } =
    useFilterSearchParams(searchParams);

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();


  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );


  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [PO_TABLE_NAME, PO_LINES_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [PO_TABLE_NAME, PO_LINES_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);


  const fullRefreshTable = () => {
    setPoDataLocal(undefined);
    setSelectedPo(undefined)
    poData.refetch();
  };

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      PO_FILTER_PREFIX,
      poTableSearchParams,
      setPoTableSearchParams
    );
    setNewItemSearchParamsIfAreChanged(
      PO_LINES_FILTER_PREFIX,
      poLinesTableSearchParams,
      setPoLinesSearchParams
    );
  }, [searchParams]);


  const {
    createPo,
    getPoListData,
    getPoFilteringData,
    getPoLinesByPoListData
  } = usePurchaseService();
  
  const createPoFn = useAsyncFn(createPo)
  
  const onClickPurchase = useCallback(
    () => {
      createPoFn
      .execute({})
      .then((res) => {
        let poId = res.id
        handleOpenPoDialog(poId)
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert( 
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_creating_new_purchse_order")
        );
      });
    },
    []
  );


  const poData = useAsync(() =>
    getPoListData(poTableSearchParams), [poTableSearchParams]);

  const poFilteringData = useAsync(() => getPoFilteringData(), [])

  const poLinesData = useAsync(
    () => {
      if (selectedPo) {
        return getPoLinesByPoListData(selectedPo, poLinesTableSearchParams);
      }
      return Promise.resolve([]);
    },
    [selectedPo, poLinesTableSearchParams])

  useEffect(() => {
    if (poLinesData.loading) {
      return;
    }
    setPoLinesDataLocal(poLinesData.value);
  }, [poLinesData.loading]);


  const handleClickPoRow = (poId) => {
    if (!poId || poId === selectedPo) {
      setSelectedPo(undefined)
    } else {
      setSelectedPo(poId);
    }
  };

  useEffect(() => {
    if (poData.loading) {
      return;
    }
    setPoDataLocal(poData.value.results);
    setCountPoRecords(poData.value.count);
  }, [poData.loading]);

  const onClickEdit = useCallback((e, poId) => {
    e.stopPropagation()
    handleOpenPoDialog(poId)
  }, [poEditId, openPoDialog])

  const onClickShowInvoices = useCallback((e, poId) => {
    e.stopPropagation()
    handleOpenFvListByPoDialog(poId)
    let po = poDataLocal.find(po => po.id === poId)
    setPoNumberToInvoiceDialog(po.po_nr)
  }, [ poDataLocal,   openFvListByPoDialog, poIdForFvList])


  const isPoTableLoading = poData.loading || poFilteringData.loading

  const isPoLinesTableLoading = poLinesData.loading || poFilteringData.loading

  const poTableData = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        PO_TABLE_FIELDS_CONFIG,
        poDataLocal ? poDataLocal : [],
        hiddenColumnsForTables[PO_TABLE_NAME] ? hiddenColumnsForTables[PO_TABLE_NAME] : [],
        columnsOrdersForTables[PO_TABLE_NAME] ? columnsOrdersForTables[PO_TABLE_NAME] : [],
        onClickEdit,
        onClickShowInvoices,
        onPreviewDoc,
        onDownloadDoc
      ),
    [poDataLocal, hiddenColumnsForTables[PO_TABLE_NAME], columnsOrdersForTables[PO_TABLE_NAME]]
  );

  const poLinesTableData = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        PO_LINES_TABLE_FIELDS_CONFIG,
        poLinesDataLocal ? poLinesDataLocal : [],
        hiddenColumnsForTables[PO_LINES_TABLE_NAME] ? hiddenColumnsForTables[PO_LINES_TABLE_NAME] : [],
        columnsOrdersForTables[PO_LINES_TABLE_NAME] ? columnsOrdersForTables[PO_LINES_TABLE_NAME] : []
      ),
    [poLinesDataLocal, hiddenColumnsForTables[PO_LINES_TABLE_NAME], columnsOrdersForTables[PO_TABLE_NAME]]
  );

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const handleUpdateUserPreferences = (
    hiddenColumnsForTables,
    columnsOrdersForTables
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          hiddenColumnsForTables,
          columnsOrdersForTables
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        fullRefreshTable();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const onCloseInvoicePreViewDialog = useCallback(()=>{
    handleCloseFvListByPoDialog()
    setPoNumberToInvoiceDialog(undefined)
  },[poNumberToInvoiceDialog, openFvListByPoDialog])

  const getPageContent = () => {
    return (
      <LoaderWrapper showLoader={isPoTableLoading}>
        <FilterTable
          data={poTableData ? poTableData : []}
          onClickRow={handleClickPoRow}
          countRecords={countPoRecords}
          page={poDataPage}
          onPageChange={handleChangePoDataPage}
          rowsPerPage={poDataPageSize}
          onRowsPerPageChange={handleChangePoRowsPerPage}
          resetPageNumber={resetPoPageNumber}
          selectedItemId={selectedPo}
          style={{ maxHeight: "40vh" }}
          tableConfig={PO_TABLE_FIELDS_CONFIG}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            PO_TABLE_FIELDS_CONFIG,
            poFilteringData,
            hiddenColumnsForTables[PO_TABLE_NAME] ? hiddenColumnsForTables[PO_TABLE_NAME] : [],
            columnsOrdersForTables[PO_TABLE_NAME] ? columnsOrdersForTables[PO_TABLE_NAME] : []
          )}
          filterPrefix={PO_FILTER_PREFIX}
          showCleanFilterIcon={true}
          showContextMenu={false}
          showCheckbox={false}
        />
        {selectedPo ? (
          <LoaderWrapper showLoader={isPoLinesTableLoading}>
            <FilterTable
              data={poLinesTableData ? poLinesTableData : []}
              onClickRow={() => undefined}
              filteringData={poFilteringData}
              headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                PO_LINES_TABLE_FIELDS_CONFIG,
                poFilteringData,
                hiddenColumnsForTables[PO_LINES_TABLE_NAME] ? hiddenColumnsForTables[PO_LINES_TABLE_NAME] : [],
                columnsOrdersForTables[PO_LINES_TABLE_NAME] ? columnsOrdersForTables[PO_LINES_TABLE_NAME] : []
              )}
              style={{ maxHeight: "40vh" }}
              tableConfig={PO_LINES_TABLE_FIELDS_CONFIG}
              filterPrefix={PO_LINES_FILTER_PREFIX}
              showCleanFilterIcon={true}
              showContextMenu={false}
              showCheckbox={false}
              withPagination={false}
              showTitle={true}
              title={t('page.po_registry_page.po_lines')}
            />
          </LoaderWrapper>
        ) : (
          <Typography
            textAlign="center"
            variant="overline"
            display="block"
            gutterBottom
            style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
          >
            {t("page.po_registry_page.choose_po_to_po_lines")}
          </Typography>
        )}
      </LoaderWrapper>
    );
  };

  return (
    <NavigationDrawer
      drawerType={NAVIGATION_DRAWER_TYPE_WAREHOUSES}
      pageName={pageName}
    >
      <DefaultPageWrapper titleKey={"po_registry"}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <UniversalToolBar
              style={{ marginBlock: "5px" }}
              onClickAdjustTable={onOpenTableColumnVisibilityDialog}
              showCreateButton={hasPermissionToManagePurchases}
              itemType ={"purchase"}
              onClickCreateItem={onClickPurchase}
              showMassActionButton={false}
              showMyFiltersButton={false}
              showCleanfiltersButton={false}
              showOpenCloseFilterButton={false}
              showSearchButton={false}

            />
          </Grid>
          <Grid item xs={12}>
            {getPageContent()}
          </Grid>
        </Grid>

        {openTableColumnVisibilityDialog && (
          <TableColumnVisibilityDialog
            open={openTableColumnVisibilityDialog}
            onClose={onCloseTableColumnVisibilityDialog}
            onSubmit={handleUpdateUserPreferences}
            tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
              TABLE_CONFIGS,
              columnsOrdersForTables
            )}
            tablesHiddenColumns={hiddenColumnsForTables}
            isLoading={userPreferencesForPage.loading}
          />
        )}
        {openPoDialog &&
          <PurchaseDialog
            poId={poEditId}
            open={openPoDialog}
            onClose={handleClosePoDialog}
            hasEditPermission={hasPermissionToManagePurchases}
            onRefetchData={fullRefreshTable}
          />
        }
        {openFvListByPoDialog &&
          <FvPreviewDialog
            open={openFvListByPoDialog}
            onClose={onCloseInvoicePreViewDialog }
            poId={poIdForFvList}
            poNumber = {poNumberToInvoiceDialog}
          />
        }


      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
