import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import WarehouseTransactionInTab from "./WarehouseTransactionInTab/WarehouseTransactionInTab";
import useWarehouseService from "../../../services/warehouseService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import WarehouseTransactionOutTab from "./WarehouseTransactionOutTab/WarehouseTransactionOutTab";
import WarehouseTransactionRemoveTab from "./WarehouseTransactionRemoveTab/WarehouseTransactionRemoveTab";
import WarehouseReservationsTab from "./WarehouseReservationsTab";
import WarehouseTransactionMoveTab from "./WarehouseTransactionMoveTab";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useDialog from "../../../hooks/useDialog";
import { RESERVATION_KIND } from "../../../helpers/constants";


const WarehouseTransactionsTab = React.memo((props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [dataAreUpdating, setDataAreUpdating] = useState(false)
  const transactionRefetchRef = useRef();

  const [
    openNewTransactionDialog,
    onOpenNewTransactionDialog,
    onCloseNewTransactionDialog,
  ] = useDialog();


  const { getTransactionFilteringData,
    createTransaction,
    createReservation } = useWarehouseService()

  const createTransactionFn = useAsyncFn(createTransaction)
  const createReservationFn = useAsyncFn(createReservation)

  const onRefetchData = () => {
    if (transactionRefetchRef.current) {
      transactionRefetchRef.current.onRefetchData();
    }
  };

  

  const onSubmitNewTransaction = useCallback((form) =>{
    setDataAreUpdating(true)
    if (form.transaction_kind === RESERVATION_KIND){
      createReservationFn.execute(form)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.reservation_created")
        );
        onRefetchData()
        setDataAreUpdating(false)
        onCloseNewTransactionDialog()
      })
      .catch((error) => {
        if (error?.data?.non_field_errors){
          snackbarAlert.openErrorSnackbarAlert(t(`snackbar_alert.${error.data.non_field_errors[0]}`));
        }else{
          snackbarAlert.openErrorSnackbarAlert(t(`snackbar_alert.${error.data.error_code}`));
        }
        setDataAreUpdating(false)
      });
    }else{
      createTransactionFn.execute(form)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.transaction_created")
          );
          onRefetchData()
          setDataAreUpdating(false)
          onCloseNewTransactionDialog()
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(t(`snackbar_alert.${error.data.error_code}`));
          setDataAreUpdating(false)
        });
    }
  },[])

  const transactionFilteringData = useAsync(getTransactionFilteringData)

  const commonTransactionProps = {
    readOnly: props.readOnly,
    transactionFilteringData: transactionFilteringData,
    isSaving : dataAreUpdating,
    onOpenTransactionDialog : onOpenNewTransactionDialog,
    openTransactionDialog : openNewTransactionDialog,
    onCloseTransactionDialog : onCloseNewTransactionDialog,
    onSubmit: onSubmitNewTransaction,
    ref : transactionRefetchRef
  }

  return (
    <FullWidthTabs
      cleanFilters={true}
      items={[
        {
          label: t("tabs.warehouse_transaction_tabs.transaction_in"),
          content: (
            <WarehouseTransactionInTab
              {...commonTransactionProps}
            />
          ),
        },
        {
          label: t("tabs.warehouse_transaction_tabs.transaction_out"),
          content: (
            <WarehouseTransactionOutTab
            {...commonTransactionProps}
            />
          ),
        },
        {
          label: t("tabs.warehouse_transaction_tabs.transaction_move"),
          content: (
            <WarehouseTransactionMoveTab
            {...commonTransactionProps}
            />
          ),
        },
        {
          label: t("tabs.warehouse_transaction_tabs.reservations"),
          content: (
            <WarehouseReservationsTab
            {...commonTransactionProps}
            />
          ),
        },
        {
          label: t("tabs.warehouse_transaction_tabs.transaction_delete"),
          content: (
            <WarehouseTransactionRemoveTab
               {...commonTransactionProps}
            />
          ),
        },
      ]}
    />
  );
});

WarehouseTransactionsTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

WarehouseTransactionsTab.defaultProps = {
  readOnly: false,
};

export default WarehouseTransactionsTab;
