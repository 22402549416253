import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import UserTenantTableWithToolBar from "../../../other/UserTenantTableWithToolBar";

import {
  USER_TENANT_TABLE_FILTER_PREFIX,
  USER_TENANT_TABLE_NAME,
  USER_TENANTS_TABLE_CONFIG,
} from "./TableConfig";

const RRAdminUserContactsTab = (props) => {
  const { t } = useTranslation();

  return (
    <UserTenantTableWithToolBar
      pageName={props.pageName}
      tableName={USER_TENANT_TABLE_NAME}
      tableConfig={USER_TENANTS_TABLE_CONFIG}
      tableFilterPrefix={USER_TENANT_TABLE_FILTER_PREFIX}
      addUserTenantButtonTitle={t("tabs.rr_admin.add_contact")}
      exportToFileFileName={`${t("tabs.rr_admin.contacts")}.xlsx`}
      userTenantDataSearchParams={{ tenant_rr_visibility: true }}
      userTenantFilteringDataSearchParams={{ tenant_rr_visibility: true }}
      showRoleDescriptionDialog={false}
      defaultPageSize={100}
      initialExpandedFilterHeader={true}
    />
  );
};

RRAdminUserContactsTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

RRAdminUserContactsTab.defaultProps = {};

export default RRAdminUserContactsTab;
