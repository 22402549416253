import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import TicketCollapsibleTable from "../../components/table/TicketCollapsibleTable/TicketCollapsibleTable";
import {
  TICKET_TABLE_NAME,
  TICKET_TABLE_FIELDS_CONFIG,
  ORDER_TABLE_FIELDS_CONFIG,
  ORDER_TABLE_NAME,
} from "../../components/table/TicketCollapsibleTable/TableConfigs";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import UniversalToolBar from "../../components/bar/UniversalToolBar";
import useTicketService from "../../services/ticketService";
import useOrderService from "../../services/orderService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import FilterDialog from "../../components/base/FilterDialog";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import MultipleLocalizationDialog from "../../components/dialog/MutlipleLocalizationDialog";
import ChangeStatusDialog from "../../components/dialog/ChangeStatusDialog";
import MergeTicketDialog from "../../components/dialog/MergeTicketsDialog";
import UniversalFilterForm from "../../components/form/UniversalFilterForm";
import TableService from "../../services/tableService";
import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import AddDeleteFlagDialog from "../../components/dialog/AddDeleteFlagDialog";
import CreateTicketDialog from "../../components/dialog/CreateTicketDialog";
import { getErrorMsg, isSearchParamsEmpty } from "../../helpers/methods";
import useTicketOrderDetailsDrawer from "../../hooks/useTicketOrderDetailsDrawer";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper/DefaultPageWrapper";
import { exportTicketsToFileUrl } from "../../helpers/apiUrls";
import usePermissionService from "../../services/permissionService";

const TABLE_CONFIGS = [
  { name: TICKET_TABLE_NAME, config: TICKET_TABLE_FIELDS_CONFIG },
  { name: ORDER_TABLE_NAME, config: ORDER_TABLE_FIELDS_CONFIG },
];

export default function TicketOrderManagementPage(props) {
  const { pageName } = props;
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [ticketDataLocaly, setTicketDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const [isDialogWithParamsClosed, setIsDialogWithParamsClosed] =
    useState(true);

  const { showChangesCircle } = usePermissionService();

  const {
    page: ticketDataPage,
    pageSize: ticketDataPageSize,
    handleChangePageWithSearchParams: handleChangeTicketDataPage,
    handleChangePageSizeWithSearchParams: handleChangeTicketDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const handleChangeRowsPerPage = (e) => {
    handleChangeTicketDataPageSize(e, parseInt(e.target.value, 10));
  };


  const {
    getTicketData,
    getTicketFilteringData,
    addTicketsToFavority,
    deleteTicketsFromFavority,
    updateMassTicketData,
  } = useTicketService();

  const { getOrderFilteringData } = useOrderService();

  const ticketData = useAsync(
    () => getTicketData(searchParams, isDialogWithParamsClosed),
    [searchParams]
  );

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const fullRefreshTable = () => {
    setTicketDataLocaly(undefined);
    ticketData.refetch();
  };

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  
  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [TICKET_TABLE_NAME, ORDER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [TICKET_TABLE_NAME, ORDER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const handleUpdateUserPreferences = (
    hiddenColumnsForTables,
    columnsOrdersForTables
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          hiddenColumnsForTables,
          columnsOrdersForTables
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        fullRefreshTable();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const ticketFilteringData = useAsync(getTicketFilteringData);
  const orderFilteringData = useAsync(getOrderFilteringData);

  useEffect(() => {
    if (ticketData.loading) {
      return;
    }
    setTicketDataLocaly(ticketData.value.results);
    setCountRecords(ticketData.value.count);
  }, [ticketData.loading]);

  const handleClickCheck = useCallback((id) => {
    setTicketDataLocaly((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      selectedRow.selected = !selectedRow.selected;
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, []);

  const [checkedAll, setCheckedAll] = useState(false);
  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setTicketDataLocaly((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );

  const getSeletedTicketIds = (excludeClaims = false) =>
    ticketDataLocaly
      .filter(
        (ticket) =>
          ticket.selected && (!excludeClaims || ticket.is_claim === false)
      )
      .map((ticket) => ticket.id);

  const addTicketsToFavorityFn = useAsyncFn(addTicketsToFavority);

  const handleAddTicketForUserFavority = () => {
    let ticketIds = getSeletedTicketIds();
    if (ticketIds.length === 0) return;

    addTicketsToFavorityFn
      .execute(ticketIds)
      .then((res) => {
        setTicketDataLocaly(undefined);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.ticket_to_favority_added")
        );
        ticketData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_adding_ticket_to_favority")
        );
      });
  };

  const deleteTicketsToFavorityFn = useAsyncFn(deleteTicketsFromFavority);
  const handleDeleteTicketsFromFavority = () => {
    let ticketIds = getSeletedTicketIds();
    if (ticketIds.length === 0) return;
    deleteTicketsToFavorityFn
      .execute(ticketIds)
      .then((res) => {
        setTicketDataLocaly(undefined);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.ticket_from_favority_deleted")
        );
        ticketData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_deleting_ticket_from_favority"
          )
        );
      });
  };

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [
    openMultipleLocalizationDialog,
    onOpenMultipleLocalizationDialog,
    onCloseMultipleLocalizationDialog,
  ] = useDialog();

  const [
    openCreateTicketDialog,
    onOpenCreateTicketDialog,
    onCloseCreateTicketDialog,
  ] = useDialog();

  const [
    openAddDeleteFlagsToTicketDialog,
    onOpenAddDeleteFlagsToTicketDialog,
    onCloseAddDeleteFlagsToTicketDialog,
  ] = useDialog();

  const [
    openChangeTicketStatusDialog,
    onOpenChangeTicketStatusDialog,
    onCloseChangeTicketStatusDialog,
  ] = useDialog();

  const [
    openMergeTicketsDialog,
    onOpenMergeTicketsDialog,
    onCloseMergeTicketsDialog,
  ] = useDialog();

  const clickedTicketMultipleLocalization = useRef();
  const handleOpenMultipleLocalizationDialog = useCallback(
    (e, ticketId) => {
      e.stopPropagation();
      clickedTicketMultipleLocalization.current = ticketDataLocaly.find(
        (ticket) => ticket.id === ticketId
      ).locations;
      onOpenMultipleLocalizationDialog();
    },
    [onOpenMultipleLocalizationDialog, ticketDataLocaly]
  );

  const handleCloseMultipleLocalizationDialog = () => {
    clickedTicketMultipleLocalization.current = null;
    onCloseMultipleLocalizationDialog();
  };

  const onCleanFlitersInFilterDialog = () => {
    setTicketDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const isLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    columnsOrdersForTables === undefined ||
    ticketFilteringData.loading ||
    orderFilteringData.loading ||
    ticketDataLocaly === undefined ||
    countRecords === undefined;

  const onCloseCreateTicketDialogLocal = (refreshList = false) => {
    if (refreshList) {
      if (isSearchParamsEmpty(searchParams)) {
        ticketData.refetch();
      } else {
        setSearchParams({});
      }
    }
    onCloseCreateTicketDialog();
  };

  const flagDialogModeRef = useRef();
  const handleOpenAddDeleteFlagsToTicketDialog = (dialogMode) => {
    if (getSeletedTicketIds().length === 0) return;
    flagDialogModeRef.current = dialogMode;
    onOpenAddDeleteFlagsToTicketDialog();
  };

  const handleAddDeleteFlagsToTickets = () => {
    ticketData.refetch();
    onCloseAddDeleteFlagsToTicketDialog();
  };

  const handleOpenChangeTicketStatusDialog = () => {
    if (getSeletedTicketIds().length === 0) return;
    onOpenChangeTicketStatusDialog();
  };

  const handleOpenMergeTicketsDialog = () => {
    if (getSeletedTicketIds(true).length === 0) return;
    onOpenMergeTicketsDialog();
  };

  const updateMassTicketDataFn = useAsyncFn(updateMassTicketData);

  const prepareTicketDataForMassUpdate = (dataToSend) => {
    return getSeletedTicketIds().map((id) => ({
      id,
      ...dataToSend,
    }));
  };

  const handleChangeMassTicketStatus = (statusData) => {
    updateMassTicketDataFn
      .execute(prepareTicketDataForMassUpdate({ status_data: statusData }))
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.mass_status_changed")
        );
        onCloseChangeTicketStatusDialog();
        ticketData.refetch();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_mass_ticket_status_change")
          )
        );
      });
  };

  const massActionButtonOptions = [
    {
      label: t("page.ticket_page.mass_status_change"),
      callback: handleOpenChangeTicketStatusDialog,
    },
    {
      label: t("page.ticket_page.merge_tickets"),
      callback: handleOpenMergeTicketsDialog,
    },
    {
      label: t("page.ticket_page.add_ticket_to_favority"),
      callback: handleAddTicketForUserFavority,
    },
    {
      label: t("page.ticket_page.delete_ticket_from_favority"),
      callback: handleDeleteTicketsFromFavority,
    },
    {
      label: t("page.ticket_page.add_flags_to_tickets"),
      callback: () => handleOpenAddDeleteFlagsToTicketDialog("add"),
    },
    {
      label: t("page.ticket_page.delete_flags_from_tickets"),
      callback: () => handleOpenAddDeleteFlagsToTicketDialog("delete"),
    },
  ];

  const {
    openDetailsDrawer,
    handleToggleDetailsDrawer,
    handleOpenTicketDetailsDrawer,
    handleOpenOrderDetailsDrawer,
    handleToSetShowChangesUpdated,
    itemType,
    filteringData,
    itemData,
    isLoading: isDrawerLoading,
    onUpdateDataPromise,
    onRefetchData,
    openedTicketIdRef,
    openedOrderIdRef,
    availableActionButtons,
    hasPermissionToManageTickets,
    hasPermissionToManageOrders
  } = useTicketOrderDetailsDrawer(
    ticketData,
    setTicketDataLocaly,
    ticketFilteringData,
    orderFilteringData
  );

  const location = useLocation();

  useEffect(() => {
    if (
      isLoading === false &&
      location.state?.from_unsaved_configurator &&
      location.state?.ticket_id
    ) {
      handleOpenTicketDetailsDrawer(location.state.ticket_id);
      location.state = {};
    }
  }, [isLoading]);

  const ticketExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      TICKET_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[TICKET_TABLE_NAME],
      columnsOrdersForTables?.[TICKET_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[TICKET_TABLE_NAME],
    columnsOrdersForTables?.[TICKET_TABLE_NAME],
    TICKET_TABLE_FIELDS_CONFIG,
  ]);

  const rowClickDetails = (ticketId) => {
    handleOpenTicketDetailsDrawer(ticketId);
    handleToSetShowChangesUpdated(ticketId);
  };

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={handleToggleDetailsDrawer}
      itemData={itemData}
      filteringData={filteringData}
      itemType={itemType}
      isLoading={isDrawerLoading}
      onUpdateDataPromise={onUpdateDataPromise}
      onOpenDialogParamsAction={setIsDialogWithParamsClosed}
      onRefetchData={onRefetchData}
      onRefetchTableData={fullRefreshTable}
      availableActionButtons={availableActionButtons}
      hasPermissionToManageTickets = {hasPermissionToManageTickets}
      hasPermissionToManageOrders = {hasPermissionToManageOrders}
    >
      <NavigationDrawer pageName={pageName}>
        <DefaultPageWrapper titleKey={"ticket_management"}>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <UniversalToolBar
                style={{ marginBlock: "5px" }}
                massActionButtonOptions={massActionButtonOptions}
                onClickSearch={onOpenFilterDialog}
                onClickMyFilters={onOpenUserFiltersDialog}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                onClickCreateItem={onOpenCreateTicketDialog}
              />
            </Grid>
            <Grid item xs={12}>
              <LoaderWrapper showLoader={isLoading}>
                <TicketCollapsibleTable
                  showChangesCircle={showChangesCircle}
                  data={ticketDataLocaly}
                  checkedAll={checkedAll}
                  showDetailsIcon={true}
                  showCleanFilterIcon={false}
                  onClickCheckAll={handleClickCheckAll}
                  onClickCheck={handleClickCheck}
                  onClickRow={rowClickDetails}
                  countRecords={countRecords}
                  page={ticketDataPage}
                  onPageChange={handleChangeTicketDataPage}
                  rowsPerPage={ticketDataPageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={ticketFilteringData}
                  hiddenColumns={hiddenColumnsForTables}
                  columnsOrders={columnsOrdersForTables}
                  selectedTicketId={openedTicketIdRef.current}
                  isSelectedTicket={itemType === "ticket_order"}
                  selectedOrderId={openedOrderIdRef.current}
                  onClickMultipleLocationAlert={
                    handleOpenMultipleLocalizationDialog
                  }
                  style={{ maxHeight: "80vh" }}
                  onClickInnerTableRow={handleOpenOrderDetailsDrawer}
                  showExportToFileButton={true}
                  exportToFileUrl={exportTicketsToFileUrl}
                  exportToFileSearchParams={searchParams}
                  exportToFileHeaders={ticketExportHeaders}
                  exportToFileFileName={`${t(
                    "page.ticket_page.tickets"
                  ).replace(" ", "_")}.xlsx`}
                />
              </LoaderWrapper>
            </Grid>
          </Grid>
        </DefaultPageWrapper>
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFlitersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterForm={
            <UniversalFilterForm
              filteringData={ticketFilteringData}
              filtersConfig={TICKET_TABLE_FIELDS_CONFIG}
              includeOpenCloseFilter={true}
              includeHasChangesDotFilter={true}
            />
          }
        />
        {openUserFiltersDialog && (
          <UserFilterDialog
            open={openUserFiltersDialog}
            onClose={onCloseUserFiltersDialog}
            pageName={pageName}
            filterForm={
              <UniversalFilterForm
                filteringData={ticketFilteringData}
                filtersConfig={TICKET_TABLE_FIELDS_CONFIG}
                includeOpenCloseFilter={true}
                includeHasChangesDotFilter={true}
              />
            }
          />
        )}
        {openTableColumnVisibilityDialog && (
          <TableColumnVisibilityDialog
            open={openTableColumnVisibilityDialog}
            onClose={onCloseTableColumnVisibilityDialog}
            onSubmit={handleUpdateUserPreferences}
            tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
              TABLE_CONFIGS,
              columnsOrdersForTables
            )}
            tablesHiddenColumns={hiddenColumnsForTables}
            isLoading={userPreferencesForPage.loading}
          />
        )}
        {openMultipleLocalizationDialog &&
          clickedTicketMultipleLocalization.current && (
            <MultipleLocalizationDialog
              open={openMultipleLocalizationDialog}
              onClose={handleCloseMultipleLocalizationDialog}
              localizationData={clickedTicketMultipleLocalization.current}
              itemType={"ticket"}
            />
          )}
        {openCreateTicketDialog && (
          <CreateTicketDialog
            open={openCreateTicketDialog}
            onClose={onCloseCreateTicketDialogLocal}
          />
        )}

        {openAddDeleteFlagsToTicketDialog && (
          <AddDeleteFlagDialog
            open={openAddDeleteFlagsToTicketDialog}
            onClose={onCloseAddDeleteFlagsToTicketDialog}
            itemType={"ticket"}
            itemIds={getSeletedTicketIds()}
            onSubmit={handleAddDeleteFlagsToTickets}
            dialogMode={flagDialogModeRef.current}
          />
        )}

        {openChangeTicketStatusDialog && (
          <ChangeStatusDialog
            open={openChangeTicketStatusDialog}
            onClose={onCloseChangeTicketStatusDialog}
            itemType={"ticket"}
            onSubmit={handleChangeMassTicketStatus}
            isLoading={updateMassTicketDataFn.loading}
            isMassAction={true}
          />
        )}
        {openMergeTicketsDialog && (
          <MergeTicketDialog
            open={openMergeTicketsDialog}
            onClose={onCloseMergeTicketsDialog}
            ticketIds={getSeletedTicketIds(true)}
          />
        )}
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
