import BasicDialog from "../../base/BasicDialog"
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useForm } from "../../../hooks/useForm";
import { isEmptyObject, isEmptyValue } from "../../../helpers/methods";
import DocsDataForm from "../../form/DocsDataForm/DocsDataForm";
import useDocsService from "../../../services/docsService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useEffect } from "react";

function DocsDetailsDialog(props) {

    const { t } = useTranslation();

    const {
        formValue,
        setFormValue,
        onChange,
        onChangeAutocompleteFieldWithObjectOptions,
    } = useForm( props.docsId? {}: {...props.doc});


    const { detailsDocsData } = useDocsService()

    const docsData = useAsync(() => {
        if (props.docsId){
            return detailsDocsData(props.docsId);
        }
        return Promise.resolve({})
      }, [props.docsId]);


    useEffect(() => {
        if (docsData.loading) return;
        if (!isEmptyObject(docsData.value)){
            setFormValue(docsData.value);
        }
      }, [docsData.loading]);


    const validated = !isEmptyValue(formValue?.docs_area)

    const onSubmit = () => {
            props.onSubmit(formValue)
            setFormValue({})
    }

    return (
        <>
            <BasicDialog
                open={props.open}
                onClose={() => {
                    props.onClose()
                    setFormValue({})
                }}
                titleAlign="center"
                contentAlign="center"
                title={t("dialog.docs_details_dialog.docs_details")}
                maxWidth="sm"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={1}
                >
                    <Grid item xs={12}>
                        <DocsDataForm
                            formValue={formValue}
                            onChange={onChange}
                            onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions}
                            editMode = {true}
                            readOnly = {props.readOnly}
                        />
                    </Grid>
                    {!props.readOnly &&
                    <Grid item xs={12}>
                        <Button
                            disabled={!validated}
                            variant="contained"
                            onClick={onSubmit}
                            component="label"
                            fullWidth
                        >
                            {t("dialog.docs_details_dialog.save_changes")}
                        </Button>
                    </Grid>}
                </Grid>
            </BasicDialog>
        </>
    )

}

DocsDetailsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onRefetchData : PropTypes.func,
    doc : PropTypes.object
};
DocsDetailsDialog.defaultProps = {
    open: false,
};

export default DocsDetailsDialog