import i18n from "../../../../i18n";

import { Grid } from "@mui/material";

import { RcpScheduleTableCell } from "./RcpScheduleTableCell";

import {
  LEFT_PART_GRID_XS,
  CENTER_PART_GRID_XS,
  RIGHT_PART_GRID_XS,
  WORKED_HOURS_COL_XS,
  RBH_PER_MOHTH_COL_XS,
  OVERTIME_HOURS_COL_XS,
  EMPTY_CELL_COLOR,
  FREE_DAY,
  RCP_PLAN_COLORS,
  RCP_DONE_COLORS,
} from "../Config";

const RcpScheduleHeaderDay = ({ day, rcpKind }) => {
  const isRcpPlan = rcpKind === "rcpplan";
  const isRcpDone = rcpKind === "rcpdone";

  const getdayWeekNumberBg = (day) => {
    return day.is_free_day
      ? isRcpPlan
        ? RCP_PLAN_COLORS[FREE_DAY]
        : RCP_DONE_COLORS[FREE_DAY]
      : null;
  };

  return (
    <Grid item xs={1}>
      <Grid container>
        <Grid item xs={12}>
          <RcpScheduleTableCell
            value={day.dayweek_number}
            bgColor={getdayWeekNumberBg(day)}
          />
        </Grid>
        <Grid item xs={12}>
          <RcpScheduleTableCell
            value={i18n.t(`dialog.rcp_schedule_dialog.${day.dayweek_name}`)}
          />
        </Grid>
        {!isRcpDone && (
          <Grid item xs={!isRcpPlan ? 6 : 12}>
            <RcpScheduleTableCell
              value={i18n.t(`dialog.rcp_schedule_dialog.rcp_plan`)}
            />
          </Grid>
        )}
        {!isRcpPlan && (
          <Grid item xs={!isRcpDone ? 6 : 12}>
            <RcpScheduleTableCell
              value={i18n.t(`dialog.rcp_schedule_dialog.rcp_done`)}
              bgColor={RCP_DONE_COLORS[FREE_DAY]}
              fnColor={EMPTY_CELL_COLOR}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const RcpScheduleTableHeaders = ({ headers, rcpKind }) => {
  if (!headers) return null;

  return (
    <Grid
      item
      xs={12}
      container
      sx={{
        position: "sticky",
        top: 0,
        height: "max-content",
        background: EMPTY_CELL_COLOR,
      }}
    >
      <Grid item xs={LEFT_PART_GRID_XS}>
        <RcpScheduleTableCell
          value={i18n.t(`dialog.rcp_schedule_dialog.employee`)}
        />
      </Grid>
      <Grid item xs={CENTER_PART_GRID_XS}>
        <Grid container columns={Object.keys(headers).length}>
          {Object.values(headers).map((day) => (
            <RcpScheduleHeaderDay day={day} rcpKind={rcpKind} />
          ))}
        </Grid>
      </Grid>
      <Grid item container xs={RIGHT_PART_GRID_XS}>
        <Grid item xs={WORKED_HOURS_COL_XS}>
          <RcpScheduleTableCell
            value={i18n.t(`dialog.rcp_schedule_dialog.worked_hours`)}
            style={{
              overflowX: "hidden",
            }}
          />
        </Grid>
        <Grid item xs={RBH_PER_MOHTH_COL_XS}>
          <RcpScheduleTableCell
            value={i18n.t(`dialog.rcp_schedule_dialog.rbh_per_month`)}
            style={{
              overflowX: "hidden",
            }}
          />
        </Grid>
        <Grid item xs={OVERTIME_HOURS_COL_XS}>
          <RcpScheduleTableCell
            value={i18n.t(`dialog.rcp_schedule_dialog.overtime_hours`)}
            style={{
              overflowX: "hidden",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
