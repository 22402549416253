import { useState, useEffect, useCallback } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import { PO_INVOICE_TABLE_FIELDS_CONFIG } from "./PageTablesConfig";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { useEmptyArrayMemo } from "../../hooks/useEmptyArrayMemo";
import FilterTable from "../../components/table/FilterTable";
import TableService from "../../services/tableService";
import { NAVIGATION_DRAWER_TYPE_WAREHOUSES } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import usePurchaseService from "../../services/purchaseService";
import useDialogWithId from "../../hooks/useDialogWithId";
import useDocsData from "../../hooks/useDocsData";
import PurchaseFvDialog from "../../components/dialog/PurchaseFvDialog/PurchaseFvDialog";
import PurchasePreviewDialog from "../../components/dialog/PurchasePreviewDialog/PurchasePreviewDialog";
import useUserService from "../../services/userService";
import { CREATE_INVOCIE_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";

export default function PoInvoicePage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const [fvLocal, setFvLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [fvNumberToPoDialog, setFvNumberToPoDialog] = useState()

  const [hasCreateInvoicePermission] = useCheckPermission(CREATE_INVOCIE_PERMISSION)
  const [
    openInvoiceDialog,
    fvEditId,
    handleOpenInvoiceDialog,
    handleCloseInvoiceDialog,
    exportInvoiceStatus
  ] = useDialogWithId();

  const [
    openPoListByFvDialog,
    fvPoListByFvId,
    handleOpenPoListByFvDialog,
    handleClosePoListByFveDialog,
    exportPoListByFvStatus
  ] = useDialogWithId();

  const {
    page: fvDataPage,
    pageSize: fvDataPageSize,
    handleChangePageWithSearchParams: handleChangeFvDataPage,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();


  const {
    getFVs,
    getFVFilteringData
  } = usePurchaseService()


  const fvData = useAsync(
    () => getFVs(searchParams),
    [searchParams]
  );


  const {
    onDownloadDoc,
    onPreviewDoc,
  } = useDocsData();


  const { emptyArrayMemo } = useEmptyArrayMemo()

  const fVFilteringData = useAsync(getFVFilteringData)

  useEffect(() => {
    if (fvData.loading) {
      return;
    }
    setFvLocal(fvData.value.results);
    setCountRecords(fvData.value.count);
  }, [fvData.loading]);


  const onClickEdit = useCallback((e, fvId) => {
    e.stopPropagation()
    handleOpenInvoiceDialog(fvId)
  }, [fvEditId, openInvoiceDialog])

  const onClickShowPoList = useCallback((e, fvId) => {
    e.stopPropagation()
    handleOpenPoListByFvDialog(fvId)
    let fv = fvLocal.find(fv => fv.id === fvId)
    setFvNumberToPoDialog(fv.fv_nr)
  }, [fvLocal, fvEditId, openInvoiceDialog])

  const onClosePoPreViewDialog = useCallback(() => {
    handleClosePoListByFveDialog()
    setFvNumberToPoDialog(undefined)
  }, [fvNumberToPoDialog, openPoListByFvDialog])


  const isLoading = fVFilteringData.loading || fvData.loading

  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        PO_INVOICE_TABLE_FIELDS_CONFIG,
        fvLocal,
        emptyArrayMemo,
        emptyArrayMemo,
        onClickEdit,
        onClickShowPoList,
        onPreviewDoc,
        onDownloadDoc
      ),
    [fvLocal, PO_INVOICE_TABLE_FIELDS_CONFIG]
  );


  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_WAREHOUSES}
    >
      <DefaultPageWrapper titleKey={pageName}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          {hasCreateInvoicePermission &&
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
                onClick={handleOpenInvoiceDialog}
              >
                {t("page.po_invoice_page.new_invoice")}
              </Button>
            </Grid>}
          <Grid item xs={12}>
            {
              isLoading ?
                <LoaderWrapper showLoader={true} />
                :
                <FilterTable
                  data={data}
                  showCheckbox={false}
                  showCleanFilterIcon={true}
                  countRecords={countRecords}
                  page={fvDataPage}
                  onPageChange={handleChangeFvDataPage}
                  rowsPerPage={fvDataPageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={fVFilteringData}
                  hiddenColumns={emptyArrayMemo}
                  style={{ maxHeight: "vh" }}
                  tableConfig={PO_INVOICE_TABLE_FIELDS_CONFIG}
                  headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                    PO_INVOICE_TABLE_FIELDS_CONFIG,
                    fVFilteringData || emptyArrayMemo,
                    emptyArrayMemo
                  )}
                  showContextMenu={false}
                />
            }
          </Grid>
        </Grid>

        {openInvoiceDialog &&
          <PurchaseFvDialog
            open={openInvoiceDialog}
            onClose={handleCloseInvoiceDialog}
            onRefetchData={fvData.refetch}
          />
        }
        {openPoListByFvDialog &&
          <PurchasePreviewDialog
            open={openPoListByFvDialog}
            onClose={onClosePoPreViewDialog}
            fvId={fvPoListByFvId}
            fvNumber={fvNumberToPoDialog}
          />
        }
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
