import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";

export const GUARANTEE_AGREEMENT_TABLE_NAME = "guarantee_agreements_table";


export const GUARANTEE_AGREEMENT_PAGE_TABLE_FIELDS_CONFIG = [
  {
    name: "agreement",
    getValueCallback: (rowData) => rowData.agreement,
    label: i18n.t("table.agreements_table.agreement_nr"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.agreement,
      isObjectOption: true,
      optionLabelKey: "agreement_nr",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },

  {
    name: "owners_agreement",
    getValueCallback: (rowData) => rowData.owner,
    label: i18n.t("table.agreements_table.agreement_owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owner,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.agreements_table.agreement_tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "guarantee_category",
    getValueCallback: (rowData) => rowData.guarantee_category,
    label: i18n.t("table.guarantee_agreements_table.guarantee_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.guarantee_category,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
 
  {
    name: "guarantee_date_contract",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.guarantee_date_contract),
    label: i18n.t("table.guarantee_agreements_table.guarantee_date_contract"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "guarantee_value",
    getValueCallback: (rowData) => rowData.guarantee_value,
    label: i18n.t("table.guarantee_agreements_table.guarantee_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,

  },
  {
    name: "guarantee_validity",
    getValueCallback: (rowData) => rowData.guarantee_validity,
    label: i18n.t("table.guarantee_agreements_table.guarantee_validity"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined

  },
  {
    name: "guarantee_location",
    getValueCallback: (rowData) => rowData.guarantee_location,
    label: i18n.t("table.guarantee_agreements_table.guarantee_location"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined

  },
  {
    name: "guarantee_date_close",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.guarantee_date_close),
    label: i18n.t("table.guarantee_agreements_table.guarantee_date_close"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
];
